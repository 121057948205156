import axios from 'axios';
import {useState, useContext} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import Link from '@mui/material/Link';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LoadOwnershipCancel from './LoadOwnershipCancel';
import LoadListListItem from "../LoadList/LoadListListItem";
import LoadOwnershipEditHelp from './LoadOwnershipEditHelp';
import LoadOwnershipEditCustomerAssignedToList from './LoadOwnershipEditCustomerAssignedToList';
import LoadOwnershipEditCustomerContractToList from './LoadOwnershipEditCustomerContractToList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadOwnershipEdit(props) {
    //console.debug('RTS Break LoadOwnershipEdit');

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showOwnershipHelp, setShowOwnershipHelp] = useState(false);

    const [assignedToCustomer, setAssignedToCustomer] = useState(props.data.assignedToCustomer);
    const [contractToCustomer, setContractToCustomer] = useState(props.data.contractToCustomer);

    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = props.data;
        apiObj.assignedToCustomer = assignedToCustomer;
        apiObj.contractToCustomer = contractToCustomer;

        let apiURL = apiRoot + "/Loads/UpdateLoad/" + apiObj.idGuid;
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.dataRefreshFx();            
            props.modeFx(0);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateLoad Error", error);
            setShowErrorModal(true);
        });
    } 


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 

            <Dialog open={showOwnershipHelp} onClose={()=>{setShowOwnershipHelp(false);}}>
                <DialogTitle>Reel Ownership Help</DialogTitle>
                <DialogContent>
                    <LoadOwnershipEditHelp />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowOwnershipHelp(false);}}>Close</Button>
                </DialogActions>
            </Dialog>                     

            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit} style={{width:'400px'}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography variant='h4'>Change Ownership</Typography>
                        <Link onClick={() => {setShowOwnershipHelp(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <HelpOutlineIcon sx={{fontSize:'1em', ml:1}}  />
                        </Link>
                    </Box>
                    <Box>
                        <Typography variant='h6'>Sold To: {props.data.soldToCustomer.name}</Typography>
                    </Box>
                    <LoadOwnershipEditCustomerAssignedToList data={props.data} setAssignedToCustomerFx={setAssignedToCustomer} />
                    <LoadOwnershipEditCustomerContractToList data={props.data} setContractToCustomerFx={setContractToCustomer} />                               
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit" variant="contained" sx={{mr:2}}>Change Ownership</Button>
                            <LoadOwnershipCancel modeFx={props.modeFx} />
                        </Box>
                    </Box>
                </form>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
