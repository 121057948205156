import axios from 'axios';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import EditCancel from '../EditCancel';
import UserRequestsEditEmail from './UserRequestsEditEmail';
import UserRequestsEditBody from './UserRequestsEditBody';
import UserRequestsRemove from './UserRequestsRemove';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersEditCreate(props) {
    //console.debug("RTS Break UserRequestsEdit");

    const location = useLocation();

    const [mode, setMode] = useState(location.state.mode);
    const [userRequest, setUserRequest] = useState(location.state.data);

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState('');
   
    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box>            
            {mode===99
            ?
            <UserRequestsRemove data={userRequest} />
            :
            <>
            <Typography variant="h5" sx={{my:2}}>Request New User</Typography>
            <Box sx={{mt:1}}>
                <UserRequestsEditEmail setValidatedFx={setValidated} setEmailFx={setEmail} />
            </Box>
            <Box>
                <UserRequestsEditBody validated={validated} email={email} />
            </Box>
            <Box sx={{textAlign:'center', mt:2}}>
                <EditCancel />
            </Box> 
            </>
            }           
        </Box>
        </>
    );
}
