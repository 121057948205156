import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import CustomerReelsEdit from '../../components/CustomerReels/CustomerReelsEdit';

export default function CustomerReelsPageEdit( props ){
    //console.debug("RTS Break CustomerReelsPageEdit");

    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 

    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Typography variant="h5" sx={{my:2}}>Edit Customer Reel</Typography>
            <Box>
                <CustomerReelsEdit />
            </Box>
        </Container>
    );
}
