import Button from '@mui/material/Button';

export default function LoadMarkCancel(props) {
    //console.debug("RTS Break LoadMarkCancel");
    
    return (
        <Button variant="contained" onClick={handleCancel}>Cancel</Button>
    );

    function handleCancel(e){
        e.preventDefault();
        props.modeFx(0);
    }

}
