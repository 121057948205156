import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';

export default function LoadListListItemLinkRecordPull(props){
    return(
        <Link onClick={clickEvent} sx={{textDecoration:'none', cursor:'pointer'}}>
            <Box sx={{display:'flex'}}>
                <HandymanOutlinedIcon />
                <Typography sx={{fontWeight:'bold', pl:'2px'}}>Record A Pull</Typography>
            </Box>
        </Link>
    )

    function clickEvent(e){
        let args ={
            idx:props.childIdx,
            mode: 3
        }
        props.openChildFx(args);
    }

}
