import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function DashboardKPIProjectList(props) {
    //console.debug("RTS Break DashboardProjectList");
    
    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoProjects, setRepoProjects] = useState();

    function fetchData(){

        let apiObj={
            "user": user, 
            "customers": [sessionCustomer.customer],
            "sortBy":0             
        };

        let apiURL = apiRoot + "/Dashboard/GetDashboardProjects";
        axios
        .post(
            apiURL,
            apiObj,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoProjects(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }


    useEffect(() => {
        fetchData();
    }, [props.filter]); 


    function handleClick(e, cellValues){
        let filterObj = {"user":user, 
        "status":[],
        "customers":[sessionCustomer.customer],
        "orders":[], 
        "projects":[cellValues.row.project], 
        "products":[], 
        "reelTypes":[],
        "reels":[],
        "minLength":0,
        "namedLocations":[], 
        "pinned":-1,
        "locked":-1,
        "sortBy":0 
        };

        history("/LoadList", {state:{filter:filterObj}});
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    const columns = [
        {field: 'project', 
            headerName: "Project", 
            description:"Project Number", 
            flex:4,
            renderCell: (cellValues) => {
                return (
                <Button
                    onClick={(event) => {
                    handleClick(event, cellValues);
                    }}
                >
                    {cellValues.row.project.name}
                </Button>
                );
            }
        },
        {field: 'activeReels', 
            headerName: "Active Reels", 
            description:"Number of Active Reels", 
            flex:1, 
            align:"right"
        },
        {field: 'reels', 
            headerName: "Reels", 
            description:"Number of Reels", 
            flex:1, 
            align:"right"
        }
    ];

    return (
        <>
        <Box id="pageBox" sx={{width:'100%', height:400}}>
            <DataGridPro 
                rows={repoProjects} 
                columns={columns} 
                getRowId={row => row.project.name} 
                sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal"
                    },
                    "& .MuiDataGrid-columnHeader": {
                      // Forced to use important since overriding inline styles
                      height: "unset !important"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      // Forced to use important since overriding inline styles
                      maxHeight: "168px !important"
                    }
                }}                  
            />
        </Box>
        </>
    );
}