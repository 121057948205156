import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import EditIcon from '@mui/icons-material/Edit'; 

export default function CustomerReelsList(props){
    //console.debug("RTS Break CustomerReelsList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/CustomerReelsEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit Customer Reel", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'customerName', 
            headerName: "Customer", 
            description:"Customer Name", 
            flex:4,
            renderCell: (cellValues) => {
                return cellValues.row.customer.name
            },
        },        
        {
            field: 'reelTypeName', 
            headerName: "Reel Type", 
            description:"Reel Type Name", 
            flex:3,
            renderCell: (cellValues) =>{
                return cellValues.row.reelType.name
            }
        },
        {
            field: 'purchaseDate', 
            headerName: "Date Purchased", 
            description:"Date Purchased", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            } 
        }        
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro autoHeight pagination components={{Toolbar: GridToolbar}} pageSize={50} rows={props.data} columns={columns} getRowId={row => row.idGuid} />
        </Box>
    );
}

