import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { format, parseISO } from 'date-fns';
import Grid from '@mui/material/Grid';
import Image from 'mui-image';
import TextField from '@mui/material/TextField';


import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import ReelInspectionsEditTypeList from './ReelInspectionsEditTypeList';
import EditCancel from '../EditCancel';
import SRDC1 from './SRDC1.png';
import SRDC2 from './SRDC2.png';
import SRDC3 from './SRDC3.png';
import SRDC41 from './SRDC4-1.png';
import SRDC42 from './SRDC4-2.png';
import SRDC43 from './SRDC4-3.png';
import SRDC51 from './SRDC5-1.png';
import SRDC52 from './SRDC5-2.png';
import SRDC61 from './SRDC6-1.png';
import SRDC62 from './SRDC6-2.png';

import { useSessionContext } from '../../contexts/SessionContext';
import { Typography } from '@mui/material';

export default function ReelInspectionsEdit(props) {
    //console.debug("RTS Break ReelInspectionEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const location = useLocation();
    const history = useNavigate();
    const data = location.state.data;
    const mode = location.state.mode;
 
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showZoomModal, setShowZoomModal] = useState(false);
    const [zoomTitle, setZoomTitle] = useState("Image Zoom");
    const [zoomMode, setZoomMode] = useState(null);

    const [showNotesModal, setShowNotesModal] = useState(false);
    const [notesTitle, setNotesTitle] = useState(null);
    const [notesMode, setNotesMode] = useState(null);
    const [notes, setNotes] = useState(null);
    
    const [reel, setReel] = useState(data.reel);
    const [inspType, setInspType] = useState(mode===1?data.inspectionType!==null?data.inspectionType:0:0);
    const [flangeRibCracks, setFlangeRibCracks] = useState(mode===1?data.flangeRibCracks!==null?data.flangeRibCracks:0:0);
    const [flangeRibCracksNotes, setFlangeRibCracksNotes] = useState(mode===1?data.flangeRibCracksNotes!==null?data.flangeRibCracksNotes:null:null);
    const [flangeAbrasions, setFlangeAbrasions] = useState(mode===1?data.flangeAbrasions!==null?data.flangeAbrasions:0:0);
    const [flangeAbrasionsNotes, setFlangeAbrasionsNotes] = useState(mode===1?data.flangeAbrasionsNotes!==null?data.flangeAbrasionsNotes:null:null);
    const [laggingTireBurs, setLaggingTireBurs] = useState(mode===1?data.laggingTireBurs!==null?data.laggingTireBurs:0:0);
    const [laggingTireBursNotes, setLaggingTireBursNotes] = useState(mode===1?data.laggingTireBursNotes!==null?data.laggingTireBursNotes:null:null);
    const [crossarmArborDamage, setCrossarmArborDamage] = useState(mode===1?data.crossarmArborDamage!==null?data.crossarmArborDamage:0:0);
    const [crossarmArborDamageNotes, setCrossarmArborDamageNotes] = useState(mode===1?data.crossarmArborDamageNotes!==null?data.crossarmArborDamageNotes:null:null);
    const [reelStraightness, setReelStraightness] = useState(mode===1?data.reelStraightness!==null?data.reelStraightness:0:0);
    const [reelStraightnessNotes, setReelStraightnessNotes] = useState(mode===1?data.reelStraightnessNotes!==null?data.reelStraightnessNotes:null:null);
    const [dRings, setDRings] = useState(mode===1?data.dRings!==null?data.dRings:0:0);
    const [dRingsNotes, setDRingsNotes] = useState(mode===1?data.dRingsNotes!==null?data.dRingsNotes:null:null);
    const [gussets, setGussets] = useState(mode===1?data.gussets!==null?data.gussets:0:0);
    const [gussetsNotes, setGussetsNotes] = useState(mode===1?data.gussetsNotes!==null?data.gussetsNotes:null:null);
    const [drumSticthWeld, setDrumSticthWeld] = useState(mode===1?data.drumSticthWeld!==null?data.drumSticthWeld:0:0);
    const [drumStitchWeldNotes, setDrumStitchWeldNotes] = useState(mode===1?data.drumSticthWeldNotes!==null?data.drumSticthWeldNotes:null:null);
        
    const curDate = mode===1?format(parseISO(data.createDate), 'MM-dd-yyyy'):format(new Date(), 'MM-dd-yyyy');
    

    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid===''?null:idGuid,
            AppUser: user,
            Reel: reel,
            InspectionType: inspType,
            FlangeRibCracks: flangeRibCracks,
            FlangeRibCracksNotes: flangeRibCracksNotes,
            FlangeAbrasions: flangeAbrasions,
            FlangeAbrasionsNotes: flangeAbrasionsNotes,
            LaggingTireBurs: laggingTireBurs,
            LaggingTireBursNotes: laggingTireBursNotes,
            CrossarmArborDamage: crossarmArborDamage,
            CrossarmArborDamageNotes: crossarmArborDamageNotes,
            ReelStraightness: reelStraightness,
            ReelStraightnessNotes: reelStraightnessNotes,
            DRings: dRings,
            DRingsNotes: dRingsNotes,
            Gussets: gussets,
            GussetsNotes: gussetsNotes,
            DrumSticthWeld: drumSticthWeld,
            DrumStitchWeldNotes: drumStitchWeldNotes,
            ModifyUser: user.userName
        }


        if(mode > 0){
            let apiURL = apiRoot + "/ReelInspections/UpdateReelInspection";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateReelInspection Error", error);
                setShowErrorModal(true);
            });
        }
        else{        
            apiObj.CreateUser = user.userName;
            let apiURL = apiRoot + "/ReelInspections/CreateReelInspection";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateReelInspection Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    function setZoom(mode){
        switch(mode){
            case 'SRDC1':
                setZoomTitle('Cracks in the ribs of the flange');
                break;
            case 'SRDC2':
                setZoomTitle('Abrasions in the flange');
                break;
            case 'SRDC3':
                setZoomTitle('Burs on the lagging tire');
                break;
            case 'SRDC41':
                setZoomTitle('Damages to crossarms and arbor');
                break;
            case 'SRDC42':
                setZoomTitle('Damages to crossarms and arbor');
                break;
            case 'SRDC43':
                setZoomTitle('Damages to crossarms and arbor');
                break;
            case 'SRDC51':
                setZoomTitle('Straightness of Reel');
                break;
            case 'SRDC52':
                setZoomTitle('Straightness of Reel');
                break;
            case 'SRDC61':
                setZoomTitle('Two D-rings in the starter pocket');
                break;
            case 'SRDC62':
                setZoomTitle('Two gussets on crossarm');
                break;
        }

        setZoomMode(mode);
        setShowZoomModal(true);        
    }


    function showFieldNotes(mode){
        switch(mode){
            case 'SRDC1':
                setNotes(flangeRibCracksNotes);
                setNotesTitle("Cracks in the ribs of the flange");
                break;
            case 'SRDC2':
                setNotes(flangeAbrasionsNotes);
                setNotesTitle("Abrasions in the flange");
                break;
            case 'SRDC3':
                setNotes(laggingTireBursNotes);
                setNotesTitle("Burs on the lagging tire");
                break;
            case 'SRDC4':
                setNotes(crossarmArborDamageNotes);
                setNotesTitle("Damages to crossarms and arbor");
                break;
            case 'SRDC5':
                setNotes(reelStraightnessNotes);
                setNotesTitle("Straightness of Reel");
                break;
            case 'SRDC6':
                setNotes(dRingsNotes);
                setNotesTitle("Two D-rings in the starter pocket")
                break;
            case 'SRDC7':
                setNotes(gussetsNotes);
                setNotesTitle("Two gussets on crossarm")
                break;
            case 'SRDC8':
                setNotes(drumStitchWeldNotes);
                setNotesTitle("Is the drum stitch welded to the flange on both sides");
                break;
            }

        setNotesMode(mode);
        setShowNotesModal(true);        
    }
 
    
    function setFieldNotes(){
        switch(notesMode){
            case 'SRDC1':
                setFlangeRibCracksNotes(notes);
                break;
            case 'SRDC2':
                setFlangeAbrasionsNotes(notes);
                break;
            case 'SRDC3':
                setLaggingTireBursNotes(notes);
                break;
            case 'SRDC4':
                setCrossarmArborDamageNotes(notes);
                break;
            case 'SRDC5':
                setReelStraightnessNotes(notes);
                break;
            case 'SRDC6':
                setDRingsNotes(notes);
                break;
            case 'SRDC7':
                setGussetsNotes(notes);
                break;
            case 'SRDC8':
                setDrumStitchWeldNotes(notes);
                break;
        }

        setNotesMode(null);
        setNotesTitle(null);
        setNotes(null);
        setShowNotesModal(false);        
    }
 

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showZoomModal} onClose={()=>{setShowZoomModal(false);}}>
            <DialogTitle>{zoomTitle}</DialogTitle>
            <DialogContent>
                {
                    zoomMode==='SRDC1'
                    ?
                        <Image src={SRDC1} showLoading width={500} />
                    :
                    zoomMode==='SRDC2'
                    ?               
                        <Image src={SRDC2} showLoading width={500} />
                    :
                    zoomMode==='SRDC3'
                    ?               
                        <Image src={SRDC3} showLoading width={500} />
                    :
                    zoomMode==='SRDC41'
                    ?               
                        <Image src={SRDC41} showLoading width={500} />
                    :
                    zoomMode==='SRDC42'
                    ?               
                        <Image src={SRDC42} showLoading width={500} />
                    :
                    zoomMode==='SRDC43'
                    ?               
                        <Image src={SRDC43} showLoading width={500} />
                    :
                    zoomMode==='SRDC51'
                    ?               
                        <Image src={SRDC51} showLoading width={500} />
                    :
                    zoomMode==='SRDC52'
                    ?               
                        <Image src={SRDC52} showLoading width={500} />
                    :
                    zoomMode==='SRDC61'
                    ?               
                        <Image src={SRDC61} showLoading width={500} />
                    :
                    zoomMode==='SRDC62'
                    ?               
                        <Image src={SRDC62} showLoading width={500} />
                    :
                        ''
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowZoomModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showNotesModal} onClose={()=>{setShowNotesModal(false);}}>
            <DialogTitle>Notes - {notesTitle}</DialogTitle>
            <DialogContent>
                <Box sx={{minWidth:"550px"}}>
                    <TextField
                            id="tbNotes"
                            margin="normal"
                            fullWidth
                            label="Notes"
                            size="small"
                            multiline
                            rows={6}
                            value={notes}
                            onChange={(e) => {setNotes(e.target.value);}}
                        />            
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setFieldNotes();}}>Close</Button>
            </DialogActions>
        </Dialog>           

        <Box>
            <Stack>
                <Box sx={{display:'flex'}}>
                    <Typography variant="h6" sx={{fontWeight:'bold'}}>Date:</Typography>
                    <Typography variant="h6" sx={{ml:'2px'}}>{curDate}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography variant="h6" sx={{fontWeight:'bold'}}>Inspector:</Typography>
                    <Typography variant="h6" sx={{ml:'2px'}}>{user.firstName + ' ' + user.lastName}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography variant="h6" sx={{fontWeight:'bold'}}>Reel Size:</Typography>
                    <Typography variant="h6" sx={{ml:'2px'}}>{data.reel.reelType.name}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography variant="h6" sx={{fontWeight:'bold'}}>Reel Serial Number:</Typography>
                    <Typography variant="h6" sx={{ml:'2px'}}>{data.reel.serialNumber}</Typography>
                </Box>
            </Stack>

            <Box>
                <ReelInspectionsEditTypeList inspType={inspType} setInspTypeFx={setInspType} />
            </Box>

            <Stack spacing={1} sx={{pt:2}}>
                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={5} sx={{textAlign:'left'}}>
                        <Image src={SRDC1}  showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC1');}} />
                    </Grid>
                    <Grid item xs={4} sx={{textAlign:'left', px:1}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Cracks in the ribs of the flange</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={flangeRibCracks===1?"contained":"outlined"} color={flangeRibCracks===1?"success":"primary"} onClick={(e) =>{setFlangeRibCracks(1);setNotes(null);setFieldNotes('SRDC1');}}  type="button">Pass</Button>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={flangeRibCracks===-1?"contained":"outlined"} color={flangeRibCracks===-1?"error":"primary"} onClick={(e) =>{setFlangeRibCracks(-1);showFieldNotes('SRDC1');}}  type="button">Fail</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center', pt:4}}>
                                <Button variant="contained" size="small"  type="button" startIcon={<HistoryEduIcon />} onClick={(e) =>{showFieldNotes('SRDC1');}}>Notes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={5} sx={{textAlign:'left'}}>
                        <Image src={SRDC2}  showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC2');}} />
                    </Grid>
                    <Grid item xs={4} sx={{textAlign:'left', px:1}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Abrasions in the flange</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={flangeAbrasions===1?"contained":"outlined"} color={flangeAbrasions===1?"success":"primary"} onClick={(e) =>{setFlangeAbrasions(1);setNotes(null);setFieldNotes('SRDC2');}}  type="button">Pass</Button>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={flangeAbrasions===-1?"contained":"outlined"} color={flangeAbrasions===-1?"error":"primary"} onClick={(e) =>{setFlangeAbrasions(-1);showFieldNotes('SRDC2');}}  type="button">Fail</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center', pt:4}}>
                                <Button variant="contained" size="small"  type="button" startIcon={<HistoryEduIcon />} onClick={(e) =>{showFieldNotes('SRDC2');}}>Notes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={5} sx={{textAlign:'left'}}>
                        <Image src={SRDC3}  showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC3');}} />
                    </Grid>
                    <Grid item xs={4} sx={{textAlign:'left', px:1}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Burs on the lagging tire</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={laggingTireBurs===1?"contained":"outlined"} color={laggingTireBurs===1?"success":"primary"} onClick={(e) =>{setLaggingTireBurs(1);setNotes(null);setFieldNotes('SRDC3');}}  type="button">Pass</Button>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={laggingTireBurs===-1?"contained":"outlined"} color={laggingTireBurs===-1?"error":"primary"} onClick={(e) =>{setLaggingTireBurs(-1);showFieldNotes('SRDC3');}}  type="button">Fail</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center', pt:4}}>
                                <Button variant="contained" size="small"  type="button" startIcon={<HistoryEduIcon />} onClick={(e) =>{showFieldNotes('SRDC3');}}>Notes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={5} sx={{textAlign:'left'}}>
                        <Box sx={{display:'flex'}}>
                            <Image src={SRDC41} showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC41');}} />
                            <Image src={SRDC42} showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC42');}} />
                            <Image src={SRDC43} showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC43');}} />
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign:'left', px:1}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Damages to crossarms and arbor</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={crossarmArborDamage===1?"contained":"outlined"} color={crossarmArborDamage===1?"success":"primary"} onClick={(e) =>{setCrossarmArborDamage(1);setNotes(null);setFieldNotes('SRDC4');}}  type="button">Pass</Button>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={crossarmArborDamage===-1?"contained":"outlined"} color={crossarmArborDamage===-1?"error":"primary"} onClick={(e) =>{setCrossarmArborDamage(-1);showFieldNotes('SRDC4');}}  type="button">Fail</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center', pt:4}}>
                                <Button variant="contained" size="small"  type="button" startIcon={<HistoryEduIcon />} onClick={(e) =>{showFieldNotes('SRDC4');}}>Notes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>          

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={5} sx={{textAlign:'left'}}>
                        <Box sx={{display:'flex'}}>
                            <Image src={SRDC51} showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC51');}} />
                            <Image src={SRDC52} showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC52');}} />
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign:'left', px:1}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Straightness of Reel</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={reelStraightness===1?"contained":"outlined"} color={reelStraightness===1?"success":"primary"} onClick={(e) =>{setReelStraightness(1);setNotes(null);setFieldNotes('SRDC5');}}  type="button">Pass</Button>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={reelStraightness===-1?"contained":"outlined"} color={reelStraightness===-1?"error":"primary"} onClick={(e) =>{setReelStraightness(-1);showFieldNotes('SRDC5');}}  type="button">Fail</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center', pt:4}}>
                                <Button variant="contained" size="small"  type="button" startIcon={<HistoryEduIcon />} onClick={(e) =>{showFieldNotes('SRDC5');}}>Notes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>      

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={5} sx={{textAlign:'left'}}>
                        <Box sx={{display:'flex'}}>
                            <Image src={SRDC61} showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC61');}} />
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign:'left', px:1}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Two D-rings in the starter pocket</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={dRings===1?"contained":"outlined"} color={dRings===1?"success":"primary"} onClick={(e) =>{setDRings(1);setNotes(null);setFieldNotes('SRDC6');}}  type="button">Pass</Button>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={dRings===-1?"contained":"outlined"} color={dRings===-1?"error":"primary"} onClick={(e) =>{setDRings(-1);showFieldNotes('SRDC6');}}  type="button">Fail</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center', pt:4}}>
                                <Button variant="contained" size="small"  type="button" startIcon={<HistoryEduIcon />} onClick={(e) =>{showFieldNotes('SRDC6');}}>Notes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={5} sx={{textAlign:'left'}}>
                        <Image src={SRDC62} showLoading sx={{px:.25, cursor:'pointer'}} width={125} onClick={() => {setZoom('SRDC62');}} />
                    </Grid>
                    <Grid item xs={4} sx={{textAlign:'left', px:1}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Two gussets on crossarm</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={gussets===1?"contained":"outlined"} color={gussets===1?"success":"primary"} onClick={(e) =>{setGussets(1);setNotes(null);setFieldNotes('SRDC7');}}  type="button">Pass</Button>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={gussets===-1?"contained":"outlined"} color={gussets===-1?"error":"primary"} onClick={(e) =>{setGussets(-1);showFieldNotes('SRDC7');}}  type="button">Fail</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center', pt:4}}>
                                <Button variant="contained" size="small"  type="button" startIcon={<HistoryEduIcon />} onClick={(e) =>{showFieldNotes('SRDC7');}}>Notes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 


                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={5} sx={{textAlign:'left'}}>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign:'left', px:1}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Is the drum stitch welded to the flange on both sides</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={drumSticthWeld===1?"contained":"outlined"} color={drumSticthWeld===1?"success":"primary"} onClick={(e) =>{setDrumSticthWeld(1);setNotes(null);setFieldNotes('SRDC8');}}  type="button">Pass</Button>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Button variant={drumSticthWeld===-1?"contained":"outlined"} color={drumSticthWeld===-1?"error":"primary"} onClick={(e) =>{setDrumSticthWeld(-1);showFieldNotes('SRDC8');}}  type="button">Fail</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center', pt:4}}>
                                <Button variant="contained" size="small"  type="button" startIcon={<HistoryEduIcon />} onClick={(e) =>{showFieldNotes('SRDC8');}}>Notes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                 
            </Stack>            

            <Box sx={{textAlign:'center', mt:3}}>
                <Button variant="contained"  type="button" onClick={handleSubmit} sx={{mr:2}}>Save</Button>
                <EditCancel />
            </Box>
        </Box>
        </>
    );
}
