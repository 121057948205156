import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';

import Loading from '../components/Loading';
import Error from '../components/Error';
import NamedLocationsList from '../components/NamedLocations/NamedLocationsList';
import NamedLocationsHeader from '../components/NamedLocations/NamedLocationsHeader';

import { useSessionContext } from '../contexts/SessionContext';

export default function NamedLocationsPage( props ){
    //console.debug("RTS Break NamedLocationsPage");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [mode, setMode] = useState(0);
    const [selUser, setSelUser] = useState();
    const [data, setData] = useState([]);
    const [hasError, setHasError] = useState(false);
  
    function fetchData(){

        let apiObj = {
            Customer: sessionCustomer.customer
        }

        let apiURL = apiRoot + "/NamedLocations/GetLocationsByCustomer";
        axios
        .post(
            apiURL,
            sessionCustomer.customer,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, []);  
    

    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box id="pageBox">
            <NamedLocationsHeader />
            <Box sx={{mt:2}}>           
                <NamedLocationsList data={data} />
            </Box>
        </Box>
    );
}
