import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import UsersList from '../../components/Users/UsersList';
import UsersHeader from '../../components/Users/UsersHeader';
import UsersFilter from '../../components/Users/UsersFilter';

import { useSessionContext } from '../../contexts/SessionContext';

export default function UsersPage( props ){
    //console.debug("RTS Break UsersPage");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    let filter = {};
    function fetchData(){
        let apiURL = apiRoot + "/AppUsers/GetAllAppUsersFull";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }

    useEffect(() => {
        props.setIsModalFx(false);
        filter = {"user":user, 
                    "status": 1,
                    "customers":props.filter==null?[]:props.filter.customers, 
                    "sortBy":0 
                }
        fetchData();
    }, []);  
    

    function dataFilter(args){
        filter = {"user":user,
                    "status": 1, 
                    "customers":args.customers, 
                    "sortBy":0 
                }
        setIsLoading(true);
        fetchData();
    }


     if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box id="pageBox">
            <UsersHeader />
            {/* <UsersFilter data={data} filterFx={dataFilter} />             */}
            <Box sx={{mt:2}}>           
                <UsersList data={data} />
            </Box>
        </Box>
    );


}
