import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import ClearIcon from '@mui/icons-material/Clear';

export default function CustomerProjectsLoadsList(props){
    //console.debug("RTS Break CustomerProjectLoadsList")

    function handleClick(e, cellValues){
        props.removeLoadFx(cellValues.row);
    }

    function CustomToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:2,
            valueGetter: (cellValues) => {
                return cellValues.row.reel.serialNumber;
            },
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Southwire Product Id", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.product.name;
            },
 
        },
        {
            field: 'shipDate', 
            headerName: "Purchase Date", 
            description:"Reel Ship Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },
        {
            field: '',
            description:"Remove Reel", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <ClearIcon sx={{color:"red"}} />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },        
    ];

    return (
        <DataGridPro 
            autoHeight 
            components={{Toolbar: CustomToolbar}} 
            rows={props.data} 
            columns={columns} 
            getRowId={row => row.idGuid} 
        />
    );
}
