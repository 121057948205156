import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography>
                Crew Members are users with limited functionality that do not count as a licensed seat.  A 
                Crew Member might be a lineperson that will perform pulls from a reel.  By giving them access you
                allow them to have access to all reels within your organization but limited to being able 
                to perform pulls.
            </Typography>
            <Typography sx={{mt:1}}>
                The list of Crew Members presented below can be filtered and exported using the toolbar located
                at the top of the list.
            </Typography>            
            <Typography sx={{mt:1}}>
                In addition to the toolbar each column in the list can be manipulated using the menu that appears when you 
                hover over the column header text.
            </Typography>            
            <Typography sx={{mt:1}}>
                A user can remove an existing Crew Member by clicking on the red X icon in the far right column of the 
                list or create a new Crew Member by clicking on the New Crew Member button in the upper right hand corner.
            </Typography>            
        </Box>
    );

}
