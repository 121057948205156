import Button from '@mui/material/Button';

export default function LoadOwnershipCancel(props) {
    //console.debug("RTS Break LoadOwnershipCancel");
    
    return (
        <Button type="button" variant="contained" onClick={handleCancel}>Cancel</Button>
    );

    function handleCancel(e){
        e.preventDefault();
        props.modeFx(0);
    }

}
