import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import PalletActionHeader from '../../components/PalletAction/PalletActionHeader';
import PalletActionEdit from '../../components/PalletAction/PalletActionEdit';

import { useSessionContext } from '../../contexts/SessionContext';


export default function PalletActionPage( props ){
    //console.debug("RTS Break PalletActionPage");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState(props.data);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
      

    useEffect(() => {
        props.setIsModalFx(true);
    }, []);  
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box>
            <PalletActionHeader data={props.data} />
            <Box sx={{mt:2}}>           
                <PalletActionEdit data={props.data} />
            </Box>
        </Box>
    );
}
