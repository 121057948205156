import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import ProductsEdit from '../../components/Products/ProductsEdit';

export default function ProductsPageEdit( props ){
    //console.debug("RTS Break ProductsPageEdit");

    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 

    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Typography variant="h5" sx={{my:2}}>Edit Product</Typography>
            <Box>
                <ProductsEdit />
            </Box>
        </Container>
    );
}
