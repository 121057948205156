import axios from 'axios';
import {useState, useContext} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPullManagementReject(props) {
    //console.debug('RTS Break LoadPullManagementReject');

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    if(props.data===null){
        return ("")
    }

    const curLat = props.data.lat===null?"Not Available":props.data.lat;
    const curLng = props.data.lng===null?"Not Available":props.data.lng;


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = props.data;
        apiObj.confirmedFlag = 0;

        let apiURL = apiRoot + "/LoadPulls/DeleteLoadPull/" + apiObj.idGuid 

        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.handleRejectFx(props.data);
        })
        .catch(error => {
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateLoadPull Error", error);
            setShowErrorModal(true);
        });
    } 


    function handleCancelClick(e){
        props.setShowPullRejectFx(false);
    }
 
    return (
        <form onSubmit={handleSubmit} style={{width:'400px'}}>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 
            <Box sx={{display:'flex'}}>
                <Typography sx={{fontWeight:'bold'}}>Date:</Typography>
                <Typography>{format(parseISO(props.data.pullDate), 'MM-dd-yyyy')}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography sx={{fontWeight:'bold'}}>Length Pulled:</Typography>
                <Typography>{props.data.pullLength}</Typography>
                <Typography sx={{marginLeft:"2px"}}>ft.</Typography>                
                {/* <Typography sx={{marginLeft:"2px"}}>{props.data.uom}</Typography>                 */}
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography sx={{fontWeight:'bold'}}>Notes:</Typography>
                <Typography>{props.data.notes}</Typography>
            </Box>                         
            <Box sx={{pt:1}}>
                <Typography sx={{fontWeight:'bold'}}>Location</Typography>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Latitude:</Typography>
                    <Typography>{curLat}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Longitude:</Typography>
                    <Typography>{curLng}</Typography>
                </Box>
            </Box>
            <Box sx={{pt:1}}>
                <Typography sx={{fontWeight:'bold'}}>Project</Typography>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Name:</Typography>
                    <Typography>{props.data.project!==null?props.data.project.name:''}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography sx={{fontWeight:'bold'}}>Description:</Typography>
                    <Typography>{props.data.project!==null?props.data.project.desc:''}</Typography>
                </Box>
            </Box>            
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <Box xs={{display:'flex'}}>
                    <Button type="submit" variant="contained" sx={{mr:2}}>Reject Pull</Button>
                    <Button type="button" variant="contained" onClick={handleCancelClick}>Cancel</Button>
                </Box>
            </Box>
        </form>
    );

}
