import { useNavigate } from 'react-router-dom';
import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

import WorkItemsList_2Body from './WorkItemsList_2Body';

export default function WorkItemsList_2(props){
    //console.debug("RTS Break WorkItemsList_2");

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    function SessionCustomerToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <Box sx={{width:"50%"}}>
                    <GridToolbarFilterButton />
                </Box>
                <Box sx={{width:"50%", display:"flex", justifyContent:"right"}}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'displayVal', 
            headerName: "", 
            description:"Work Package Item", 
            flex:10,
            renderCell: (cellValues) => {
                    return (
                        <WorkItemsList_2Body data={cellValues.row} mode={0} />
                    );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true                       
        },
        {
            field: 'value2', 
            headerName: "Id", 
            description:"Fuse Id", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value3', 
            headerName: "Phase", 
            description:"Fuse Phase", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value4', 
            headerName: "Size", 
            description:"Fuse Size", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value5', 
            headerName: "Location", 
            description:"Location Latitude/Longitude", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                         
        },
        {
            field: 'value6', 
            headerName: "Map Link", 
            description:"Google Map Link", 
            sortable:false,
            hideable:false,
            filterable:false,
            disableColumnMenu:true,
            hide:true                          
        },
    ];

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: SessionCustomerToolbar,  }}
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
                getRowHeight={() => 'auto'}
                headerHeight={0}
                sx={{
                    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                       outline: "none !important",
                       border: "none !important"
                    },
                 }}
            />
        </Box>
        </>
    );
}
