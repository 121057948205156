import { useEffect } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import EditCancel from '../../components/EditCancel';
import WorkItemsEdit_1 from '../../components/WorkItems/WorkItemsEdit_1';
import WorkItemsEdit_2 from '../../components/WorkItems/WorkItemsEdit_2';

export default function WorkItemsEditPage( props ){
    //console.debug("RTS Break WorkItemsEditPage");

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.workItem;
    const mode = location.state.mode;


    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 

    return(
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box>
                {
                data.workSet.workSetType.code === 1
                    ?
                    <WorkItemsEdit_1 data={data} mode={mode} />
                    :
                    data.workSet.workSetType.code === 2
                        ?
                        <WorkItemsEdit_2 data={data} mode={mode} />
                        :
                        <Box sx={{pt:3}}><Typography variant='h1'>Unknown Work Set Type</Typography></Box>
                }

            </Box>
        </Container>
    );
}
