import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import EditCancel from '../EditCancel';
import NamedLocationsEditManagerList from './NamedLocationsEditManagerList';
import NamedLocationsMap from './NamedLocationsMap';

import { useSessionContext } from '../../contexts/SessionContext';


export default function NamedLocationsEdit(props) {
    //console.debug("RTS Break NamedLocationsEdit");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [name, setName] = useState(mode===1?data.name!==null?data.name:'':'');
    const [shortName, setShortName] = useState(mode===1?data.shortName!==null?data.shortName:'':'');
    const [address1, setAddress1] = useState(mode===1?data.address1!==null?data.address1:'':'');
    const [address2, setAddress2] = useState(mode===1?data.address2!==null?data.address2:'':'');
    const [city, setCity] = useState(mode===1?data.city!==null?data.city:'':'');
    const [region, setRegion] = useState(mode===1?data.region!==null?data.region:'':'');
    const [zip, setZip] = useState(mode===1?data.zip!==null?data.zip:'':'');
    const [geoLat, setGeoLat] = useState(mode===1?data.geoLat!==null?data.geoLat:'':'');
    const [geoLng, setGeoLng] = useState(mode===1?data.geoLng!==null?data.geoLng:'':'');
    const [manager, setManager] = useState(mode===0?null:data.manager);
    const [points, setPoints] = useState([]);

    function handleSubmit(e) {
        e.preventDefault();

        let tmpPoints = [];
        for (var c = 0; c < points.length; c++) {
            tmpPoints.push( { lat: points[c][1], lng: points[c][0], pointSequence: c} );
        }

        let apiObj = {
            IdGuid: mode === 1 ? data.idGuid : "",
            Name: name===''?null:name,
            ShortName: shortName===''?null:shortName,
            Customer: sessionCustomer.customer,
            Address1: address1===''?null:address1,
            Address2: address2===''?null:address2,
            City: city===''?null:city,
            Region: region===''?null:region,
            Zip: zip===''?null:zip,
            GeoLat: geoLat===''?null:geoLat,
            GeoLng: geoLng===''?null:geoLng,
            Manager: manager,
            Points: tmpPoints,
            modifyUser: user.userName,
            createUser: user.userName
        };

        if (apiObj.Name === null || apiObj.Name.trim() === "") {
            setErrorBody("Location name is required");
            setShowErrorModal(true);
            return;
        }

        if (apiObj.ShortName === null || apiObj.ShortName.trim() === "") {
            setErrorBody("Location short name is required");
            setShowErrorModal(true);
            return;
        }

        if (apiObj.Points.length <= 4) {
            setErrorBody("Location must have at least 4 points");
            setShowErrorModal(true);
            return;
        }

        if (mode === 1) {
            let apiURL = apiRoot + "/NamedLocations/UpdateLocation";

            axios
            .put(
                apiURL,
                apiObj,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
                )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateCustomerReel Error", error);
                setShowErrorModal(true);
            });
        }
        else {
            let apiURL = apiRoot + "/NamedLocations/CreateLocation";

            axios
            .post(
                apiURL,
                apiObj,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
                )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateCustomerReel Error", error);
                setShowErrorModal(true);
            });
        }
    }

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box>
            <form id="locationForm" onSubmit={handleSubmit}>
            <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {setName(e.target.value);}}
                />
                <TextField
                    id="tbShortName"
                    margin="normal"
                    fullWidth
                    label="Short Name"
                    size="small"
                    required
                    value={shortName}
                    onChange={(e) => {setShortName(e.target.value);}}
                />
                <NamedLocationsEditManagerList manager={manager} setManagerFx={setManager} />
                <Box sx={{textAlign:'center', mt:2}}>
                    <NamedLocationsMap pointsFx={setPoints} mode={mode} data={data}/>
                </Box>
                <Box sx={{textAlign:'center', mt:2}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
        </Box>
        </>
    );
}