import axios from 'axios';
import {useState, useContext} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import Link from '@mui/material/Link';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LoadPullAdjustmentCancel from './LoadPullAdjustmentCancel';
import LoadListListItem from "../LoadList/LoadListListItem";
import LoadPullAdjustmentEditHelp from './LoadPullAdjustmentEditHelp';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPullAdjustmentEdit(props) {
    //console.debug('RTS Break LoadPullAdjustmentEdit');

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showPullAdjustmentHelp, setShowPullAdjustmentHelp] = useState(false);

    const [pullLength, setPullLength] = useState(0);
    const [pullDate, setPullDate] = useState(format( new Date(), 'MM-dd-yyyy'));
    const [notes, setNotes] = useState("");
    const maxValue = props.data.amt;


    function handleSubmit(e) {
        e.preventDefault();

        const valCheck = Math.floor(props.data.amt - props.data.pullSum + parseInt(pullLength));

        if(valCheck > props.data.amt){
            setErrorTitle("Validation Error");
            setErrorBody("The length recorded exceeds the product shipped on the reel");
            setShowErrorModal(true);
            return;            
        }

        if(valCheck < 0){
            setErrorTitle("Validation Error");
            setErrorBody("The length pulled exceeds the product shipped on the reel");
            setShowErrorModal(true);
            return;            
        }

        let apiObj = {
            PullLoad: props.data,
            PullUser: 'Adjustment',
            PullLength: pullLength,
            Uom: props.data.uom,
            PullDate: pullDate,
            Lat: null,
            Lng: null, 
            Notes: notes,
            AdjustmentFlag: 1,
            ConfirmedFlag: 1,
            createUser: user.userName,
            modifyUser: user.userName
        }

        let apiURL = apiRoot + "/LoadPulls/CreateLoadPull"

        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.dataRefreshFx();            
            props.modeFx(0);
        })
        .catch(error => {
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateLoadPull Error", error);
            setShowErrorModal(true);
        });
    } 


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 

            <Dialog open={showPullAdjustmentHelp} onClose={()=>{setShowPullAdjustmentHelp(false);}}>
                <DialogTitle>Pull Adjustment Help</DialogTitle>
                <DialogContent>
                    <LoadPullAdjustmentEditHelp />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowPullAdjustmentHelp(false);}}>Close</Button>
                </DialogActions>
            </Dialog>                    

            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit} style={{width:'400px'}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography variant='h4'>Add Pull Adjustment</Typography>
                        <Link onClick={() => {setShowPullAdjustmentHelp(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <HelpOutlineIcon sx={{fontSize:'1em', ml:1}}  />
                        </Link>
                    </Box>

                    <TextField
                        id="tbLength"
                        margin="normal"
                        required
                        fullWidth
                        label="Length"
                        type="number"
                        size="small"
                        value={pullLength}
                        onChange={(e) => {setPullLength(e.target.value);}}
                        InputProps={{inputProps:{max:{maxValue}}}}
                    />

                    <DatePicker
                        id="tbPullDate"
                        label="Adjustment Date"
                        inputFormat="MM/dd/yyyy"
                        value={pullDate}
                        onChange={(val) => setPullDate(val)}
                        renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                        maxDate={new Date()}
                    />   

                    <TextField
                        id="tbNotes"
                        margin="normal"
                        required
                        fullWidth
                        label="Notes"
                        size="small"
                        value={notes}
                        onChange={(e) => {setNotes(e.target.value);}}
                        multiline
                        rows={4}
                    />                                     

                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit" variant="contained" sx={{mr:2}}>Record Adjustment</Button>
                            <LoadPullAdjustmentCancel modeFx={props.modeFx} dataRefreshFx={props.dataRefreshFx} />
                        </Box>
                    </Box>
                </form>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
