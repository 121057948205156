import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsEditCustomerSoldToList(props) {
    //console.debug("RTS Break LoadsEditCustomerSoldToList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [customer, setCustomer] = useState(props.customer);
    const [repoCustomers, setRepoCustomers] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    function fetchData(){
        let apiURL = apiRoot + "/Customers/GetAllCustomersTypeCustomer";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoCustomers(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }


    useEffect(() => {
        fetchData();
    }, []); 

    
    function valChange(e, val){
        props.setCustomerFx(val);
        setCustomer(val);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoCustomers}
                renderInput={(params) => <TextField {...params} label="Sold To Customer" margin="normal" required size="small" />}
                value={customer}
                onChange={valChange}
                getOptionLabel={(repoValue) => repoValue!=''?repoValue.name + ' (' + repoValue.idSap + ')':''}
                renderOption={(props, repoCustomers) => (
                    <Box component="li" {...props} key={repoCustomers.idGuid}>
                        {repoCustomers.name + ' (' + repoCustomers.idSap + ')'}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.idGuid === value.idGuid}
            />
        </>
    );
}

