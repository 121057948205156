import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';

import CustomerProjectsList from '../components/CustomerProjects/CustomerProjectsList';

export default function CustomerProjectsPage( props ){
    //console.debug("RTS Break CustomerProjectsPage");

    useEffect(() => {
        props.setIsModalFx(false);
    }, []);  



    return(
        <CustomerProjectsList />
    );

}

