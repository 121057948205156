import axios from 'axios';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import EditCancel from '../EditCancel';
// import CrewMembersEditCustomerList from './CrewMembersEditCustomerList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersEdit(props) {
    //console.debug("RTS Break CrewMemebersEditAdd");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle] = useState("Validation Error");

    const [fName, setFName] = useState(props.data.firstName);
    const [lName, setLName] = useState(props.data.lastName);

    
    function handleSubmit(e) {
        e.preventDefault();

        let customers = [];
        let curIdGuid = sessionCustomer.customer.idGuid;

        if(props.data.appUserCustomers.filter(c => c.idGuid === curIdGuid).length > 0){
            setErrorBody("User is already associated with this customer");
            setShowErrorModal(true);
            return;
        }
		props.data.appUserCustomers.push({customer:sessionCustomer.customer, accessLevel:1});

        let apiObj = {
            userName: props.data.email,
            email: props.data.email,
            firstName: props.data.firstName,
            lastName: props.data.lastName,
            status: props.data.status,
            adminFlag: props.data.adminFlag,
            manufacturerFlag: props.data.manufacturerFlag,
            distributorFlag: props.data.distributorFlag,
            appUserCustomers: props.data.appUserCustomers,
            licensedBy: props.data.licensedBy,
            licensedId: props.data.licensedId,
            modifyUser: user.userName,
            modReelsFlag: 1
        }

        let apiURL = apiRoot + "/AppUsers/UpdateAppUser";
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateUser Error", error);
            setShowErrorModal(true);

        });
    } 

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box>
            <form id="requestForm" onSubmit={handleSubmit}>
                <Box>
                    <Typography sx={{fontWeight:'bold', pb:1}}>
                        A user with this email address already exists in the system.  
                        To associate this user with your organization click the save 
                        button or type a new email address in the above field and re-validate.
                    </Typography>
                    <Typography>{sessionCustomer.name}</Typography>
                </Box>
                <TextField
                    id="tbFName"
                    margin="normal"
                    fullWidth
                    label="First Name"
                    size="small"
                    required
                    value={fName}
                    onChange={(e) => {setFName(e.target.value);}}
                    disabled
                />
                <TextField
                    id="tbLName"
                    margin="normal"
                    fullWidth
                    label="Last Name"
                    size="small"
                    required
                    value={lName}
                    onChange={(e) => {setLName(e.target.value);}}
                    disabled
                />
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
        </Box>
        </>
    );
}
