import axios from 'axios';
import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import LoadListListItem from '../LoadList/LoadListListItem';
import LoadDetailsMap from './LoadDetailsMap';
import LoadDetailsPullList from './LoadDetailsPullList';
import LoadDetailsPinList from './LoadDetailsPinList';
import LoadDetailsCancel from './LoadDetailsCancel';
import LoadDetailsPullListDetail from './LoadDetailsPullListDetail';
import LoadDetailsProject from './LoadDetailsProject';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadDetails(props){
    //console.debug("RTS Break LoadDetails");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showPullDetailModal, setShowPullDetailModal] = useState(false);
    const [pullDetail, setPullDetail] = useState({});
    const contractToDisplay = props.data.contractToCustomer != null?props.data.contractToCustomer.name + '(' + props.data.contractToCustomer.idSap + ')':'';

    function showPullDetail(args){
        setPullDetail(args);
        setShowPullDetailModal(true);
    }

     
    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>         
        <Dialog open={showPullDetailModal} onClose={()=>{setShowPullDetailModal(false);}}>
            <DialogTitle>Pull Detail</DialogTitle>
            <DialogContent>
                <LoadDetailsPullListDetail data={pullDetail} />
            </DialogContent>
            <DialogActions>
                <Button type="button" 
                    title="Ok" 
                    className="rtsButton rtsDarkBackground"
                    onClick={e => {setShowPullDetailModal(false);}}>
                        Close
                </Button>
            </DialogActions>
        </Dialog>
        <Grid container>
            <Grid item xs={12}>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true} />
            </Grid>

            <Grid item xs={12} md={6} sx={{pr:{xs:0, md:1}, pt:2}}>
                <Stack>
                    <Typography variant="h6">Location History</Typography>
                    <Container>
                        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', height:30}}>
                            <Box sx={{display:'flex', mr:1}}>
                                <Box className="rtsMarkerSmallInitLegend" sx={{mt:.6}}></Box>
                                <Typography>In Transit</Typography>
                            </Box>
                            <Box sx={{display:'flex', mr:1}}>
                                <Box className="rtsMarkerSmallOnSiteLegend" sx={{mt:.6}}></Box>
                                <Typography>On Site</Typography>
                            </Box>
                            <Box sx={{display:'flex', mr:1}}>
                                <Box className="rtsMarkerSmallWipLegend" sx={{mt:.6}}></Box>
                                <Typography>Work In Progress</Typography>
                            </Box>
                            <Box sx={{display:'flex', mr:1}}>
                                <Box className="rtsMarkerSmallRetLegend " sx={{mt:.6}}></Box>
                                <Typography>Returning</Typography>
                            </Box>
                            <Box sx={{display:'flex'}}>
                                <Box className="rtsMarkerSmallCurLegend" sx={{mt:.6}}></Box>
                                <Typography>Current</Typography>
                            </Box>
                        </Box>
                    </Container>
                </Stack>
                <LoadDetailsMap data={props.data} user={props.user} />
            </Grid>

            <Grid item xs={12} md={6} sx={{pl:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{mb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Pulls</Typography>
                    </Grid>
                    {sessionCustomer.accessLevel > 25 && props.data.status === 1
                    ?
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Button 
                            type="button" 
                            variant="contained"
                            onClick={()=>(props.modeFx(11))} 
                        >
                            Manage Pulls
                        </Button>
                    </Grid>
                    :
                    ""
                    }
                </Grid>
                <LoadDetailsPullList data={props.data} user={props.user} showPullDetailFx={showPullDetail} />
            </Grid>

            <Grid item xs={12} md={6} sx={{pr:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{pb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Ownership</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        {
                            sessionCustomer.accessLevel > 25 
                            && props.data.status === 1 
                            && (sessionCustomer.customer.idGuid === props.data.soldToCustomer.idGuid 
                                || sessionCustomer.customer.idGuid === props.data.assignedToCustomer.idGuid)
                            ?
                            <Grid item sx={{textAlign:'right'}}>
                                <Button 
                                    type="button" 
                                    variant="contained"
                                    onClick={()=>(props.modeFx(8))} 
                                >
                                    Change Ownership
                                </Button>
                            </Grid>
                            :
                            ""
                        }
                    </Grid>
                </Grid>
                <Box sx={{border:'1px solid #e2e8f0', borderRadius:'5px', p:1}}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Sold To:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.soldToCustomer.name} ({props.data.soldToCustomer.idSap})</Typography>
                    </Box>                
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Assigned To:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.assignedToCustomer.name} ({props.data.assignedToCustomer.idSap})</Typography>
                    </Box>                
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Contracted To:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{contractToDisplay}</Typography>
                    </Box>
                </Box>                
            </Grid>

            <Grid item xs={12} md={6} sx={{pl:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{mb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Project Details</Typography>
                    </Grid>
                    {sessionCustomer.accessLevel > 25
                    ?
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Button 
                            type="button" 
                            variant="contained"
                            onClick={()=>(props.modeFx(10))} 
                            >
                            Change Project
                        </Button>
                    </Grid>
                    :
                    ""
                    }
                </Grid>                
               <LoadDetailsProject data={props.data} user={props.user} />
            </Grid>

            <Grid item xs={12} md={6} sx={{pl:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{mb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Users With Reel Pinned</Typography>
                    </Grid>
                    {sessionCustomer.accessLevel > 1
                    ?
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Button 
                            type="button" 
                            variant="contained"
                            onClick={()=>(props.modeFx(9))} 
                            >
                            Add Pin
                        </Button>
                    </Grid>
                    :
                    ""
                    }
                </Grid>                
               <LoadDetailsPinList data={props.data} user={props.user} />
            </Grid>                           
        </Grid>
      
        <LoadDetailsCancel modeFx={props.modeFx} baseMode={props.baseMode} />
        </>
    )
}
