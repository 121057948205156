import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import SystemNamedLocationsMapSpatial from "./SystemNamedLocationsMapSpatial";

export default function SystemNamedLocationsMap( props ) {
    return(
        <Box>
            <Grid container>
                <Grid item xs={7} md={8} lg={9}>
                    <SystemNamedLocationsMapSpatial pointsFx={props.pointsFx} mode={props.mode} data={props.data} />
                </Grid>
            </Grid>
        </Box>
    );
}
