import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import PickupRequestAdminEdit from '../../components/PickupRequestsAdmin/PickupRequestsAdminEdit';

export default function PickupRequestsAdminPageEdit( props ){

    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 
    
    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Typography variant="h5" sx={{my:2}}>Edit Pickup Request</Typography>
            <Box>
                <PickupRequestAdminEdit data={props.data} mode={props.mode} />
            </Box>
        </Container>  
    );
}
