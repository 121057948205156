import { useNavigate } from 'react-router-dom';
import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import WorkSetListBody from './WorkSetListBody';

export default function WorkSetsList(props){
    //console.debug("RTS Break WorkSetsList");

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    function SessionCustomerToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <Box sx={{width:"50%"}}>
                    <GridToolbarFilterButton />
                </Box>
                <Box sx={{width:"50%", display:"flex", justifyContent:"right"}}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    }


    const columns = [
        {
            field: 'displayVal', 
            headerName: "", 
            description:"Work Package Item", 
            flex:10,
            renderCell: (cellValues) => {
                    return (
                        <WorkSetListBody data={cellValues.row} mode={0} />
                    );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true                       
        },
        {
            field: 'name', 
            headerName: "Name", 
            description:"Program Name", 
            flex:4,
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true         },
        {
            field: 'workSetType', 
            headerName: "Type", 
            description:"Work Set Type", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.workSetType.name
            },
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true             
        },           
        {
            field: 'createDate', 
            headerName: "Create Date", 
            description:"Create Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            },
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true              
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"Work Set Status", 
            flex:3,
            valueGetter: (cellValues) => {
                switch(cellValues.row.status){
                    case 100:
                        return "Complete";
                        break;

                    case 50:
                        return "In Progress";
                        break;

                    default:
                        return "New";
                        break;
                }
            },
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true             
        },        
    ];

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: SessionCustomerToolbar,  }}
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
                getRowHeight={() => 'auto'}
                headerHeight={0}
                sx={{
                    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                    outline: "none !important",
                    border: "none !important"
                    },
                }}
            />
        </Box>
        </>        
    );
}
