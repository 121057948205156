import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import EditCancel from '../EditCancel';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductsEdit(props) {
    //console.debug("RTS Break ProductsEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [idSap, setIdSap] = useState(mode===1?data.idSap!==null?data.idSap:'':'');
    const [name, setName] = useState(mode===1?data.name!==null?data.name:'':'');
    const [shortName, setShortName] = useState(mode===1?data.shortName!==null?data.shortName:'':'');
    const [desc, setDesc] = useState(mode===1?data.desc!==null?data.desc:'':'');
    const [productHierarchy, setProductHierarchy] = useState(mode===1?data.productHierarchy!==null?data.productHierarchy:'':'');
    const [productImage, setProductImage] = useState(mode===1?data.productImage!==null?data.productImage:'':'');
    const [specsDoc, setSpecsDoc] = useState(mode===1?data.specsDoc!==null?data.specsDoc:'':'');
    const [pullySize, setPullySize] = useState(mode===1?data.pullySize!==null?data.pullySize:'':'');
    const [pullyDistance, setPullyDistance] = useState(mode===1?data.pullyDistance!==null?data.pullyDistance:'':'');
    const [stringInstructions, setStringInstructions] = useState(mode===1?data.stringInstructions!==null?data.stringInstructions:'':'');
    const [weightPerFt, setWeightPerFt] = useState(mode===1?data.weightPerFt:0);



    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid===''?null:idGuid,
            IdSap: idSap===''?null:idSap,
            Name: name===''?null:name,
            ShortName: shortName===''?null:shortName,
            Desc: desc===''?null:desc,
            ProductHierarchy: productHierarchy===''?null:productHierarchy,
            ProductImage: productImage===''?null:productImage,
            SpecsDoc: specsDoc===''?null:specsDoc,
            PullySize: pullySize===''?null:pullySize,
            PullyDistance: pullyDistance===''?null:pullyDistance,
            StringInstructions: stringInstructions===''?null:stringInstructions,
            WeightPerFt: weightPerFt===''?null:weightPerFt,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Products/UpdateProduct/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateProduct Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Products/CreateProduct";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateProduct Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box>
            <form id="productForm" onSubmit={handleSubmit}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {setName(e.target.value);}}
                />
                <TextField
                    id="tbShortName"
                    margin="normal"
                    fullWidth
                    label="Short Name"
                    size="small"
                    required
                    value={shortName}
                    onChange={(e) => {setShortName(e.target.value);}}
                />
                <TextField
                    id="tbIdSap"
                    margin="normal"
                    fullWidth
                    label="SAP Id"
                    size="small"
                    value={idSap}
                    onChange={(e) => {setIdSap(e.target.value);}}
                />
                <TextField
                    id="tbDesc"
                    margin="normal"
                    fullWidth
                    label="Description"
                    size="small"
                    value={desc}
                    onChange={(e) => {setDesc(e.target.value);}}
                />
                <TextField
                    id="tbProductHierarchy"
                    margin="normal"
                    fullWidth
                    label="Product Hierarchy"
                    size="small"
                    required
                    value={productHierarchy}
                    onChange={(e) => {setProductHierarchy(e.target.value);}}
                />
                <TextField
                    id="tbWeightPerFt"
                    margin="normal"
                    fullWidth
                    label="Weight Per. Ft. (lbs)"
                    size="small"
                    required
                    value={weightPerFt}
                    onChange={(e) => {setWeightPerFt(e.target.value);}}
                />    
                <TextField
                    id="tbProductImage"
                    margin="normal"
                    fullWidth
                    label="Product Image URL"
                    size="small"
                    value={productImage}
                    onChange={(e) => {setProductImage(e.target.value);}}
                />  
                <TextField
                    id="tbSpecsDoc"
                    margin="normal"
                    fullWidth
                    label="Product Specifications Document URL"
                    size="small"
                    value={specsDoc}
                    onChange={(e) => {setSpecsDoc(e.target.value);}}
                />
                <TextField
                    id="tbPullySize"
                    margin="normal"
                    fullWidth
                    label="Pully Size"
                    size="small"
                    value={pullySize}
                    onChange={(e) => {setPullySize(e.target.value);}}
                />                                                 
                <TextField
                    id="tbPullyDistance"
                    margin="normal"
                    fullWidth
                    label="Pully Distance"
                    size="small"
                    value={pullyDistance}
                    onChange={(e) => {setPullyDistance(e.target.value);}}
                />                                   
                <TextField
                    id="tbStringInstructions"
                    margin="normal"
                    fullWidth
                    label="Stringing Instructions"
                    size="small"
                    value={stringInstructions}
                    onChange={(e) => {setStringInstructions(e.target.value);}}
                />                                         
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
        </Box>
        </>
    );
}
