import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';

export default function WorkSetsHeader(props){
    const history = useNavigate();

    function handleClick(e){
        history("/WorkSetsEdit", {state:{data:{}, mode: 0}});
    }

    return (
        <Grid container sx={{mb:1}}>
            <Grid item xs={6}>
                <Typography variant="h5">Work Sets</Typography>
            </Grid>
        </Grid>
    )
}

