import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export default function ProductAliasHeader(props){
    const history = useNavigate();

    return (
        <Grid container sx={{mb:1}}>
            <Grid item xs={6}>
                <Box display="flex">
                    <Typography variant="h5">Customer Parts</Typography>
                    <Link onClick={() => {props.setHelpListOverviewFx(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <HelpOutlineIcon variant="h5" bold sx={{ml:1, mt:.5}}  />
                    </Link>
                </Box>                 
            </Grid>
            <Grid item xs={6} sx={{textAlign:'right'}}>
                <Button type="button" variant="contained" onClick={handleClick}>
                    New Part
                </Button>
            </Grid>
        </Grid>
    )

    function handleClick(e){
        let curKeys = props.data.map(a => a.type===0?a.product.idGuid:null);
        curKeys = curKeys.filter(a => a != null);
        history("/ProductAliasEdit", {state:{data:{}, mode: 0, keys: curKeys}});
    }
}
