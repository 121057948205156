import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Loading from '../components/Loading';
import Error from '../components/Error';
import CrewMembersList from '../components/CrewMembers/CrewMembersList';
import CrewMembersHeader from '../components/CrewMembers/CrewMembersHeader';
import CrewMembersRemove from '../components/CrewMembers/CrewMembersRemove';
import HelpListOverview from '../components/CrewMembers/HelpListOverview';

import { useSessionContext } from '../contexts/SessionContext';

export default function CrewMembersPage( props ){
    //console.debug("RTS Break CrewMembersPage");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [helpListOverview, setHelpListOverview] = useState(false);

    const [mode, setMode] = useState(0);
    const [selUser, setSelUser] = useState();
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({"user":user, 
                                        "customers":[sessionCustomer.customer], 
                                        "sortBy":0 
                                        });   

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);


    function fetchData(){
        let apiURL = apiRoot + "/AppUsers/GetCrewMembersByFilter";
        axios
        .post(
            apiURL,
            filter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, [filter]);  


    function dataFilter(args){
        setIsLoading(true);
        let locFilter = {"user":user,
                        "customers":[sessionCustomer.customer], 
                        "sortBy":0 
                        }

        setFilter(locFilter);
        setMode(0);
    }

    function dataRefresh(){
        setIsLoading(true);
        fetchData();
        setMode(0);
    }    

    
    function remove(args){
        setSelUser(args);
        setMode(1);
    }


    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
        <Dialog open={helpListOverview} onClose={()=>{setHelpListOverview(false);}}>
            <DialogTitle>Crew Members Help</DialogTitle>
            <DialogContent>
                <HelpListOverview />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setHelpListOverview(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Box id="pageBox">
            <CrewMembersHeader  setHelpListOverviewFx={setHelpListOverview} />
            <Box sx={{mt:2}}>           
                <CrewMembersList data={data} user={user} removeFx={remove} dataRefreshFx={dataRefresh}/>
            </Box>
        </Box>
        </>
    );
}
