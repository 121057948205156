import axios from 'axios';
import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import ReelWipReelList from './ReelWipReelList';
import ReelWipTrackerList from './ReelWipTrackerList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelWipHeader(props){
    //console.debug("RTS Break ReelWipHeader");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    
    const [reel, setReel] = useState(null);
    const [tracker, setTracker] = useState(null)

    function handleSubmit(e) {
        e.preventDefault();
 
        let apiObj = {
            IdGuid: "",
            Reel: reel,
            Tracker: tracker,
            createUser: user.userName,
            modifyUser: user.userName
        }

        if(apiObj.Reel === null){
            setErrorTitle("Validation Error");
            setErrorBody("Reel is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Tracker === null){
            setErrorTitle("Validation Error");
            setErrorBody("Tracker is required");
            setShowErrorModal(true);
            return;
        }

        let data = props.data;
        
        // Check records for this Reel.
        let reelCheck = data.filter(rw => {
            if(rw.reel.serialNumber === reel.serialNumber){
                if(rw.sentDate === null && rw.load === null){
                    return rw;
                }
                else{
                    if(rw.sentDate !== null && rw.load === null){
                        return rw;
                    }
                }
            }
        })
        if(reelCheck && reelCheck.length > 0){
            setErrorTitle("Validation Error");
            setErrorBody("A record for this Reel is already pending.  Review the WIP list and remove the record if possible.");
            setShowErrorModal(true);
            return;            
        }

        // Check records for this tracker.
        let trackerCheck = data.filter(rw => {
            if(rw.tracker.esn === tracker.esn){
                if(rw.sentDate === null && rw.load === null){
                    return rw;
                }
                else{
                    if(rw.sentDate !== null && rw.load === null){
                        return rw;
                    }
                }
            }
        })
        if(trackerCheck && trackerCheck.length > 0){
            setErrorTitle("Validation Error");
            setErrorBody("A record for this Tracker is already pending.  Review the WIP list and remove the record if possible.");
            setShowErrorModal(true);
            return;            
        }

        // Catch all check of data.        
        let dataCheck = data.filter(rw => {
            if(rw.reel.serialNumber === reel.serialNumber){
                if(rw.tracker.esn === tracker.esn){
                    if(rw.sentDate === null && rw.load === null){
                        return rw;
                    }
                    else{
                        if(rw.sentDate !== null && rw.load === null){
                            return rw;
                        }
                    }
                }
            }
        })
        if(dataCheck && dataCheck.length > 0){
            setErrorTitle("Validation Error");
            setErrorBody("A record for this Reel and Tracker is already pending.  Review the WIP list and remove the record if possible.");
            setShowErrorModal(true);
            return;            
        }

        let apiURL = apiRoot + "/ReelWip/CreateReelWip";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.fetchDataFx();
        })
        .catch(error => {
            setErrorTitle("Insert Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS Creat Reel WIP Error", error);
            setShowErrorModal(true);
        });
    }     


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <Box>
            <Box>
                <Typography variant="h5">Reel W.I.P.</Typography>
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <ReelWipReelList reel={reel} setReelFx={setReel} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <ReelWipTrackerList tracker={tracker} setTrackerFx={setTracker} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1, textAlign:{xs:'center', sm:'left'}}}>
                        <Button variant="contained"  type="submit" sx={{mt:{xs:0,lg:2}}}>
                            Add Record
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
        </>            
    )
}
