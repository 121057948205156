import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit'; 

import Loading from '../Loading';
import Error from '../Error';


import { useSessionContext } from '../../contexts/SessionContext';


export default function UsersRequestImport(props){
    //console.debug("RTS Break UsersRequestImport")

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [data, setData] = useState([]);

    function CustomToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }    

    function fetchData(){
        let apiURL = apiRoot + "/UserRequests/GetActiveUserRequests";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    } 
    
    
    useEffect(() => {
        fetchData();
    }, []);     


    function handleClick(e, cellValues){
        props.setImportRequestFx(cellValues.row);
    }

    const columns = [
        {
            field: '',
            description:"Select Request", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'customer', 
            headerName: "Customer", 
            description:"Customer Making Request", 
            flex:4,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.name
            }
        },                
        {
            field: 'customerId', 
            headerName: "Customer Id", 
            description:"Customer Id Making Request", 
            flex:2,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.idSap
            }
        },                
        {
            field: 'email', 
            headerName: "Email Address", 
            description:"Crew Member Email Address", 
            flex:4
        },
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"Crew Member First Name", 
            flex:3 
        },
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"Crew Member Last Name", 
            flex:3 
        }  
    ];


    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }    

    return (
        <Box>
            <Box id="pageBox" sx={{width:'100%', mt:1}}>
                <DataGridPro 
                    autoHeight 
                    rows={data} 
                    columns={columns} 
                    components={{Toolbar: CustomToolbar}}  
                    getRowId={row => row.email} 
                />
            </Box>
            <Box sx={{mt:2, textAlign:'right'}}>
                <Button variant="contained" onClick={e => {props.showImportModalFx(false);}}>Cancel</Button>
            </Box>
        </Box>        
    );
}
