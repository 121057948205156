import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar, visibleGridColumnsLengthSelector } from '@mui/x-data-grid-pro';

import EditIcon from '@mui/icons-material/Edit'; 

export default function ProductAliasList(props){
    //console.debug("RTS Debug ProductAliasList");
    const history = useNavigate();

    function handleClick(e, cellValues){
        let curKeys = props.data.map(a => a.type===0?a.product.idGuid:null);
        curKeys = curKeys.filter(a => a != null);
        history("/ProductAliasEdit", {state: {data: cellValues.row, mode: 1, keys: curKeys}});
    }
 
    const columns = [
        {
            field: "Edit",
            headerName: "",
            description:"Edit Product", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'SWProdId', 
            headerName: "Product/Wildcard%", 
            description:"Southwire Product Id/Wildcard", 
            flex:2, 
            valueGetter: (cellValues) => {
                return cellValues.row.type===0?cellValues.row.product.idSap:cellValues.row.wildcard + "%"
            }
        },
        {
            field: 'SWProdName', 
            headerName: "Product Name", 
            description:"Southwire Product Name", 
            flex:2, 
            valueGetter: (cellValues) => {
                return cellValues.row.type===0?cellValues.row.product.name:"N/A"
            },
            hide:true
        },        
        {
            field: 'customerMaterialNo', 
            headerName: "Cust. Part No.", 
            description:"Customer Material Number", 
            flex:2
        },        
        {
            field: 'alias', 
            headerName: "Cust. Part Name", 
            description:"Customer Part Id", 
            flex:4
        },
        {
            field: 'ageIndexLimit', 
            headerName: "Age Index", 
            description:"Product Age Index Limit", 
            flex:2, 
            hide:true
        },        
        {
            field: 'remnantLimit', 
            headerName: "Remnant Limit", 
            description:"Product Remnant Limit", 
            flex:2, 
            hide:true
        },        
    ];

    return (
        <>
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
        </>
    );
}
