import axios from 'axios';
import {useState, useContext, useEffect} from 'react';
import Link from '@mui/material/Link';

import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

import { useSessionContext } from '../../contexts/SessionContext';


export default function LoadListListItemLoadPin(props) {
    //console.debug("RTS Break LoadListListItemLoadPin");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const[data, setData] = useState(props.data);
    const[loadPin, setLoadPin] = useState(props.data.loadPin);

    function SetPinStatus(){

        let curPin = 0;
        if(loadPin === 0){
            curPin = 1;
        }

        let apiObj = {
            AppUser: user,
            Customer: sessionCustomer.customer,
            Load: data,
            Action: curPin
        }


        let apiURL = apiRoot + "/LoadList/LoadPin";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                if(response.data === 1){
                    let locData = data;
                    locData.loadPin = 1;
                    setLoadPin(1);
                    setData(locData);
                    props.setDataFx(locData);
                }
                else{
                    let locData = data;
                    locData.loadPin = 0;
                    setLoadPin(0);
                    setData(locData);
                    props.setDataFx(locData);
                }
            }
            else{
                let locData = data;
                locData.loadPin = 0;
                setLoadPin(0);
                setData(locData);
                props.setDataFx(locData);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
        });
    }


    return (
        <Link onClick={SetPinStatus} sx={{textDecoration:'none', cursor:'pointer'}}>
        {
        loadPin === 1
        ?
            <>
            <PushPinIcon sx={{fontSize:'1.5em', color:'red'}}  />
            </>
        :
            <>
            <PushPinOutlinedIcon sx={{fontSize:'1.5em', color:'red', transform:'rotate(45deg)'}}  />
            </>
        }
        </Link>
    );
}
