import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import NavDrawer from './NavDrawer';
import NavButDash from './NavButDash';
import NavButReels from './NavButReels';
import NavButAnalytics from './NavButAnalytics';
import NavButAdmin from './NavButAdmin';
import NavButManage from './NavButManage';
import NavButLocator from './NavButLocator';
import NavButSystem from './NavButSystem';
import NavButSessionCustomer from './NavButSessionCustomer';

import WorkNavButDash from './WorkNavButDash';
import WorkNavButSystem from './WorkNavButSystem';

import PalletNavButDash from './PalletNavButDash';
import PalletNavButTurns from './PalletNavButTurns';
import PalletNavButAdmin from './PalletNavButAdmin';
import PalletNavButSystem from './PalletNavButSystem';

import ManuNavButDash from './ManuNavButDash';
import ManuNavButAdmin from './ManuNavButAdmin';
import ManuNavButSystem from './ManuNavButSystem';

import InspectionNavButDash from './InspectionNavButDash';
import InspectionNavButAdmin from './InspectionNavButAdmin';
import InspectionNavButSystem from './InspectionNavButSystem';

import PasswordReset from '../PasswordReset';

import { useSessionContext } from '../../contexts/SessionContext';

export default function NavBar( props ){
    //console.debug("RTS Break NavBar");

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showPasswordSuccessModal, setShowPasswordSuccessModal] = useState(false);

    const {user, appMode, setAppMode, sessionCustomer, setSessionCustomer, userLogOut} = useSessionContext();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
      
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    let modCount = 0;
    let defMode = '';
    if(user.modReelsFlag === 1){
        modCount++;
        if(defMode === ''){
            defMode = 'REELS';
        }
    }

    if(user.modWorkSetsFlag === 1){
        modCount++;
        if(defMode === ''){
            defMode = 'WORK';
        }
    }

    if(user.modPalletsFlag === 1){
        modCount++;
        if(defMode === ''){
            defMode = 'PALLETS';
        }
    }

    if(user.modManufacturingFlag === 1){
        modCount++;
        if(defMode === ''){
            defMode = 'MANUFACTURING';
        }
    } 
    
    if(user.modReelInspectionsFlag === 1){
        modCount++;
        if(defMode === ''){
            defMode = 'REELINSPECTIONS';
        }
    }   


    if(props.isModal){
        return(
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Box sx={{px:{xs:1, md:2}}}>
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                        {
                            appMode==='PALLETS'
                            ?
                                'Pallet Tracking System'
                            :
                                appMode==='WORK'
                                ?
                                    'Work Tracking System'
                                :
                                    'Reel Tracking System'
                        }
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                        {
                            appMode==='PALLETS'
                            ?
                                'Pallet Tracking System'
                            :
                                appMode==='WORK'
                                ?
                                    'Work Tracking System'
                                :
                                    'Reel Tracking System'
                        }
                        </Typography>
                    </Toolbar>
                    <Box sx={{mt:-1, textAlign:'center'}}>
                        <Typography variant="h6">
                        {
                            appMode==='PALLETS'
                            ?
                                'Southwire Pallets'
                            :
                                appMode==="MANUFACTURING"
                                ?
                                    'Manufacturing'
                                :
                                    appMode==="REELINSPECTIONS"
                                    ?
                                        'Reel Inspections'
                                    :
                                        sessionCustomer!=null
                                        ?
                                            sessionCustomer.customer.name + ' - ' + sessionCustomer.customer.idSap
                                        :
                                            'Please Select A Customer'
                        }
                        </Typography>
                    </Box>
                </Box>
            </AppBar>
        )
    }

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showPasswordModal} onClose={()=>{setShowPasswordModal(false);}}>
            <DialogTitle>Change User Password</DialogTitle>
            <DialogContent>
                <PasswordReset mode={1} userName={user.userName} passwordToken={-1} setShowPasswordModalFx={setShowPasswordModal} />
           </DialogContent>
        </Dialog>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Box sx={{px:{xs:1, md:2}}}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                    {
                        appMode==='PALLETS'
                        ?
                            'Pallet Tracking System'
                        :
                            appMode==='WORK'
                            ?
                                'Work Tracking System'
                            :
                                'Reel Tracking System'
                    }
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip title="Open/Close Menu">
                            <IconButton
                                id="navButton"
                                size="large"
                                onClick={() => {setOpenDrawer(!openDrawer)}}
                                color="inherit"
                                sx={{ p: 0 }}
                            >
                                <MenuIcon />  
                            </IconButton>
                        </Tooltip>
                        <NavDrawer openDrawer={openDrawer} setOpenDrawerFx={setOpenDrawer} />
                    </Box>

                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                    {
                        appMode==='PALLETS'
                        ?
                            'Pallet Tracking System'
                        :
                            appMode==='WORK'
                            ?
                                'Work Tracking System'
                            :
                                'Reel Tracking System'
                    }
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {
                            appMode==='WORK'
                            ?
                                <>
                                <WorkNavButDash setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                <WorkNavButSystem setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                </>
                            :
                                appMode==='PALLETS'
                                ?
                                    <>
                                    <PalletNavButDash setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                    <PalletNavButTurns setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                    <PalletNavButAdmin setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                    <PalletNavButSystem setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                    </>
                                :
                                    appMode==='MANUFACTURING'
                                    ?
                                        <>
                                        <ManuNavButDash setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                        <ManuNavButAdmin setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                        <ManuNavButSystem setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                        </>
                                    :
                                        appMode==='REELINSPECTIONS'
                                        ?
                                            <>
                                            <InspectionNavButDash setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            <InspectionNavButAdmin setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            <InspectionNavButSystem setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            </>
                                        :                                    
                                            <>
                                            <NavButDash setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            <NavButReels setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            <NavButAnalytics setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            <NavButManage setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            <NavButAdmin setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            <NavButLocator setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            <NavButSystem setOpenDrawerFx={setOpenDrawer} mode="NavBar" />
                                            </>
                        }
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open User Details">
                            <IconButton
                                id="userButton"
                                onClick={handleOpenUserMenu} 
                                size="large"
                                color="inherit"
                                sx={{ p: 0 }}  
                                >
                                <PersonIcon />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-userDetails"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <Box key='Logout' onClick={handleCloseUserMenu} sx={{p:2, textAlign:'center'}}>
                                <Box sx={{textAlign:'left'}}>
                                    <Box sx={{display:'flex'}}>
                                        <Typography size='small' sx={{fontWeight:'bold'}}>Signed in as:</Typography>
                                        <Typography size='small' sx={{pl:1}}>{user.firstName + " " + user.lastName}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography size='small' sx={{fontWeight:'bold'}}>Email:</Typography>
                                        <Typography size='small' sx={{pl:1}}>{user.email}</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Button variant="contained" 
                                            sx={{mt:3}} 
                                            onClick={() => {setShowPasswordModal(true);}}
                                    >
                                        Change Password
                                    </Button>
                                </Box>
                                <Box>
                                    <Button variant="contained" 
                                            sx={{mt:2}} 
                                            onClick={() => {history("/Dashboard"); userLogOut();}}
                                    >
                                        Log Out
                                    </Button>
                                </Box>
                                <Box>
                                    {modCount>1
                                    ?
                                        <Button variant="contained"
                                            sx={{mt:2}}
                                            onClick={() => {setAppMode(null); setSessionCustomer(null);}}
                                        >
                                            Switch Modes
                                        </Button>
                                    :
                                        ''
                                    }
                                </Box>
                            </Box>
                        </Menu>
                    </Box>
                </Toolbar>
                <Box sx={{mt:-2, textAlign:'center'}}>
                    <Grid container wrap="nowrap" direction="row" alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography variant="h6" sx={{display:'inline', fontSize:{xs:'.75em', sm:'1em', md:'1.25em'}}}>
                            {
                                appMode==='PALLETS'
                                ?
                                    'Southwire Pallets'
                                :
                                    appMode==='MANUFACTURING'
                                    ?
                                        'Manufacturing'
                                    :
                                        appMode==='REELINSPECTIONS'
                                        ?
                                            'Reel Inspections'
                                        :
                                            sessionCustomer!=null
                                            ?
                                            sessionCustomer.customer.name + ' - ' + sessionCustomer.customer.idSap
                                            :
                                            'Please Select A Customer'
                            }
                            </Typography>
                        </Grid>
                        <Grid item>
                            {
                                appMode==='PALLETS'
                                ?
                                    ''
                                :
                                    appMode==='MANUFACTURING'
                                    ?
                                        ''
                                    :
                                        appMode==='REELINSPECTIONS'
                                        ?
                                            ''
                                        :
                                            user.appUserCustomers.length>1
                                            ?
                                            <NavButSessionCustomer />
                                            :
                                            user.adminFlag === 1
                                            ?
                                                <NavButSessionCustomer />
                                            :
                                                user.reelAdminFlag === 1
                                                ?
                                                    <NavButSessionCustomer />
                                                :
                                                    ''
                        }
                        </Grid>
                    </Grid>                    
                </Box>                
            </Box>
        </AppBar>
        </>
    );
}



