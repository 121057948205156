import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import ReelTypesEdit from '../../components/ReelTypes/ReelTypesEdit';

export default function ReelTypesPageEdit( props ){
    //console.debug("RTS Break ReelTypesPageEdit");

    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 

    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Typography variant="h5" sx={{my:2}}>Edit Reel Type</Typography>
            <Box>
                <ReelTypesEdit />
            </Box>
        </Container>
    );
}
