import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';

import EditCancel from '../EditCancel';
import EditStatus from '../EditStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PalletsEdit(props) {
    //console.debug("RTS Break PalletsEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [name, setName] = useState(mode===1?data.name!==null?data.name:'':'');
    const [esn, setEsn] = useState(mode===1?data.esn!=null?data.esn:'':'');
    const [idSap, setIdSap] = useState(mode===1?data.idSap!==null?data.idSap:'':'');
    const [purchaseDate, setPurchaseDate] = useState(mode===1?data.purchaseDate!==null?format(parseISO(data.purchaseDate), 'MM-dd-yyyy'):null:format( new Date(), 'MM-dd-yyyy'));
    const [status, setStatus] = useState(mode===1?data.status:1);


    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid===''?null:idGuid,
            Name: name===''?null:name,
            ESN: esn===''?null:esn,
            IdSap: idSap===''?null:idSap,
            PurchaseDate: purchaseDate===''?null:purchaseDate,
            Status: status===''?null:status,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Pallets/UpdatePallet/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdatePallet Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Pallets/CreatePallet";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreatePallet Error", error);
                setShowErrorModal(true);
            });
        }
    } 

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="palletForm" onSubmit={handleSubmit}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {setName(e.target.value);}}
                />                
                <TextField
                    id="tbEsn"
                    margin="normal"
                    fullWidth
                    label="ESN"
                    size="small"
                    required
                    value={esn}
                    onChange={(e) => {setEsn(e.target.value);}}
                />
                <TextField
                    id="tbIdSap"
                    margin="normal"
                    fullWidth
                    label="SAP Id"
                    size="small"
                    value={idSap}
                    onChange={(e) => {setIdSap(e.target.value);}}
                />
                <DatePicker
                    id="tbPurchaseDate"
                    label="Purchase Date"
                    inputFormat="MM/dd/yyyy"
                    value={purchaseDate}
                    onChange={(val) => setPurchaseDate(val)}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                />
                <EditStatus status={status} setStatusFx={setStatus} /> 
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );
}

