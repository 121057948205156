import axios from 'axios';
import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import Loading from '../Loading';
import Error from '../Error';
import LoadPinsEditCancel from './LoadPinsEditCancel';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPinsEditCustomerUserList(props){
    //console.debug("RTS Break LoadPinsEditCustomerUserList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [selectionModel, setSelectionModel] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoUsers, setRepoUsers] = useState([]);

    function fetchData(){

        let apiObj = sessionCustomer.customer;

        let apiURL = apiRoot + "/Customers/GetCustomerUsers";
        axios
        .post(
            apiURL,
            apiObj,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoUsers(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }


    useEffect(() => {
        fetchData();
    }, []); 


    function handleClick(e, cellValues){
        let selectedRowData = repoUsers.filter((row) => selectionModel.includes(row.userName.toString()));
        props.addLoadPinsFx(selectedRowData);
    }


    function CustomToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }    

    const columns = [
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"User First Name", 
            flex:3
        },        
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"User Last Name", 
            flex:3 
        },
        {
            field: 'email', 
            headerName: "Email", 
            description:"User Email", 
            flex:4 
        }        
    ];


    return (
        <Box>
            <Box sx={{height:"400px"}}>
                <DataGridPro 
                    checkboxSelection={true} 
                    pagination 
                    pageSize={50}             
                    rows={repoUsers} 
                    columns={columns} 
                    getRowId={row => row.userName}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel} 
                    components={{Toolbar: CustomToolbar}}            
                />
            </Box>
            <Box sx={{mt:2, textAlign:'center'}}>
                <Button variant="contained" onClick={handleClick} sx={{mr:2}}>Add Pins</Button>
                <LoadPinsEditCancel modeFx={props.modeFx} dataRefreshFx={props.dataRefreshFx} />
            </Box>
        </Box>
    );
}
