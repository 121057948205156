import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography>
                The Reels page allows for the addition and maintenance of a physical reel to the system.  The list of 
                reels can be filtered by selecting the filter button on the menu at the top of the list of reels.  As 
                you type in the filter box the list will show results that match your entered filter.  You also have the 
                option of chosing exclusive filters like only show reels that have trackers attached.
            </Typography>
            <Typography sx={{mt:1}}>
                The fields tracked within the system include:
            </Typography>
            <table>
                <tbody>
                <tr>
                    <td>
                        Serial Number
                    </td>
                    <td>
                        The serial number of the physical reel.  This number is what is used to track the 
                        reel within the SAP system.
                    </td>
                </tr>
                <tr>
                    <td>
                        Reel Type
                    </td>
                    <td>
                        The type of reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Tracker
                    </td>
                    <td>
                        A pick list of Trackers within the system.  Using this list you can choose which
                        tracker is attached to the reel.  The system will not allow you to associate a 
                        tracker with more than one reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Active
                    </td>
                    <td>
                        The Active flag indicates if this reel is still being used by Southwire.  When a 
                        reel is destroyed we cannot delete it from the system because of historical records
                        would still need to reference that reel.
                    </td>
                </tr>
                </tbody>
            </table>          
        </Box>
    );

}
