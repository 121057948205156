import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function LoadDetailsPinList(props){
    //console.debug('RTS Break LoadDetailsPinList');

    const columns = [
        {field:"pinUser",
            headerName:"User",
            description:"User With Load Pinned",
            flex:12,
            valueGetter: (cellValues) => {
                return (
                    cellValues.row.appUser.firstName + ' ' + cellValues.row.appUser.lastName
                )
            },
        }
    ];

    return (
        <Box sx={{height:320}}>
            <DataGridPro rows={props.data.loadPins} columns={columns} getRowId={row => row.appUser.userName} />
        </Box>
    );
}
