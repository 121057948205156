import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSessionContext } from '../../contexts/SessionContext';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';


export default function NavButSessionCustomer( props ){
    const {setSessionCustomer} = useSessionContext();

    const handleClick = (e) => {
        setSessionCustomer(null);
    }
    
    return(
            <Tooltip title="Open Customer Selector">
                <IconButton
                id="sessionCustomerButton"
                onClick={handleClick} 
                color="inherit"
                sx={{ p: 0, mt:-1, mb:-1, fontSize:'3em', verticalAlign:'center' }}  
                >
                    <ArrowDropDownIcon fontSize='6em' sx={{p:0, m:0}} />
                </IconButton>
            </Tooltip>
    );
}



