import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import FilterCustomerList from '../FilterCustomerList';
import PickupRequestsAdminFilterStatus from './PickupRequestsAdminFilterStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsAdminFilter(props) {
    //console.debug("RTS Break PickupRequestsAdminFilter");

    const {user} = useSessionContext();
    const [customers, setCustomers] = useState([]);
    const [status, setStatus] = useState([50,75]);
    const [showFilter, setShowFilter] = useState(true);

    function setParentFilter(e){
        let args = {"customers":customers, 
                    "products":[], 
                }
        props.filterFx(args);
    }


    function toggleShowFilter()
    {
        setShowFilter(!showFilter);
    }

    if(user.appUserCustomers.length <= 0){
        return("");
    }                      


    if(showFilter!==true){
        return(
            <Box onClick={toggleShowFilter}>
                <Button variant="text" startIcon={<ArrowCircleDownIcon />} size="small">Show Filters</Button>
            </Box>
        );
    }
    else{    
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={5} sx={{px:1, pt:1}}>
                    <FilterCustomerList setCustomersFx={setCustomers} />
                </Grid>
                <Grid item xs={12} sm={6} lg={5} sx={{px:1, pt:1}}>
                    <PickupRequestsAdminFilterStatus status={status} setStatusFx={setStatus} />
                </Grid>                
                <Grid item xs={12} sm={4} lg={2} sx={{textAlign:{xs:'center',sm:'left'}}}>
                    <Button variant="contained" onClick={setParentFilter}>Filter</Button>
                </Grid>
                <Grid item xs={12}>
                    <Box onClick={toggleShowFilter}>
                        <Button variant="text" startIcon={<ArrowCircleUpOutlinedIcon />} size="small">Hide Filters</Button>
                    </Box> 
                </Grid>               
            </Grid>
        );
    }
}
