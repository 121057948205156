import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import EditIcon from '@mui/icons-material/Edit'; 

export default function CustomersList(props){
    //console.debug("RTS Break CustomerList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/CustomersEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Select Customer", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:4
        },
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Customer Id", 
            flex:2
        },
        {
            field: 'customerType', 
            headerName: "Type", 
            description:"Customer Type", 
            flex:1
        },        
        {
            field: 'mailCity', 
            headerName: "City", 
            description:"Customer City", 
            flex:3
        },
        {
            field: 'mailRegion', 
            headerName: "State", 
            description:"Customer State", 
            flex:1
        },
        {
            field: 'distributionChannel',
            headerName: "Dist. Channel",
            description: "Distribution Channel",
            flex:1
        },
        {
            field: 'salesOrg',
            headerName: "Sales Org.",
            description: "Sales Organization",
            flex:1
        },
        {
            field: 'division',
            headerName: "Division",
            description: "Division",
            flex:1
        },
        {
            field: 'salesOffice',
            headerName: "Office",
            description: "Sales Office",
            flex:1
        },
        {
            field: 'salesGroup',
            headerName: "Group",
            description: "Sales Group",
            flex:1
        },


    ];
    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pagination 
                pageSize={25} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
