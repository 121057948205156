import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import PickupRequestsAdminList from '../../components/PickupRequestsAdmin/PickupRequestsAdminList';
import PickupRequestsAdminHeader from '../../components/PickupRequestsAdmin/PickupRequestsAdminHeader';
import PickupRequestsAdminFilter from '../../components/PickupRequestsAdmin/PickupRequestsAdminFilter';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsAdminPage( props ){
    //console.debug("RTS Break PickupRequestAdminPage");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({"user":user, 
                                        "customers":[sessionCustomer.customer], 
                                        "sortBy":0 
                                        });   

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    function fetchData(){
        let apiURL = apiRoot + "/PickupRequest/GetAllPickupRequestByFilter";
        axios
        .post(
            apiURL,
            filter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, [filter]);  


    function dataFilter(args){
        setIsLoading(true);
        let locFilter = {"user":user,
                        "customers":args.customers, 
                        "sortBy":0 
                        }

        setFilter(locFilter);
    }

    function dataRefresh(){
        setIsLoading(true);
        fetchData();
    }    
    
    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box id="pageBox">
            <PickupRequestsAdminHeader />
            <PickupRequestsAdminFilter data={data} filterFx={dataFilter} />
            <Box sx={{mt:2}}>           
                <PickupRequestsAdminList data={data} user={user} dataRefreshFx={dataRefresh}/>
            </Box>
        </Box>            
    );

}
