import { useNavigate } from 'react-router-dom';
import {useState, useContext} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { format, parseISO } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Typography } from '@mui/material';
import { Tooltip } from '@mui/material';

export default function WorkSetListBody(props){
    //console.debug("RTS Break WorkSetListBody");

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    function handleClickView(e, cellValues){
        history("/WorkItems", {state:{data:props.data}});
    }

    function handleClickDelete(e, cellValues){
        history("/WorkSetsDelete", {state:{mode:1, workSet:props.data}});
    }

    function getStatus(){
        if(props.data.itemCountWorked > 0){
            if(props.data.itemCount == props.data.itemCountWorked){
                return("Complete");
            }
            else{
                return("Active");
            }
        }
        else{
            return("New");
        }
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Grid container sx={{py:1, px:1, my:1, border:'1px solid black', borderRadius:'10px'}}>
            <Grid item xs={12} sm={props.mode===1?12:9}>
                <Grid container>
                    <Grid item xs={12} md={4} flex>
                        <Box style={{display:"flex", whiteSpace:'nowrap'}}>
                            <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Name:</Typography>
                            <Typography sx={{ml:1}}>{props.data.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box style={{display:"flex"}}>
                            <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Type:</Typography>
                            <Typography sx={{ml:1}}>{props.data.workSetType.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box style={{display:"flex", whiteSpace:'nowrap'}}>
                            <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Create Date:</Typography>
                            <Typography sx={{ml:1}}>{format(parseISO(props.data.createDate), "MM-dd-yyyy")}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={4} flex>
                        <Box style={{display:"flex", whiteSpace:'nowrap'}}>
                            <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Status:</Typography>
                            <Typography sx={{ml:1}}>{getStatus()}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box style={{display:"flex", whiteSpace:'nowrap'}}>
                            <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Items:</Typography>
                            <Typography sx={{ml:1}}>{props.data.itemCount}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box style={{display:"flex", whiteSpace:'nowrap'}}>
                            <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Worked Items:</Typography>
                            <Typography sx={{ml:1}}>{props.data.itemCountWorked}</Typography>
                        </Box>
                    </Grid>
                </Grid>                
            </Grid>
            {props.mode === 0
            ?
            <Grid item xs={12} sm={3} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Button
                        variant='contained'
                        key='Receive'
                        onClick={(event) => {handleClickView(event, props.data);}}
                        sx={{ my:{xs:1,sm:0}, mx:{xs:0,sm:1}}}
                    >
                        Open
                    </Button>
                    {props.data.itemCountWorked === 0
                    ?
                    <Box sx={{textAlign:'right', pl:3}}>
                        <Tooltip title="Delete">
                            <Link onClick={(event) => {handleClickDelete(event, props.data);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
                                    <DeleteForeverIcon sx={{mx:1, color:"red"}} />
                                </Box>
                            </Link>
                        </Tooltip>                                
                    </Box>
                    :
                    <Box sx={{textAlign:'right', pl:3}}>
                        <Tooltip title="Delete">
                            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
                                <DeleteForeverIcon sx={{mx:1, color:"gray"}} />
                            </Box>
                        </Tooltip>                                
                    </Box>
                    }
                </Box>
            </Grid>
            :
            ''
            }
        </Grid>
        </>
);



}
