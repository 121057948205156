import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function LoadDetailsPullList(props){
    //console.debug('RTS Break LoadDetailsPullList');

    function handleClick(e, cellValues){
        props.showPullDetailFx(cellValues.row);
    }

    const columns = [
        {field: "pullDate",
            headerName:"Date",
            description:"Pull Date",
            flex:2,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }             
        },
        {field: "pullUser",
            headerName:"Crew Member",
            description:"Crew Member Who Performed Pull",
            flex:5
        },
        {field:"pullLength",
            headerName:"Length",
            description:"Lenght of Pull",
            flex:2,
            valueGetter: (cellValues) => {
                return (
                    cellValues.row.pullLength.toLocaleString() + " ft"
                )
            },
            align:"right"
        },
        {field: 'detailsLink', 
            description:"Show Pull Details", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <InfoOutlinedIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },
    ];

    return (
        <Box sx={{height:320}}>
            <DataGridPro 
                rows={props.data.pulls} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
