import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import Grid from '@mui/material/Grid';

import EditCancel from '../EditCancel';
import EditStatus from '../EditStatus';
import ReelsEditReelTypeList from './ReelsEditReelTypeList';
import ReelsEditTrackerList from './ReelsEditTrackerList';
import ReelsEditTrackerSequenceId from './ReelsEditTrackerSequenceId';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelsEdit(props) {
    console.debug("RTS Break ReelsEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const data = props.data;
    const mode = props.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showSequenceModal, setShowSequenceModal] = useState(false);

    const [idGuid, setIdGuid] = useState(mode===1?data.idGuid:'')
    const [reelType, setReelType] = useState(mode===1?data.reelType:null);
    const [tracker, setTracker] = useState(mode===1?data.tracker:null);
    const [status, setStatus] = useState(mode===1?data.status:1);
    const [serialNumber, setSerialNumber] = useState(mode===1?data.serialNumber!==null?data.serialNumber:'':'');
    const [idSap, setIdSap] = useState(mode===1?data.idSap!==null?data.idSap:'':'');
    
    async function handleSubmit(e) {
        e.preventDefault();

        if(serialNumber === null){
            setErrorTitle("Validation Error");
            setErrorBody("Serial Number is required");
            setShowErrorModal(true);
            return;
        }

        if(reelType === null){
            setErrorTitle("Validation Error");
            setErrorBody("Reel Type is required");
            setShowErrorModal(true);
            return;
        }

        let serialNumberCheck = await validateSerialNumber();
        if(serialNumberCheck === -1){
            return;
        }

        if(tracker !== null){
            let trackerCheck = await validateTracker();

            if(trackerCheck === -1){
                return;
            }
        }

        saveData();
    }


    function validateSerialNumber(){
        return new Promise(function(resolve, reject){
            let apiURL = apiRoot + "/Reels/GetReelBySerialNumber/" + serialNumber
            axios
            .get(
                apiURL,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    if(response.data.idGuid && response.data.idGuid.length > 0 && response.data.idGuid !== idGuid){
                        setErrorTitle("Validation Error");
                        setErrorBody("A Reel with Serial Number " + serialNumber + " already exists");
                        console.log("RTS Error", "Reel already exists");
                        setShowErrorModal(true);
                        resolve(-1);
                    }
                    else{
                        resolve(0);
                    }                    
                }
                else{
                    resolve(0);
                }
            })
            .catch(error => {
                setErrorTitle("Validation Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS Tracker Validation Error", error);
                setShowErrorModal(true);
                reject(-1);
            });
        });
    }


    function validateTracker(){
        return new Promise(function(resolve, reject){
            let apiURL = apiRoot + "/Reels/ValidateReelTracker"
            axios
            .post(
                apiURL,
                tracker, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    if(response.data.idGuid && response.data.idGuid.length > 0 && response.data.idGuid !== idGuid){
                        setErrorTitle("Validation Error");
                        setErrorBody("Tracker " + tracker.esn + " is already associated with Reel " + response.data.serialNumber);
                        console.log("RTS Error", "Tracker already associated with Reel");
                        setShowErrorModal(true);
                        resolve(-1);
                    }
                    else{
                        resolve(0);
                    }
                }
                else{
                    resolve(0);
                }
            })
            .catch(error => {
                setErrorTitle("Validation Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS Tracker Validation Error", error);
                setShowErrorModal(true);
                reject(-1);
            });
        });
    }


    function saveData(){
        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            SerialNumber: serialNumber===''?null:serialNumber,
            ReelType: reelType,
            Tracker: tracker,
            Status: status,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Reels/UpdateReel";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateReelType Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Reels/CreateReel";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateReelType Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    try{
    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showSequenceModal} onClose={()=>{setShowSequenceModal(false);}}>
            <DialogTitle>Change Sequnce Id</DialogTitle>
            <DialogContent>
                <ReelsEditTrackerSequenceId tracker={tracker} setTrackerFx={setTracker} setShowSequenceModalFx={setShowSequenceModal} />
            </DialogContent>
        </Dialog>        
        <Box>
            <form id="reelForm" onSubmit={handleSubmit}>
                <TextField
                    id="tbSerialNumber"
                    margin="normal"
                    fullWidth
                    label="Serial Number"
                    size="small"
                    required
                    value={serialNumber}
                    onChange={(e) => {setSerialNumber(e.target.value);}}
                />                                               
                <ReelsEditReelTypeList reelType={reelType} setReelTypeFx={setReelType} />
                <ReelsEditTrackerList tracker={tracker} setTrackerFx={setTracker} />
                <Grid container>
                    <Grid item xs={12} sm={4} sx={{display:"flex", justifyContent:"start", alignItems:"center"}}>
                        <Typography sx={{fontSize:'.75em'}}>Last Check In:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!==null?tracker.lastUpdate!==null?format(parseISO(tracker.lastUpdate), "MM-dd-yyyy"):'':''}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{display:"flex", justifyContent:{xs:"start", sm:"center"}, alignItems:"center"}}>
                        <Typography sx={{fontSize:'.75em'}}>Battery Level:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!==null?tracker.batteryLevel!==null?tracker.batteryLevel!=='-1'?tracker.batteryLevel+"%":'Unknown':'Unknown':''}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{display:"flex", justifyContent:{xs:"start", sm:"end"}, alignItems:"center"}}>
                        <Typography sx={{fontSize:'.75em'}}>Sequence Id:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!==null?tracker.idSeq!==null?tracker.idSeq:'-1':''}</Typography>
                        <Button variant="text" sx={{"p":0, "m":0, display:tracker!==null?"initial":"none", "pl":1}} onClick={()=>{setShowSequenceModal(true)}}>Change</Button>
                    </Grid>
                </Grid>

                <EditStatus status={status} setStatusFx={setStatus} /> 
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
        </Box>
        </>
    );
    
}
catch(e)
{
    var s = e.message;
}
}
