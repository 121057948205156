import {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';

import mapboxgl from 'mapbox-gl';
import mapboxGlDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

export default function SystemNamedLocationsMapSpatial( props ) {
    //console.debug("RTS Break SystemNamedLocationsMapSpatial");

    const map = useRef(null);
    const draw = useRef(null);

    useEffect(() => {
        if(map.current) return;

        map.current = new mapboxgl.Map({

            container: "mapContainer",
            accessToken: "pk.eyJ1IjoiamltcHRhayIsImEiOiJja3kzMXZyOHIwNnRvMnBwOGxrbWJ4ZDhiIn0.bWHzm3g7ZOvo1wcWf2w8fg",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [-85.0716746, 33.5666694],
            zoom: 9
        });

    });

    useEffect(() => {
        if(!map.current) return;

        if(draw.current) return;

        draw.current = new mapboxGlDraw({
            displayControlsDefault: false,

            // Select which mapbox-gl-draw control buttons to add to the map.
            controls: {
                polygon: true,
                trash: true
            },

            // Set mapbox-gl-draw to draw by default.
            // The user does not have to click the polygon control button first.
            defaultMode: props.mode !== 1 ? 'draw_polygon' : 'simple_select'
        });

        map.current.addControl(draw.current);

        map.current.on('draw.create', updatePoints);
        map.current.on('draw.delete', updatePoints);
        map.current.on('draw.update', updatePoints);

        map.current.on('draw.modechange', (e) => {

            const data = draw.current.getAll();

            if (draw.current.getMode() === 'draw_polygon') {

                var pids = []

              // ID of the added template empty feature
              const lid = data.features[data.features.length - 1].id

              data.features.forEach((f) => {

                if (f.geometry.type === 'Polygon' && f.id !== lid) {
                  pids.push(f.id)
                }
              })

              draw.current.delete(pids)
            }
        });

        if (props.mode === 1) {
            drawPolygon(props.data.points);
        }

    });

    function updatePoints(evt) {
        var latLons = draw.current.getAll().features;

        if (latLons.length > 1) {

        }

        if (latLons.length > 0) {
            var pts = draw.current.getAll().features[0].geometry.coordinates[0];
            props.pointsFx(pts);
        }
        else {
            props.pointsFx([]);
        }
    };


    function sortByAttr(array, attr) {
        return array.sort(function(a, b) {
            var x = a[attr]; var y = b[attr];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }


    function drawPolygon(points) {
        const sortedArr = sortByAttr(points, "pointsequence");

        let ptsArr = [];
        for (let c = 0; c < sortedArr.length; c++) {

            ptsArr.push( [ parseFloat(sortedArr[c].lng), parseFloat(sortedArr[c].lat) ] );
        }

        var geoJSON =
            {
            "type": "FeatureCollection",
            "features":
                [
                    {
                        "type": "Feature",
                        "properties": { "class_id": 1 },
                        "geometry":
                        {
                            "type": "Polygon",
                            "coordinates":
                            [
                                ptsArr
                            ]
                        }
                    }
                ]
            };
          draw.current.set(geoJSON);

          updatePoints(null);
    }

    return(
        <Box id="mapContainer" style={{ width: "550px", minWdith: "400px", maxWidth: "700px", minHeight: "400px", maxHeight: "600px" }}></Box>
    );
}
