import React from "react";
import { createContext, useState, useEffect } from "react";

export const SessionContext = createContext(null);

export const SessionContextProvider = ({ children }) => {
  //console.debug("RTS Break SessionContextProvider");

  const [user, setUser] = useState(null);
  const [userAuthorized, setUserAuthorized] = useState(false);
  const [userAuthToken, setUserAuthToken] = useState(null);
  const [sessionCustomer, setSessionCustomer] = useState(null);
  const [prevSessionCustomer, setPrevSessionCustomer] = useState(null);
  const [appMode, setAppMode] = useState(null);
  const [loadPins, setLoadPins] = useState([])

  const [customerProjectList, setCustomerProjectList] = useState(null);

  const apiRoot = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const storedSession = sessionStorage.getItem("SWTrackSession");
    if (storedSession) {
      const session = JSON.parse(storedSession);
      setUser(session.user);
      setUserAuthorized(session.userAuthorized);
      setUserAuthToken(session.userAuthToken);
      setSessionCustomer(session.sessionCustomer);
      setPrevSessionCustomer(session.prevSessionCustomer);
      setAppMode(session.appMode);
      setLoadPins(session.loadPins);
      setCustomerProjectList(session.customerProjectList);
    }
  }, []);

  useEffect(() => {
    saveSession();
  }, [
      user, 
      userAuthorized, 
      userAuthToken, 
      sessionCustomer, 
      prevSessionCustomer, 
      appMode, 
      loadPins, 
      customerProjectList
    ]
  )


  const userLogOut = () => {
    setUserAuthToken("");
    setUser(null);
    setUserAuthorized(false);
    setSessionCustomer(null);
    setPrevSessionCustomer(null);
    setAppMode(null);
    setLoadPins([]);
    setCustomerProjectList(null);
  }


  const saveSession = () => {
    const sessionObject = { user: user, 
                            userAuthorized: userAuthorized, 
                            userAuthToken: userAuthToken, 
                            sessionCustomer: sessionCustomer, 
                            prevSessionCustomer: prevSessionCustomer,
                            appMode: appMode,
                            loadPins: loadPins,
                            customerProjectList: customerProjectList
                           };
    sessionStorage.setItem("SWTrackSession", JSON.stringify(sessionObject));  
  }


  const value = {
    user,
    setUser,
    userAuthorized,
    setUserAuthorized,
    userAuthToken,
    setUserAuthToken,
    sessionCustomer,
    setSessionCustomer,
    prevSessionCustomer,
    setPrevSessionCustomer,
    appMode,
    setAppMode,
    loadPins,
    setLoadPins,
    customerProjectList,
    setCustomerProjectList,
    apiRoot,
    userLogOut,
    saveSession
  };


  return(
      <SessionContext.Provider value={value}> {children} </SessionContext.Provider>
  );

};

export const useSessionContext = () => React.useContext(SessionContext);
