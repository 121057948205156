import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { format, parseISO } from 'date-fns';

import { useSessionContext } from '../../contexts/SessionContext';


export default function LoadDetailsProject(props){
    //console.debug("RTS Break LoadDetailsProject");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const[showProjectList, setShowProjectList] = useState(false);

    function showLinkChange(){
        if(props.data!==null && sessionCustomer.accessLevel > 1){
            return true;
        }
        else{
            return false;
        }
    }

    function showManagerName(){
        if(props.data.customerProject==null){
            return "";
        }
        else{
            if(props.data.customerProject.manager==null){
                return "";
            }
            else{
                return props.data.customerProject.manager.firstName + " " + props.data.customerProject.manager.lastName;
            }
        }
    }
    
    return (
        <Box sx={{border:'1px solid #e2e8f0', borderRadius:'5px', p:1}}>        
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Project Name:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{props.data.customerProject==null?"No Project":props.data.customerProject.name}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Description:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{props.data.customerProject==null?"":props.data.customerProject.desc}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Manager:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{showManagerName()}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>External Id:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{props.data.customerProject==null?"":props.data.customerProject.extId}</Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Start Date:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.customerProject==null?"":format(parseISO(props.data.customerProject.startDate), "MM-dd-yyyy")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>End Date:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.customerProject==null?"":format(parseISO(props.data.customerProject.endDate), "MM-dd-yyyy")}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

