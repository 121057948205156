import { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Image from 'mui-image';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import Loading from '../components/Loading';
import { useSessionContext } from '../contexts/SessionContext';

import Copyright from '../components/Copyright';
import logo from './logo-Southwire.png';


const AppModePage = (props) => {
    //console.debug("RTS Break AppModePage");

    const history = useNavigate();
    const userRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const {user, setAppMode, setSessionCustomer, setPrevSessionCustomer} = useSessionContext();


    if(isLoading){
        return(
            <Loading />
        );
    }


    function setCurrentMode(val){
        setAppMode(val);
        
        if(val==='PALLETS' || val==='MANUFACTURING' || val==='REELINSPECTIONS'){
            setSessionCustomer({accessLevel:100, customer:{idGuid:'-99', idSap:'', name:'None'}});
            setPrevSessionCustomer({accessLevel:100, customer:{idGuid:'-99', idSap:'', name:'None'}});
        }
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box>
                    <Typography component="h1" variant="h5">
                        Reel Tracking System
                    </Typography>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography sx={{fontSize:'.5em', fontWeight:'bold', textAlign:'right', width:'60%'}}>
                            Powered By
                        </Typography>
                        <Image src={logo} wrapperStyle={{justifyContent:'left', width:'30%', paddingLeft:'5px'}} style={{width:"100%"}} />
                    </Box>
                </Box>
                <Typography component="h1" variant="h5">
                    Application Mode
                </Typography>
                <Stack spacing={2} sx={{mt:2}}>
                    {
                        user.modReelsFlag===1
                        ?
                        <Button variant='contained' onClick={() => {setCurrentMode('REELS');}}>
                            <Typography>Reel Tracking</Typography>
                        </Button>
                        :
                        ''
                    }
                    {
                        user.modWorkSetsFlag===1
                        ?
                        <Button variant='contained' onClick={() => {setCurrentMode('WORK');}}>
                            <Typography>Work Tracking</Typography>
                        </Button>
                        :
                        ''
                    }
                    {
                        user.modPalletsFlag===1
                        ?
                        <Button variant='contained' onClick={() => {setCurrentMode('PALLETS');}}>
                            <Typography>Pallet Tracking</Typography>
                        </Button>
                        :
                        ''
                    }
                    {
                        user.modManufacturingFlag===1
                        ?
                        <Button variant='contained' onClick={() => {setCurrentMode('MANUFACTURING');}}>
                            <Typography>Manufacturing</Typography>
                        </Button>
                        :
                        ''
                    }   
                    {
                        user.modReelInspectionsFlag===1
                        ?
                        <Button variant='contained' onClick={() => {setCurrentMode('REELINSPECTIONS');}}>
                            <Typography>Reel Inspections</Typography>
                        </Button>
                        :
                        ''
                    }                                        
                </Stack>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      );
}

export default AppModePage;
