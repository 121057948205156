import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography>
                The Reels page allows for the quick creation of a Reel Damage Checklist.
            </Typography>
            <Typography sx={{mt:1}}>
                To start a new inspection simply find the reel to inspect in the list and click on the 
                pencil icon for the reel.   
            </Typography>
            <Typography sx={{mt:1}}>
                To filter the list of reels you can enter the Reel Serial Number into the search bar at
                the top right of the table or choose the filter option from the menu for more advanced 
                filtering capabilities.   
            </Typography>
            <Typography sx={{mt:1}}>
                A Steel Reel should have the following inspections performed prior to shipping to a customer:
            </Typography>
            <table>
                <tbody>
                <tr>
                    <td>
                        1. Check for cracks in the ribs of the reel flange.
                    </td>
                </tr>
                <tr>
                    <td>
                        2. Check for abrasions in the flange.
                    </td>
                </tr>
                <tr>
                    <td>
                        3. Check for burs on the lagging tire.
                    </td>
                </tr>
                <tr>
                    <td>
                        4. Check for damage to the corssarms or the arbor.
                    </td>
                </tr>
                <tr>
                    <td>
                        5. Check the straightness of the reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        6. Check the two D-rings in the starter pocket.
                    </td>
                </tr>                
                <tr>
                    <td>
                        7. Check the two gussets on the crossarm.
                    </td>
                </tr>                
                <tr>
                    <td>
                        8. Check that the drum stitch is welded to the flange on both sides.
                    </td>
                </tr>                
                </tbody>
            </table>          
        </Box>
    );

}
