import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar, GridApi } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { format, parseISO } from 'date-fns';

import Loading from '../Loading';
import Error from '../Error';
import CustomerProjectsListHeader from './CustomerProjectsListHeader';
import CustomerProjectsListHelpOverview from './CustomerProjectsListHelpOverview';

import EditIcon from '@mui/icons-material/Edit'; 

import { useSessionContext } from '../../contexts/SessionContext';


export default function CustomerProjectsList(props){
    //console.debug("RTS Break CustomerProjectList");
    
    const {user, sessionCustomer, userAuthToken, apiRoot, customerProjectList, setCustomerProjectList} = useSessionContext();

    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [helpListOverview, setHelpListOverview] = useState(false);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(null);
    
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 1,
        page: 2
        //page: customerProjectList.page===null?0:customerProjectList.page
    });


    function fetchData(){
        if(filter !== null){
            let apiURL = apiRoot + "/CustomerProjects/GetProjectsList";
            let apiBody = filter;
            axios
            .post(
                apiURL,
                apiBody, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    setIsLoading(false);
                    setData(response.data);
                }
            })
            .catch(error => {
                setHasError(true);
                console.log("RTS Data Error", error);
            });
        }
    }


    useEffect(() => {
        setFilter({"user":user, "customer":sessionCustomer.customer, "sortBy":0});
    }, [])


    useEffect(() => {
        fetchData();
    }, [filter]);  
    
    
    function dataFilter(args){
        setIsLoading(true);
        let locFilter = {"user":user,
                        "customers":[sessionCustomer.customer], 
                        "sortBy":0 
                        }
    
        setFilter(locFilter);
    }
    

    function handleClick(e, cellValues){
        let listSession = {
            page: paginationModel.page,
            //filters: apiRef.current.filter
        }

        setCustomerProjectList(listSession);
        history("/CustomerProjectsEdit", {state: {key:cellValues.row.idGuid, mode:1}});
    }


    const columns = [
        {
            field: 'Edit',
            headerName: '',
            description:"Edit Project", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Project Name", 
            description:"Project Name", 
            flex:4 
        },
        {
            field: 'managerName', 
            headerName: "Manager", 
            description:"Project Manager", 
            flex:3        
        },
        {
            field: 'loadCount', 
            headerName: "# Reels", 
            description:"Number of Reels", 
            flex:1    
        },        
        {
            field: 'startDate', 
            headerName: "Start Date", 
            description:"Project Start Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            } 
        },
    ];


    if(hasError){
        return(
            <Error />
        );
    }
    
    
    if(isLoading){
        return(
            <Loading />
        );
    }

    return (
        <>
        <Dialog open={helpListOverview} onClose={()=>{setHelpListOverview(false);}}>
            <DialogTitle>Project Help</DialogTitle>
            <DialogContent>
                <CustomerProjectsListHelpOverview />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setHelpListOverview(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <CustomerProjectsListHeader setHelpListOverviewFx={setHelpListOverview} />
        <Box sx={{mt:2}}>           
            <DataGridPro 
                autoHeight 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                rows={data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                pagination
                pageSize={50}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </Box>
        </>
    );
}
