import {useState,  useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function UsersEditCustomerAccessList(props) {
    //console.debug("RTS Break UsersEditCustomerAccessList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    // This must come after the repoStatus is set because of the find.
    const [accessLevel, setAccessLevel] = useState(props.data);
    const repoAccessLevel = [
         {id:1, label:'Crew Member'}
        ,{id:25, label:'Associate'} 
        ,{id:50, label:'Customer Representative'}
        ,{id:75, label:'Customer Admin'}
    ];


    function valChange(e, val){
        props.setAccessLevelFx(val);
        setAccessLevel(val);
    }    

    return(
        <>
            <Autocomplete
                options={repoAccessLevel}
                renderInput={(params) => <TextField {...params} label="Access Level" size="small" />}
                value={accessLevel}
                onChange={valChange}
                getOptionLabel={(repoAccessLevel) => repoAccessLevel.label}
                renderOption={(props, repoAccessLevel) => (
                    <Box component="li" {...props} key={repoAccessLevel.id}>
                        {repoAccessLevel.label}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
            />
        </>
    );
}

