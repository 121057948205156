import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import GaugeChart from 'react-gauge-chart'

export default function LoadListListItemIndexReturned(props) {
    //console.debug("RTS Break LoadListListItemIndexReturned");

    const purchase = props.data.amt;
    const remains = Math.floor(props.data.amt - props.data.pullSum);
    const remainsPercent = remains/purchase;
    const remainsPercentDisp = (remains/purchase)*100;
    const netWeight = Math.floor(remains * props.data.product.weightPerFt);
    const consumedPercent = props.data.pullSum/purchase;
    const consumedPercentDisp = (props.data.pullSum/purchase)*100;

    return (
        <Stack sx={{textAlign:'center'}}>
            <Box sx={{width:'100px'}}>
                {
                props.data.markEmptyScrap === 1
                ?
                    <>
                    <Stack sx={{textAlign:'center'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Scrap Returned</Typography>
                        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Box sx={{display:'flex'}}>
                                <Typography variant="body2">{remains.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                                <Typography variant="body2" sx={{ml:'2px'}}>ft</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Box sx={{display:'flex'}}>
                                <Typography variant="caption">{netWeight.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                                <Typography variant="caption" sx={{ml:'2px'}}>lbs</Typography>
                            </Box>
                        </Box>                
                    </Stack>
                    </>
                
                :
                    props.data.markEmptyScrap === 0
                    ?
                    <>
                        <Typography variant="body2">Reel Returned Empty</Typography>
                    </>
                    :
                    <>
                        <Typography variant="body2">Reel Returned Outside of System</Typography>
                    </>
                }
            </Box>
        </Stack>
    );
}
