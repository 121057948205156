import axios from 'axios';
import {useState, useEffect } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import ReelsEdit from '../../components/ManuReels/ManuReelsEdit';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ManuReelsPageEdit( props ){
    console.debug("RTS Break ReelsPageEdit");

    const {userAuthToken, apiRoot} = useSessionContext();

    const location = useLocation();
    const id = location.state.data;
    const mode = location.state.mode;

    const [data, setData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    function fetchData(){
        let apiURL = apiRoot + "/Reels/GetReelById/" + id;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        props.setIsModalFx(true);

        if(mode == 1){
            fetchData();
        }

    }, []);  
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }    

    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Typography variant="h5" sx={{my:2}}>Edit Reel</Typography>
            <Box>
                <ReelsEdit data={data} mode={mode} />
            </Box>
        </Container>
    );
}
