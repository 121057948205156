import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import EditIcon from '@mui/icons-material/Edit'; 

export default function ReelInspectionsReelsList(props){
    //console.debug("RTS Break ReelInspectionsReelsList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/ReelInsepctionsPageEdit", {state:{data:{reel: cellValues.row}, mode:0}});
    }

    const columns = [
        {
            field: '',
            description:"Add Inspection", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'serialNumber', 
            headerName: "Serial Number", 
            description:"Reel Serial Number", 
            flex:3,
        },
        {
            field: 'reelTypeName', 
            headerName: "Reel Type", 
            description:"Reel Type Name", 
            flex:3,
            valueGetter: (cellValues) =>{
                return cellValues.row.reelType.name
            }
        },
        {
            field: 'esn', 
            headerName: "Tracker", 
            description:"Tracker ESN", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.tracker!==null?cellValues.row.tracker.esn:null
            },
        },        
        {
            field: 'batteryLevel', 
            headerName: "Battery Level", 
            description:"Tracker Battery Level", 
            flex:2,
            valueGetter: (cellValues) => {
                return cellValues.row.tracker!==null?cellValues.row.tracker.batteryLevel!==null?cellValues.row.tracker.batteryLevel!=='-1'?cellValues.row.tracker.batteryLevel+"%":'Unknown':'Unknown':''
            },
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"Reel Status", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value === 1){
                    return ('Active');
                }
                else{
                    return('Deleted');
                }
            },            
        }        
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                filterModel={props.filter} 
            />
        </Box>
    );
}
