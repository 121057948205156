import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';

import EditCancel from '../EditCancel';
import EditStatus from '../EditStatus';
import TrackersEditActive from './TrackersEditActive';

import { useSessionContext } from '../../contexts/SessionContext';

export default function TrackersEdit(props) {
    //console.debug("RTS Break TrackersEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [esn, setEsn] = useState(mode===1?data.esn!=null?data.esn:'':'');
    const [idSeq, setIdSeq] = useState(mode===1?data.idSeq!==null?data.idSeq:'':'');
    const [purchaseDate, setPurchaseDate] = useState(mode===1?data.purchaseDate!==null?format(parseISO(data.purchaseDate), 'MM-dd-yyyy'):null:format( new Date(), 'MM-dd-yyyy'));
    const [status, setStatus] = useState(mode===1?data.status:1);
    const [isActive, setIsActive] = useState(mode===1?data.isActive:0);
    const [activateDate, setActivateDate] = useState(mode===1?data.activateDate!==null?format(parseISO(data.activateDate), 'MM-dd-yyyy'):null:null);
    const [batteryLevel, setBatteryLevel] = useState(mode===1?data.batteryLevel===null?'Unknown':data.batteryLevel:'Unknown');
    const [lastUpdate, setLastUpdate] = useState(mode===1?data.lastUpdate===null?'Unknown':data.lastUpdate:'Unknown');



    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid===''?null:idGuid,
            ESN: esn===''?null:esn,
            IdSeq: idSeq===''?null:Number(idSeq),
            PurchaseDate: purchaseDate===''?null:purchaseDate,
            Status: status===''?null:status,
            BatteryLevel: batteryLevel==='Unknown'?null:batteryLevel,
            IsActive: isActive===''?null:isActive,
            ActivateDate: activateDate===''?null:activateDate,
            LastUpdate: lastUpdate==='Unknown'?null:lastUpdate,
            ModifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Trackers/UpdateTracker/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateTracker Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Trackers/CreateTracker";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateTracker Error", error);
                setShowErrorModal(true);
            });
        }
    } 

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="trackerForm" onSubmit={handleSubmit}>
                <TextField
                    id="tbEsn"
                    margin="normal"
                    fullWidth
                    label="ESN"
                    size="small"
                    required
                    value={esn}
                    onChange={(e) => {setEsn(e.target.value);}}
                />
                <TextField
                    id="tbIdSeq"
                    margin="normal"
                    fullWidth
                    label="Sequence Id"
                    size="small"
                    value={idSeq}
                    onChange={(e) => {setIdSeq(e.target.value);}}
                />
                <DatePicker
                    id="tbPurchaseDate"
                    label="Purchase Date"
                    inputFormat="MM/dd/yyyy"
                    value={purchaseDate}
                    onChange={(val) => setPurchaseDate(val)}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                />
                <TrackersEditActive isActive={isActive} setIsActiveFx={setIsActive} />
                <DatePicker
                    id="tbActivateDate"
                    label="Activation Date"
                    inputFormat="MM/dd/yyyy"
                    value={activateDate}
                    onChange={(val) => setActivateDate(val)}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                /> 
                <EditStatus status={status} setStatusFx={setStatus} /> 
                <TextField
                    id="tbBatteryLevel"
                    margin="normal"
                    fullWidth
                    label="Battery Level"
                    size="small"
                    value={batteryLevel}
                    onChange={(e) => {setBatteryLevel(e.target.value);}}
                    disabled
                />
                <TextField
                    id="tbLastUpdated"
                    margin="normal"
                    fullWidth
                    label="Last Update"
                    size="small"
                    value={lastUpdate}
                    onChange={(e) => {setLastUpdate(e.target.value);}}
                    disabled
                />
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );
}

