import axios from 'axios';
import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Loading from '../components/Loading';
import Error from '../components/Error';
import LoadListList from '../components/LoadList/LoadListList';
import LoadListMap from '../components/LoadList/LoadListMap';
import LoadListFilter from '../components/LoadList/LoadListFilter';
import LoadListMode from '../components/LoadList/LoadListMode';
import LoadPullsEdit from '../components/LoadPulls/LoadPullsEdit';
import LoadPullAdjustmentEdit from '../components/LoadPullAdjustment/LoadPullAdjustmentEdit';
import LoadOwnershipEdit from '../components/LoadOwnership/LoadOwnershipEdit';
import LoadDetails from '../components/LoadDetails/LoadDetails';
import LoadMarkEdit from '../components/LoadMark/LoadMarkEdit';
import LoadSetLocation from '../components/LoadSetLocation/LoadSetLocation';
import LoadPinsEdit from '../components/LoadPins/LoadPinsEdit';
import LoadProjectEdit from '../components/LoadProject/LoadProjectEdit';
import LoadPullManagementEdit from '../components/LoadPullManagement/LoadPullManagementEdit';

import { useSessionContext } from '../contexts/SessionContext';


export default function LoadListPage( props ) {
    //console.debug("RTS Break LoadListPage");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(true);
    const [baseMode, setBaseMode] = useState(0);
    const [mode, setMode] = useState(0);
    const [data, setData] = useState([]);
    const [childRecord, setChildRecord] = useState({});
    const [hasError, setHasError] = useState(false);

    const [customerFilters, setCustomerFilters] = useState({});
    
    const [filter, setFilter] = useState(
        {
            "user":user, 
            "status":location.state==null?[]:location.state.filter==null?[1,25,50]:location.state.filter.status,
            "customers":[sessionCustomer.customer],
            "orders":location.state==null?[]:location.state.filter==null?[]:location.state.filter.orders, 
            "projects":location.state==null?[]:location.state.filter==null?[]:location.state.filter.projects, 
            "products":location.state==null?[]:location.state.filter==null?[]:location.state.filter.products, 
            "reelTypes":location.state==null?[]:location.state.filter==null?[]:location.state.filter.reelTypes,
            "reels":location.state==null?[]:location.state.filter==null?[]:location.state.filter.reels,
            "minLength":location.state==null?0:location.state.filter==null?0:location.state.filter.minLength,
            "namedLocations":location.state==null?[]:location.state.filter==null?[]:location.state.filter.namedLocations,
            "pinned":location.state==null?-1:location.state.filter==null?-1:location.state.filter.pinned,
            "locked":location.state==null?-1:location.state.filter==null?-1:location.state.filter.locked,
            "sortBy":location.state==null?0:location.state.filter==null?0:location.state.filter.sortBy
        }
    );


    function fetchData(){
        let apiURL = apiRoot + "/LoadList/GetLoadList";
        axios
        .post(
            apiURL,
            filter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                if(response.data.length === 0)
                {
                    setMode(-1);
                }
                setIsLoading(false);
                setHasError(false);
            }
            else{
                setMode(-1);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }


    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, [filter]);


    useEffect(() => {
        fetchFilters();
    },[]);
    
    
    function fetchFilters(){
        let apiURL = apiRoot + "/LoadList/GetLoadListFilters";
        axios
        .post(
            apiURL,
            sessionCustomer.customer,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setCustomerFilters(response.data);
            }
            setIsLoadingFilter(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setIsLoadingFilter(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });        
    }
    

    function dataFilter(args){
        setIsLoading(true);
        let locFilter = {"user": user,
                        "status": args.status, 
                        "customers": [sessionCustomer.customer],
                        "orders": args.orders, 
                        "projects": args.projects, 
                        "products": args.products,
                        "reelTypes": args.reelTypes, 
                        "reels": args.reels, 
                        "minLength": args.minLength,
                        "namedLocations": args.namedLocations,
                        "pinned": args.pinned,
                        "locked":args.locked,
                        "sortBy": args.sortBy 
                        }

        setFilter(locFilter);
        setMode(0);
    }


    function dataRefresh(){
        setIsLoading(true);
        fetchData();
        setMode(0);
    }


    function openChild(args){
        if(args['idx'] != null){
            let childIdx = args.idx;
            setChildRecord(data[childIdx]);
        }
        setMode(args.mode);
    }


    function openBaseMode(args){
        setIsLoading(true);
        setBaseMode(args);
        setMode(args);
        setIsLoading(false);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading || isLoadingFilter){
        return(
            <Loading />
        );
    }


    return(
        <Box id="pageBox">
            <Box id="filterBox">
                <LoadListFilter filter={filter} filterFx={dataFilter} data={data} customerFilters={customerFilters} />            
            </Box>
            <Box id="modeBox">
                <LoadListMode data={data} modeFx={openBaseMode} mode={mode} reelCount={data===null?0:data.length} />            
            </Box>            
            <Box id="contentBox">
                {mode===-1?<Box justifyContent={"center"} alignItems={"center"} display={"flex"}><Typography variant='h4'>No Records Found</Typography></Box>:""}
                {mode===0?<LoadListList data={data} user={user} openChildFx={openChild} dataRefreshFx={dataRefresh}/>:""}
                {mode===1?<LoadListMap data={data} user={user} openChildFx={openChild} dataRefreshFx={dataRefresh}/>:""}
                {mode===3?<LoadPullsEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===4?<LoadDetails data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===5?<LoadMarkEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===6?<LoadSetLocation data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===7?<LoadPullAdjustmentEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===8?<LoadOwnershipEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===9?<LoadPinsEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===10?<LoadProjectEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===11?<LoadPullManagementEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
            </Box> 
        </Box>
    );
}

