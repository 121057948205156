import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';

import LoadListListItem from "./LoadListListItem";

export default function LoadListList(props){
    //console.debug("RTS Break LoadListList");

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const pageCount = Math.ceil(props.data.length/pageSize);

    function getPage(){
        return props.data.slice(page*pageSize, page*pageSize+pageSize);
    }

    const handlePageChange = (event, page) =>{
        setPage(page-1);
    }

    function openChild(args){
        const curIdx = (page * pageSize) + args.idx; 
        props.openChildFx({idx: curIdx, mode: args.mode});
    }

    return (
        props.data.length > 0
        ?
        <Box>
            <Stack id='loadListStack' spacing={1}>
                {getPage().map((Load, idx) => {
                    return(
                        <>
                        <LoadListListItem 
                             key={idx} 
                             childIdx={idx} 
                             data={Load} 
                             user={props.user} 
                             openChildFx={openChild} 
                        />
                        </>
                    )
                })}
            </Stack>
            <Box justifyContent={"center"} alignItems={"center"} display={"flex"} sx={{margin: "20px 10px"}}>
                <Pagination 
                    count={pageCount}
                    onChange={handlePageChange}
                />
            </Box>
        </Box>
        :
        <Box justifyContent={"center"} alignItems={"center"} display={"flex"}>
            <Typography>No Records Found</Typography>
        </Box>
    );
}
