import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


import Loading from '../components/Loading';
import Error from '../components/Error';
import AnalyticsTotalReels from '../components/Analytics/AnalyticsTotalReels';
import AnalyticsReelDueBuckets from '../components/Analytics/AnalyticsReelDueBuckets';
import AnalyticsLoadRemainsPercentBuckets from '../components/Analytics/AnalyticsLoadRemainsPercentBuckets';

import { useSessionContext } from '../contexts/SessionContext';

export default function AnalyticsPage( props ){
    //console.debug("RTS Break AnalyticsPage");

    const {user, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();

    const [isLoading] = useState(false);
    const [hasError] = useState(false);

    useEffect(() => {
        props.setIsModalFx(false);
    }, []); 

    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <Box id="pageBox">
            <Grid id="dashboardGrid" container spacing={2}>
                <Grid item xs={12} sm={6} md={4} xl={3} xxl={2}>
                    <Box>
                        <AnalyticsTotalReels  />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3} xxl={2}>
                    <Box>
                        <AnalyticsLoadRemainsPercentBuckets  />
                    </Box>
                </Grid> 
                <Grid item xs={12} sm={6} md={4} xl={3} xxl={2}>
                    <Box>
                        <AnalyticsReelDueBuckets  />
                    </Box>
                </Grid> 
            </Grid>
            <Box sx={{pt:6}}>
                <Typography variant="h4">Reports</Typography>
                <Box sx={{borderTop:1}}>
                    <Grid container>
                        <Grid item xs={6} sm={5} lg={4}>
                            <Button
                                onClick={(event) => {history("/LoadsShippedLast30DaysPage");}}
                            >
                                <Typography>Reels Shipped Last 30 Days</Typography>
                            </Button>                            
                        </Grid>
                        <Grid item xs={6} sm={7} lg={8}>
                            <Typography>Shows a list of all reels sold and shipped to your organization.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sm={5} lg={4}>
                            <Button
                                onClick={(event) => {history("/LoadsReturnedLast30DaysPage");}}
                            >
                                <Typography>Reels Returned Last 30 Days</Typography>
                            </Button>                            
                        </Grid>
                        <Grid item xs={6} sm={7} lg={8}>
                            <Typography>Shows a list of all reels returned by your organization.</Typography>
                        </Grid>
                    </Grid>                                    
                    <Grid container>
                        <Grid item xs={6} sm={5} lg={4}>
                            <Button
                                onClick={(event) => {history("/LoadsDueDetailPage");}}
                            >
                                <Typography>Reels Due Details</Typography>
                            </Button>                            
                        </Grid>
                        <Grid item xs={6} sm={7} lg={8}>
                            <Typography>Shows all reels based on due date.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sm={5} lg={4}>
                            <Button
                                onClick={(event) => {history("/LoadsAwaitingReturnPage");}}
                            >
                                <Typography>Reels Awaiting Return</Typography>
                            </Button>                            
                        </Grid>
                        <Grid item xs={6} sm={7} lg={8}>
                            <Typography>Shows all reels on submitted Pickup Requests.</Typography>
                        </Grid>
                    </Grid> 
                    <Grid container>
                        <Grid item xs={6} sm={5} lg={4}>
                            <Button
                                onClick={(event) => {history("/LoadsBatteryLevelPage");}}
                            >
                                <Typography>Reel Tracker Battery Levels</Typography>
                            </Button>                            
                        </Grid>
                        <Grid item xs={6} sm={7} lg={8}>
                            <Typography>Shows the tracker battery level orderd by amount left.</Typography>
                        </Grid>
                    </Grid> 
                    <Grid container>
                        <Grid item xs={6} sm={5} lg={4}>
                            <Button
                                onClick={(event) => {history("/LoadsProductRemainsDetailPage");}}
                            >
                                <Typography>Reel Product Remains Details</Typography>
                            </Button>                            
                        </Grid>
                        <Grid item xs={6} sm={7} lg={8}>
                            <Typography>Shows the details about the amount of product left on a reel.</Typography>
                        </Grid>
                    </Grid>                                                                               
                </Box>
            </Box>
        </Box>

    );
}
