import axios from 'axios';
import {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import AddIcon from '@mui/icons-material/Add';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CustomersEditLinksAddList(props){
    //console.debug("RTS Break CustomersEditLinkAddList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);


    function fetchData(){
        let apiCustomersURL = apiRoot + "/Customers/GetAllCustomersTypeLink";
        axios
        .get(
            apiCustomersURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {     
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    } 
    
    
    useEffect(() => {
        fetchData();
    }, []);  


    function handleClick(e, cellValues){
        props.addCustomerLinkFx(cellValues.row);
    }    


    const columns = [
        {
            field: '',
            description:"Add Customer", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <AddIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },          
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:4
        }, 
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Customer Id", 
            flex:3
        },   
        {
            field: 'salesOffice', 
            headerName: "Office Id", 
            description:"Sales Office Id", 
            flex:3 
        },
        {
            field: 'salesGroup', 
            headerName: "Group Id", 
            description:"Sales Group Id", 
            flex:3 
        }
    ];


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }    

    return (
        <DataGridPro 
            autoHeight 
            components={{Toolbar: GridToolbar}} 
            pagination 
            pageSize={25} 
            rows={data} 
            columns={columns} 
            getRowId={row => row.idGuid} 
        />
    );
}
