import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import UsersEdit from '../../components/Users/UsersEdit';

export default function UsersPageEdit( props ){
    //console.debug("RTS Break UsersPageEdit");

    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 

    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{display:"flex"}}>
                <Typography variant="h5" sx={{my:2}}>Edit User</Typography>
            </Box>
            <Box>
                <UsersEdit />
            </Box>
        </Container>
    );
}
