import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import CrewMembersEdit from '../components/CrewMembers/CrewMembersEdit';

export default function CrewMembersPageEdit( props ){

    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 

    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Box>
                <CrewMembersEdit />
            </Box>
        </Container>        
    );
}
