import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export default function CustomerProjectsListHeader(props){
    const history = useNavigate();

    return (
        <Grid container sx={{mb:1}}>
            <Grid item xs={6}>
                <Box display="flex">
                    <Typography variant="h5">Projects</Typography>
                    <Link onClick={() => {props.setHelpListOverviewFx(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <HelpOutlineIcon variant="h5" bold sx={{ml:1, mt:.5}}  />
                    </Link>
                </Box>                 
            </Grid>
            <Grid item xs={6} sx={{textAlign:'right'}}>
                <Button type="button" variant="contained" onClick={handleClick}>
                    New Project
                </Button>
            </Grid>
        </Grid>
    )

    function handleClick(e){
        history("/CustomerProjectsEdit", {state:{data:{}, mode: 0}});
    }
}
