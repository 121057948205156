import {useEffect} from 'react';
import Box from '@mui/material/Box';
import { format, parseISO } from 'date-fns';
import mapboxgl from 'mapbox-gl';

export default function LoadDetailsMap( props ) {
    console.debug("RTS Break LoadDetailsMap");


    useEffect(() => {

        const map = new mapboxgl.Map({
            container: "mapContainer",
            accessToken: "pk.eyJ1IjoiamltcHRhayIsImEiOiJja3kzMXZyOHIwNnRvMnBwOGxrbWJ4ZDhiIn0.bWHzm3g7ZOvo1wcWf2w8fg",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [-85.0716746, 33.5666694],
            zoom: 9
        });


        var maxLng = 0, maxLat = 0, minLng = 0, minLat = 0, markerCount = 0;
        var sw, ne, llb;


        if(props.data.tracker!==null){
            if(props.data.tracker.locations!=null){

                props.data.tracker.locations.slice(0).reverse().map((ld, idx) => {
                    if(ld.lat !== "-91" && ld.lng !== "-181"){
                        const coord = {lat:ld.lat, lng:ld.lng};
                        markerCount = props.data.tracker.locations.length -1;

                        // Start the bounding box for the display.
                        if (idx === 1) {
                            minLng = maxLng = ld.lng // longitude
                            minLat = maxLat = ld.lat; // latitude
                        }
                        else {
                            maxLng = ld.lng > maxLng ? ld.lng : maxLng;
                            minLng = ld.lng < minLng ? ld.lng : minLng;
                            maxLat = ld.lat  > maxLat ? ld.lat  : maxLat;
                            minLat = ld.lat  < minLat ? ld.lat  : minLat;
                        }

                        // Start building the points.
                        const elMarker = document.createElement('div');

                        if(idx === markerCount){
                            if(props.data.returnedDate === null){
                                elMarker.className = "rtsMarkerSmallCur";
                            }
                            else{
                                elMarker.className = "rtsMarkerSmallRet";
                            }
                        }
                        else{
                            if(props.data.pulls.length === 0){
                                elMarker.className = "rtsMarkerSmallInit";
                            }
                            else{
                                let wipDate = new Date(props.data.pulls[0].pullDate);
                                let locDate = new Date(ld.locTimeStamp);

                                if(locDate < wipDate){
                                    elMarker.className = "rtsMarkerSmallInit";
                                }
                                else{
                                    if(props.data.pickupRequestDate !== null){
                                        let markDate = new Date(ld.pickupRequestDate);
                                        if(locDate < markDate){
                                            elMarker.className = "rtsMarkerSmallWip";
                                        }
                                        else{
                                            elMarker.className = "rtsMarkerSmallRet";
                                        }
                                    }
                                    else{
                                        elMarker.className = "rtsMarkerSmallWip";
                                    }
                                }
                            }
                        }
                        const elNum = document.createElement('span');
                        elNum.className = "rtsLightForeground";
                        //elNum.innerHTML = (props.data.tracker.locations.length - markerCount) +1;
                        elNum.innerHTML = idx +1;
                        elMarker.appendChild(elNum)                    

                        let marker = new mapboxgl.Marker(elMarker)
                            .setLngLat(coord)
                            .setPopup(new mapboxgl.Popup({offset: 30})
                            .setHTML('<div><label style="font-size:1.5em; font-Weight:bold;">' + props.data.reel.serialNumber + '</label></div>'
                                + '<div><label style="font-Weight:bold; padding-top:5px;">Location Information</span></div>'
                                + '<div><label style="padding-left:5px;">Date:</label><span>' + format(parseISO(ld.locTimeStamp), "MM-dd-yyyy") + '</span></div>'
                                + '<div><label style="padding-left:5px;">Latitude:</label><span>' + ld.lat + '</span></div>'
                                + '<div><label style="padding-left:5px;">Longitude:</label><span>' + ld.lng + '</span></div>'
                                ));

                        marker.addTo(map);
                        return marker;
                    }
                });

                sw = new mapboxgl.LngLat(minLng, minLat);
                ne = new mapboxgl.LngLat(maxLng, maxLat);
                llb = new mapboxgl.LngLatBounds(sw, ne);

                if(markerCount > 0){
                    if(markerCount === 1){
                        map.setCenter(sw);
                    }
                    else{
                        map.fitBounds(llb,
                            {
                                padding: { top: 50, bottom: 50, left: 50, right: 50 } ,
                                animate: false
                            }
                        );   
                    }
                }  
            }
        }
        else{
            if(props.data.setLocations!==null){
    
                props.data.setLocations.map((ld, idx) => {
                    const coord = {lat:ld.lat, lng:ld.lng};
                    markerCount++;

                    if (markerCount === 1) {
                        minLng = maxLng = ld.lng // longitude
                        minLat = maxLat = ld.lat; // latitude
                    }
                    else {
                        maxLng = ld.lng > maxLng ? ld.lng : maxLng;
                        minLng = ld.lng < minLng ? ld.lng : minLng;
                        maxLat = ld.lat  > maxLat ? ld.lat  : maxLat;
                        minLat = ld.lat  < minLat ? ld.lat  : minLat;
                    }

                    const elMarker = document.createElement('div');

                    if(markerCount === 1){
                        elMarker.className = "rtsMarkerSmallCur";
                    }
                    else{
                        if(props.data.pulls.length === 0){
                            elMarker.className = "rtsMarkerSmallInit";
                        }
                        else{
                            let wipDate = new Date(props.data.pulls[0].pullDate);
                            let locDate = new Date(ld.createDate);

                            if(locDate < wipDate){
                                elMarker.className = "rtsMarkerSmallInit";
                            }
                            else{
                                if(props.data.returnMarkDate != null){
                                    let markDate = new Date(ld.returnMarkDate);
                                    if(locDate < markDate){
                                        elMarker.className = "rtsMarkerSmallWip";
                                    }
                                    else{
                                        elMarker.className = "rtsMarkerSmallRet";
                                    }
                                }
                                else{
                                    elMarker.className = "rtsMarkerSmallWip";
                                }
                            }
                        }
                    }
                    const elNum = document.createElement('span');
                    elNum.className = "rtsLightForeground";
                    elNum.innerHTML = (props.data.setLocations.length - markerCount) +1;
                    elMarker.appendChild(elNum)                    

                    let marker = new mapboxgl.Marker(elMarker)
                        .setLngLat(coord)
                        .setPopup(new mapboxgl.Popup({offset: 30})
                        .setHTML('<div><label style="font-size:1.5em; font-Weight:bold;">' + props.data.reel.serialNumber + '</label></div>'
                            + '<div><label style="font-Weight:bold; padding-top:5px;">Location Information</span></div>'
                            + '<div><label style="padding-left:5px;">Date:</label><span>' + format(parseISO(ld.createDate), "MM-dd-yyyy") + '</span></div>'
                            + '<div><label style="padding-left:5px;">Latitude:</label><span>' + ld.lat + '</span></div>'
                            + '<div><label style="padding-left:5px;">Longitude:</label><span>' + ld.lng + '</span></div>'
                            ));

                    marker.addTo(map);
                    return marker;
                });

                sw = new mapboxgl.LngLat(minLng, minLat);
                ne = new mapboxgl.LngLat(maxLng, maxLat);
                llb = new mapboxgl.LngLatBounds(sw, ne);

                if(markerCount > 0){
                    if(markerCount === 1){
                        map.setCenter(sw);
                    }
                    else{
                        map.fitBounds(llb,
                            {
                                padding: { top: 50, bottom: 50, left: 50, right: 50 } ,
                                animate: false
                            }
                        );   
                    }
                }  
            }
        }
    }, []); 
    

    return(
        <Box 
            id="mapContainer"
            className="map" 
            style={{minHeight:"300px"}}  
        />
    );
}
