import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import mapboxgl from 'mapbox-gl';

export default function LoadSetLocationMap( props ) {
    //console.debug('LoadSetLocationMap');

    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [zoom] = useState(9);

    useEffect(() => {

        let centerLat;
        let centerLng;
        if(lat !== null && lng !== null){
            centerLat = lat;
            centerLng = lng;
        }
        else{
            centerLat = props.prevLat;
            centerLng = props.prevLng;
        }

        const map = new mapboxgl.Map({
            container: "mapContainer",
            accessToken: "pk.eyJ1IjoiamltcHRhayIsImEiOiJja3kzMXZyOHIwNnRvMnBwOGxrbWJ4ZDhiIn0.bWHzm3g7ZOvo1wcWf2w8fg",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [centerLng, centerLat],
            zoom: zoom
        });

        const prevCoord = {lat:props.prevLat, lng:props.prevLng};

        const elPrevMarker = document.createElement('div');
        elPrevMarker.className = "rtsMarkerSetPrevious";
        const elPrevText = document.createElement('span');
        elPrevText.className = "rtsLightForeground";
        elPrevText.innerHTML = "C";
        elPrevMarker.appendChild(elPrevText)

        new mapboxgl.Marker(elPrevMarker)
            .setLngLat(prevCoord)
            .addTo(map);


        if(lat !== null && lng !== null){
            const newCoord = {lat:lat, lng:lng};

            const elNewMarker = document.createElement('div');
            elNewMarker.className = "rtsMarkerSetNew";
            const elNewText = document.createElement('span');
            elNewText.className = "rtsLightForeground";
            elNewText.innerHTML = "N";
            elNewMarker.appendChild(elNewText)

            new mapboxgl.Marker(elNewMarker)
                .setLngLat(newCoord)
                .addTo(map);
        }

        map.on('click', moveMarker)

    }, [lat, lng]);  
    

    function moveMarker(e){
        setLat(e.lngLat.lat);
        setLng(e.lngLat.lng);
        props.setCoordsFx({lat: e.lngLat.lat, lng: e.lngLat.lng});
    }

    return(
        <Box 
            id="mapContainer"
            className="map" 
            fluid
            style={{minHeight:"400px"}}  
        />
    );
}
