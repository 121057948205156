import {useState, useContext} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { useSessionContext } from '../../contexts/SessionContext';

export default function UserRequestsEditEmail(props) {
    //console.debug("RTS Break UserRequestsEditEmail");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");    

    const [email, setEmail] = useState();

    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            email: email,
            firstName: '',
            lastName: '',
            customer: sessionCustomer,
            status: 1,
            createUser: user.userName,
            modifyUser: user.userName
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
            let args={};
            setErrorTitle("InvalidEmail");
            setErrorBody("A email entered is invalid.");
            setShowErrorModal(true);
            return;
        }        

        if(email == null){
            setErrorTitle("Email Check Required");
            setErrorBody("A User Email is required to add New User Request");
            setShowErrorModal(true);
            return;
        }

        
        let apiURL = apiRoot + "/UserRequests/GetUserRequestValidation";
        axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if(response.data){
                    switch(response.data){
                        case 1:
                            setErrorTitle("Duplicate Email");
                            setErrorBody("This Email is already a registered user.");
                            setShowErrorModal(true);
                            props.setValidatedFx(false);
                            break;

                        case 2:
                            setErrorTitle("Duplicate Email");
                            setErrorBody("A request with this Email is already pending.");
                            setShowErrorModal(true);
                            props.setValidatedFx(false);
                            break;

                        default:
                            props.setEmailFx(email);
                            props.setValidatedFx(true);
                            setShowErrorModal(false);
                            break;
                    }
                }
                else{
                    setErrorTitle("Unable to Validate");
                    setErrorBody("An issue has occured and the email cannot be validated at this time.");
                    setShowErrorModal(true);
                    return;                
                }
            })
            .catch(error => {
                setErrorTitle("Error Checking User Email");
                setErrorBody("An error has occured. Please review the console log for details.");
                setShowErrorModal(true);
                console.log("RTS CreateUser Error", error);
                return;                
            });
    } 


    function onUserNameChange(val){
        setEmail(val);
        props.setValidatedFx(false);
    }

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <form id="userEmailForm" onSubmit={handleSubmit}>
            <Typography sx={{fontWeight:'bold'}}>
                Enter an email address for the New User you wish to add and click the validate button:
            </Typography>
            <TextField
                id="tbEmail"
                margin="normal"
                fullWidth
                label="User Email Address"
                size="small"
                value={email}
                onChange={(e) => {onUserNameChange(e.target.value);}}
            />
            <Box sx={{textAlign:'center'}}>
                <Button variant="contained" type="submit">Validate</Button>
            </Box>
        </form>
        </>
    );
}
