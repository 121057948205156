import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography>
                Reel Pickup Requests allow the user to electonically request reels to be picked up by Southwire.
            </Typography>
            <Typography sx={{mt:1}}>
                The list of Pickup Request presented below can be filtered and exported using the toolbar located
                at the top of the list.
            </Typography>            
            <Typography sx={{mt:1}}>
                In addition to the toolbar each column in the list can be manipulated using the menu that appears when you 
                hover over the column header text.
            </Typography>            
            <Typography sx={{mt:1}}>
                A user can either work on an existing Pickup Request by clicking on the pencil icon in the far left column of the 
                list or create a new Pickup Request by clicking on the New Request button in the upper right hand corner.
            </Typography>            
        </Box>
    );

}
