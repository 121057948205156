import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import PalletsList from '../../components/Pallets/PalletsList';
import PalletsHeader from '../../components/Pallets/PalletsHeader';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PalletsPage( props ){
    //console.debug("RTS Break PalletsPage");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);


    function fetchData(){
        let apiURL = apiRoot + "/Pallets/GetAllPallets";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    },[]);  
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    
    return(
        <Box id="pageBox">
            <PalletsHeader />
            <Box sx={{mt:2}}>           
                <PalletsList data={data}/>
            </Box>
        </Box>
    );
}
