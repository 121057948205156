import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { format, parseISO } from 'date-fns';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LocatorListItemReel(props){
    //console.debug("RTS Break LocatorListItemReel");

    return (
    <>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Serial Number:</Typography>
            {props.data.reel!=null
            ?
            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reel.serialNumber}</Typography>
            :
            <Typography variant="body2" sx={{ml:'2px'}}>None</Typography>
            }
        </Box>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Type:</Typography>
            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.name}</Typography>
        </Box>                        
        {props.data.tracker!=null
        ?
        <>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Tracker:</Typography>
            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.tracker.esn}</Typography>
        </Box>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Battery Level:</Typography>
            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.tracker.batteryLevel}</Typography>
            <Typography variant="body2">%</Typography>
        </Box>
        </>
        :
        <>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Tracker:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:"red"}}>Not Tracked</Typography>
        </Box>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Battery Level:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:"red"}}>Not Tracked</Typography>
        </Box>
        </>
        }
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Deferral:</Typography>
            <Box>
                {props.data.deferralPeriod > 0
                ?
                <>
                <Box sx={{display:'flex'}}>
                    <Typography variant="body2" sx={{ml:'2px'}}>{props.data.deferralPeriod}</Typography>
                    <Typography variant="body2" sx={{ml:'2px'}}>Months</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    {
                    props.data.deferralDaysLeft>90
                    ?
                    <>
                    <Typography variant="body2" sx={{ml:'2px', color:'green'}}>{props.data.deferralDaysLeft}</Typography>
                    <Typography variant="body2" sx={{ml:'2px', color:'green'}}>Days Remaining</Typography>
                    </>
                    :
                    props.data.deferralDaysLeft > 60
                    ?
                    <>
                    <Typography variant="body2" sx={{ml:'2px', color:'gold', fontWeight:'bold'}}>{props.data.deferralDaysLeft}</Typography>
                    <Typography variant="body2" sx={{ml:'2px', color:'gold', fontWeight:'bold'}}>Days Remaining</Typography>
                    </>
                    :
                    props.data.deferralDaysLeft > 30
                    ?
                    <>
                    <Typography variant="body2" sx={{ml:'2px', color:'orange', fontWeight:'bold'}}>{props.data.deferralDaysLeft}</Typography>
                    <Typography variant="body2" sx={{ml:'2px', color:'orange', fontWeight:'bold'}}>Days Remaining</Typography>
                    </>
                    :
                    props.data.deferralDaysLeft >= 0
                    ?
                    <>
                    <Typography variant="body2" sx={{ml:'2px', color:'red', fontWeight:'bold'}}>{props.data.deferralDaysLeft}</Typography>
                    <Typography variant="body2" sx={{ml:'2px', color:'red', fontWeight:'bold'}}>Days Remaining</Typography>
                    </>
                    :
                    <>
                    <Typography variant="body2" sx={{ml:'2px', color:'red', fontWeight:'bold'}}>{props.data.deferralDaysLeft*-1}</Typography>
                    <Typography variant="body2" sx={{ml:'2px', color:'red', fontWeight:'bold'}}>Days Overdue</Typography>
                    </>
                    }
                </Box>
                </>
                :
                <>
                <Box sx={{display:'flex'}}>
                    <Typography variant="body2" sx={{ml:'2px'}}>No Deferral</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography variant="body2" sx={{ml:'2px'}}>{props.data.deferralDaysLeft*-1}</Typography>
                    <Typography variant="body2" sx={{ml:'2px', color:'green'}}>Days On Site</Typography>
                </Box>
                </>
                }
            </Box>
        </Box>
    </>
    )
}

