import axios from 'axios';
import {useState, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import FlagIcon from '@mui/icons-material/Flag';
import ClearIcon from '@mui/icons-material/Clear';

import Loading from '../Loading';
import Error from '../Error';

import GridToolbarRefreshButton from '../GridToolbarRefreshButton';
import { useSessionContext } from '../../contexts/SessionContext';

export default function DashbaordAlert( props ) {
    //console.debug("RTS Break DashbaordAlert");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState([]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertBody, setAlertBody] = useState('');

    function fetchData(){
        let apiObj ={
            customers: [sessionCustomer.customer],
            user: user,
            sortBy: 0
        }

        let apiURL = apiRoot + "/Dashboard/GetAlerts";
        
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            setData(response.data);
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }


    useEffect(() => {
        fetchData();
    }, []);


    function handleClick(e, cellValues){
        setAlertTitle(cellValues.row.title);
        setAlertBody(cellValues.row.body);
        setShowAlertModal(true);

        let apiObj = cellValues.row;
        apiObj.status = 1;
        let apiURL = apiRoot + "/Dashboard/UpdateAlert";
        
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }


    function handleDelClick(e, cellValues){
        let apiObj = cellValues.row;

        let apiURL = apiRoot + "/Dashboard/DeleteAlert";
        
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            let tempData = data;
            fetchData();
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    function CustomToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <GridToolbarRefreshButton refreshEvent={fetchData} />
            </GridToolbarContainer>
        );
    }


    const columns = [
        {            
            field: 'sentDate', 
            headerName: "Date", 
            description:"Alert Date", 
            flex:1,           
            renderCell: (cellValues) => {
                return (
                    <Button onClick={(event) => {handleClick(event, cellValues);}}>
                    {
                        cellValues.row.status === 0
                        ?
                            <Typography sx={{fontWeight:'bold'}}>{format(parseISO(cellValues.row.sentDate), "MM-dd-yyyy")}</Typography>
                        :
                            <Typography sx={{fontWeight:'normal'}}>{format(parseISO(cellValues.row.sentDate), "MM-dd-yyyy")}</Typography>
                    }
                    </Button>
                );
            } 
        },
        {
            field: 'Severity', 
            headerName: "", 
            description:"Severity", 
            flex:.25,
            align:"center",
            renderCell: (cellValues) => {
                switch(cellValues.row.icon){
                    case(1):
                        return( <Button onClick={(event) => {handleClick(event, cellValues);}}>
                                    <FlagIcon sx={{color:"gold"}} />
                                </Button>
                            );
                        break;

                    case(2):
                        return( <Button onClick={(event) => {handleClick(event, cellValues);}}>
                                    <FlagIcon sx={{color:"orange"}} />
                                </Button>
                            );
                        break;

                    case(3):
                        return( <Button onClick={(event) => {handleClick(event, cellValues);}}>
                                    <FlagIcon sx={{color:"red"}} />
                                </Button>
                            );
                        break;

                    default:
                        return(<Typography></Typography>);
                        break;
                }
            },
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
        {field: 'title', 
            headerName: "Title", 
            description:"Alert Title", 
            flex:4,
            renderCell: (cellValues) => {
                return (
                <Button
                    onClick={(event) => {
                    handleClick(event, cellValues);
                    }}
                >
                    {
                        cellValues.row.status === 0
                        ?
                            <Typography sx={{fontWeight:'bold'}}>{cellValues.row.title}</Typography>
                        :
                            <Typography sx={{fontWeight:'normal'}}>{cellValues.row.title}</Typography>
                    }
                </Button>
                );
            }
        },
        {
            field: 'Delete',
            headerName: "", 
            description:"Delete", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleDelClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <ClearIcon sx={{color:"red"}} />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
    ];

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showAlertModal} onClose={()=>{setShowAlertModal(false);}}>
            <DialogTitle>{alertTitle}</DialogTitle>
            <Box dangerouslySetInnerHTML={{__html: alertBody}}>
            </Box>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowAlertModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <DataGridPro 
            autoHeight 
            pagination 
            components={{Toolbar: CustomToolbar}} 
            pageSize={50} 
            rows={data} 
            columns={columns} 
            getRowId={row => row.idGuid} 
        />
        </>
    );

}

