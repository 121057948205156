import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function LoadOwnershipEditHelp(props) {
    //console.debug('RTS Break LoadOwnershipEditHelp');

    return (
        <Box>
            <Typography>
                Reel Ownership determines who can view and edit a load.  There are three levels of Reel Ownership.
				
				1. Sold To - Cannot be changed and is determined when the Reel is shipped.
				2. Assigned To
				3. Contracted To
				
				If an entity is set to any of these values the Reel will be displayed within the Reel list for that entity.
            </Typography>
        </Box>
    );

}
