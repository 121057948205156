import axios from 'axios';
import {useState, useEffect } from 'react';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import LoadsEdit from '../../components/Loads/LoadsEdit';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsPageEdit( props ){
    console.debug("RTS Break LoadsPageEdit");

    const {userAuthToken, apiRoot} = useSessionContext();

    const location = useLocation();
    const id = location.state.data;
    const mode = location.state.mode;

    const [data, setData] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    function fetchData(){
        let apiURL = apiRoot + "/Loads/GetLoadById/" + id;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }    
    
    useEffect(() => {
        props.setIsModalFx(true);
        if(mode==1){
            fetchData();
        }
        else{
            setIsLoading(false);
        }
    }, []); 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{display:'flex', my:2, alignItems:'center'}}>
                <Typography variant="h5">Edit Load</Typography>
                <Typography variant="caption" sx={{ml:1}}>[ {data.idGuid} ]</Typography>
            </Box>
            <Box>
                <LoadsEdit data={data} mode={mode} />
            </Box>
        </Container>
    );
}
