import {Box} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';



export default function LocatorExport(props){
    //console.debug("RTS Break LocatorExport");

    const columns = [
        {
            field: 'assignedCustomerName', 
            headerName: "Assigned Customer", 
            description:"Assgined Customer Name", 
            valueGetter: (cellValues) => {
                return cellValues.row.assignedToCustomer.name
            }             
        },
        {
            field: 'soldToCustomerName', 
            headerName: "Sold To Customer", 
            description:"Sold To Customer Name", 
            valueGetter: (cellValues) => {
                return cellValues.row.soldToCustomer.name
            }
        },
        {
            field: 'customerMaterialName',
            headerName: 'Customer Material Name',
            description: 'Customer Specific Material Name',
            valueGetter: (cellValues) => {
                if(cellValues.row.product.productAlias.length == 0)
                {
                    return '';
                }

                return cellValues.row.product.productAlias[0].alias
            }
        },
        {
            field: 'customerMaterialNo',
            headerName: 'Customer Material No.',
            description: 'Customer Specific Material Number'
        },
        {
            field: 'productName', 
            headerName: "Southwire Product Name", 
            description: "Product Name for Southwire", 
            valueGetter: (cellValues) => {
                return cellValues.row.product.name
            }
        },
        {
            field: 'productNo', 
            headerName: "Southwire Product No.", 
            description:"Product Number for Southwire", 
            valueGetter: (cellValues) => {
                return cellValues.row.product.idSap
            }
        },
        {
            field: 'projectName', 
            headerName: "Project", 
            description:"Project Name", 
            valueGetter: (cellValues) => {
                return cellValues.row.customerProject!==null?cellValues.row.customerProject.name:'';
            }
        }, 
        {
            field: 'amt', 
            headerName: "Length Purchased", 
            description:"The Length of Product Purchased in Feet", 
            valueGetter: (cellValues) => {
                return cellValues.row.amt.toLocaleString(undefined, { maximumFractionDigits: 2 });
            }
        },           
        {
            field: 'uom', 
            headerName: "Length Unit of Measure", 
            description:"The Unit of Measure for Length Purchased", 
            valueGetter: (cellValues) => {
                return 'ft';
            }
        },
        {
            field: 'lengthRemaining', 
            headerName: "Remaining Length", 
            description:"The Remaining Length on the Reel", 
            valueGetter: (cellValues) => {
                return Math.floor(cellValues.row.amt - cellValues.row.pullSum).toLocaleString(undefined, { maximumFractionDigits: 2 });
            }
        },
        {
            field: 'age', 
            headerName: "Age of Product (Days)", 
            description:"The Age of Product on the Reel in Days", 
            valueGetter: (cellValues) => {
                return cellValues.row.shipDateDays.toLocaleString();
            }
        },
        {
            field: 'customerPo', 
            headerName: "Purchase Order", 
            description:"The Customer Purchase Order", 
            valueGetter: (cellValues) => {
                return cellValues.row.customerPo;
            }
        }, 
        {
            field: 'shippingOrder', 
            headerName: "Shipping Order", 
            description:"The Shipping Order", 
            valueGetter: (cellValues) => {
                return cellValues.row.idSapOrder;
            }
        }, 
        {
            field: 'packingListNo', 
            headerName: "Packing List No", 
            description:"Packing List No", 
            valueGetter: (cellValues) => {
                return cellValues.row.idSapPackingList;
            }
        }, 
        {
            field: 'billOfLading', 
            headerName: "Bill of Lading", 
            description:"Bill of Lading", 
            valueGetter: (cellValues) => {
                return cellValues.row.idSapBol;
            }
        }, 
        {
            field: 'batchNo', 
            headerName: "Batch Number", 
            description:"Batch Number", 
            valueGetter: (cellValues) => {
                return cellValues.row.idSapBatchNo;
            }
        }, 
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"The Order Ship Date", 
            valueGetter: (cellValues) => {
                return cellValues.row.shipDate;
            }
        },
        {
            field: 'reelSerial', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            valueGetter: (cellValues) => {
                return cellValues.row.reel.serialNumber
            }
        },
        {
            field: 'reelType', 
            headerName: "Reel Type", 
            description:"The Class of Reel for the Load", 
            valueGetter: (cellValues) => {
                return cellValues.row.reel.reelType.name;
            }
        },
        {
            field: 'tracker', 
            headerName: "Tracker ESN", 
            description:"The Electronic Serial Number of Reel Tracker", 
            valueGetter: (cellValues) => {
                return cellValues.row.tracker==null?null:cellValues.row.tracker.esn;
            }
        },          
        {
            field: 'deferralPeriod', 
            headerName: "Deferral Months", 
            description:"The Number of Reel Return Deferral Months", 
            valueGetter: (cellValues) => {
                return cellValues.row.deferralPeriod;
            }
        }, 
        {
            field: 'tareWeight', 
            headerName: "Tare Weight", 
            description:"Tare Weight", 
            valueGetter: (cellValues) => {
                return cellValues.row.tareWeight.toLocaleString();
            }
        }, 
        {
            field: 'reelOwner', 
            headerName: "Reel Owner", 
            description:"Reel Owner", 
            valueGetter: (cellValues) => {
                return cellValues.row.customerReel==null?"Southwire":props.data.customer.name;
            }
        }, 
        {
            field: 'returnedDate', 
            headerName: "Returned Date", 
            description:"Reel Returned Date", 
            valueGetter: (cellValues) => {
                return cellValues.row.returnedDate;
            }
        }      
    ];

    return (
        <Box style={{height: '600px'}}>
            <DataGridPro rows={props.data} columns={columns} components={{ Toolbar: GridToolbar }} getRowId={row => row.idGuid} />
        </Box>
    );
}
