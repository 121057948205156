import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';

export default function TrackersHeader(props){
    //console.debug("RTS Break TrackersHeader");

    const history = useNavigate();

    function handleClick(e){
        history("/TrackersEdit", {state:{data:{}, mode: 0}});
    }

    return (
        <Grid container sx={{mb:1}}>
            <Grid item xs={6}>
                <Typography variant="h5">Trackers</Typography>
            </Grid>
            <Grid item xs={6} sx={{textAlign:'right'}}>
                <Button type="button" variant="contained" onClick={handleClick}>
                    New Tracker
                </Button>
            </Grid>
        </Grid>
    )
}
