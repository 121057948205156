import Button from '@mui/material/Button';

export default function LoadSetLocationCancel(props) {
    //console.debug('LoadSetLocationCancel');

    return (
        <Button variant="contained" onClick={handleCancel}>Close</Button>
    );

    function handleCancel(e){
        e.preventDefault();
        props.modeFx(0);
    }

}
