import {useState} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

export default function PickupRequestsAdminEditMarkedLoadsList(props){
    //console.debug("RTS Break PickupRequestsAdminEditMarkedLoadsList");
    
    function handleClick(e, cellValues){
        let selectedRowData = props.data.filter((row) => selectionModel.includes(row.idGuid.toString()));
        props.addLoadsFx(selectedRowData);
    }

    const [selectionModel, setSelectionModel] = useState([]);

    const columns = [
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:2,
            renderCell: (cellValues) => {
                return cellValues.row.reel.serialNumber;
            },
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Southwire Product Id", 
            flex:3,
            renderCell: (cellValues) => {
                return cellValues.row.product.name;
            },
 
        },
        {
            field: 'shipDate', 
            headerName: "Purchase Date", 
            description:"Reel Ship Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        }     
    ];

    return (
        <Box>
            <Box sx={{height:"400px"}}>
                <DataGridPro 
                    checkboxSelection={true} 
                    pagination 
                    pageSize={50}             
                    rows={props.data} 
                    columns={columns} 
                    getRowId={row => row.idGuid}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}             
                />
            </Box>
            <Box sx={{mt:2, textAlign:'right'}}>
                <Button variant="contained" onClick={handleClick} sx={{mr:2}}>Add Reels</Button>
                <Button variant="contained" onClick={e => {props.setShowLoadsModalFx(false);}}>Cancel</Button>
            </Box>
        </Box>
    );
}
