import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import LoadsShippedLast30DaysList from '../../components/Analytics/LoadsShippedLast30DaysList';
import LoadsShippedLast30DaysHeader from '../../components/Analytics/LoadsShippedLast30DaysHeader';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsShippedLast30DaysPage( props ){
    //console.debug("RTS Break LoadsShippedLast30DaysPage");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    function fetchData(){
        let apiURL = apiRoot + "/Analytics/GetLoadsShippedLast30Days";
        axios
        .post(
            apiURL,
            sessionCustomer.customer,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }

  
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, []);  
    
    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box id="pageBox">
            <LoadsShippedLast30DaysHeader />
            <Box sx={{mt:2}}>           
                <LoadsShippedLast30DaysList data={data} />
            </Box>
        </Box>
    );
}
