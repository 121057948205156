import axios from 'axios';
import {useState, useContext} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Grid from '@mui/material/Grid';
import { format, parseISO } from 'date-fns';
import Link from '@mui/material/Link';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LoadProjectCancel from './LoadProjectCancel';
import LoadListListItem from "../LoadList/LoadListListItem";
import LoadProjectEditHelp from './LoadProjectEditHelp';
import LoadProjectEditProjectList from './LoadProjectEditProjectList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadProjectEdit(props) {
    //console.debug('RTS Break LoadProjectEdit');

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showProjectEditHelp, setShowProjectEditHelp] = useState(false);

     const [project, setProject] = useState(props.data.customerProject);

    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = props.data;
        apiObj.CustomerProject = project;

        let apiURL = apiRoot + "/Loads/UpdateLoad/" + apiObj.idGuid;
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.dataRefreshFx();            
            props.modeFx(0);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateLoad Error", error);
            setShowErrorModal(true);
        });
    } 


    function showManagerName(){
        if(props.data.customerProject==null){
            return "";
        }
        else{
            if(props.data.customerProject.manager==null){
                return "";
            }
            else{
                return props.data.customerProject.manager.firstName + " " + props.data.customerProject.manager.lastName;
            }
        }
    }


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 
            <Dialog open={showProjectEditHelp} onClose={()=>{setShowProjectEditHelp(false);}}>
                <DialogTitle>Reel Project Help</DialogTitle>
                <DialogContent>
                    <LoadProjectEditHelp />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowProjectEditHelp(false);}}>Close</Button>
                </DialogActions>
            </Dialog>                     
            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>

            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit} style={{width:'400px'}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography variant='h4'>Selected Project</Typography>
                        <Link onClick={() => {setShowProjectEditHelp(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <HelpOutlineIcon sx={{fontSize:'1em', ml:1}}  />
                        </Link>
                    </Box>
                    <Box sx={{border:'1px solid #e2e8f0', borderRadius:'5px', p:1}}>        
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Project Name:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{project==null?"No Project":project.name}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Description:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{project==null?"":project.desc}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Manager:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{showManagerName()}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>External Id:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{project==null?"":project.extId}</Typography>
                        </Box>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{display:'flex'}}>
                                    <Typography variant="body2" sx={{fontWeight:'bold'}}>Start Date:</Typography>
                                    <Typography variant="body2" sx={{ml:'2px'}}>{project==null?"":format(parseISO(project.startDate), "MM-dd-yyyy")}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{display:'flex'}}>
                                    <Typography variant="body2" sx={{fontWeight:'bold'}}>End Date:</Typography>
                                    <Typography variant="body2" sx={{ml:'2px'}}>{project==null?"":format(parseISO(project.endDate), "MM-dd-yyyy")}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <LoadProjectEditProjectList project={project} setProjectFx={setProject} />
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit" variant="contained" sx={{mr:2}}>Set Project</Button>
                            <LoadProjectCancel modeFx={props.modeFx} />
                        </Box>
                    </Box>
                </form>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
