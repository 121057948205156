import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

export default function CustomerProjectsUnassignedLoadsList(props){
    //console.debug("RTS Break CustomerProjectsUnassignedLoadsList");
    
    function handleClick(e, cellValues){
        let selectedRowData = props.data.filter((row) => selectionModel.includes(row.idGuid.toString()));
        props.addLoadsFx(selectedRowData);
    }

    function CustomToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }

    const [selectionModel, setSelectionModel] = useState([]);


    const columns = [
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:2,
            renderCell: (cellValues) => {
                return cellValues.row.reel.serialNumber;
            },
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Southwire Product Id", 
            flex:5,
            renderCell: (cellValues) => {
                return cellValues.row.product.name;
            },
 
        },
        {
            field: 'shipDate', 
            headerName: "Purchase Date", 
            description:"Reel Ship Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            },
            align:'center'
        },
        {
            field: 'age', 
            headerName: "Age (Days)", 
            description:"Reel Age", 
            flex:2,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    parms.value.toLocaleString(undefined, { maximumFractionDigits: 2 })   
                )
            },
            align:'right'
        },             
        {
            field: 'remainingLength', 
            headerName: "Remaining (ft)", 
            description:"Remaining Length", 
            flex:2,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    parms.value.toLocaleString(undefined, { maximumFractionDigits: 2 })   
                )
            },
            align:'right'
        }, 
        {
            field: 'deferralDaysLeft', 
            headerName: "Deferral (Days)", 
            description:"Defferal Days Left", 
            flex:2,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    parms.value.toLocaleString(undefined, { maximumFractionDigits: 2 })   
                )
            },
            align:'right'
        },                     
    ];

    return (
        <Box>
            <Box sx={{height:"400px"}}>
                <DataGridPro 
                    checkboxSelection={true} 
                    pagination 
                    pageSize={50}             
                    rows={props.data} 
                    columns={columns} 
                    getRowId={row => row.idGuid}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel} 
                    components={{Toolbar: CustomToolbar}}            
                />
            </Box>
            <Box sx={{mt:2, textAlign:'right'}}>
                <Button variant="contained" onClick={handleClick} sx={{mr:2}}>Add Reels</Button>
                <Button variant="contained" onClick={e => {props.setShowLoadsModalFx(false);}}>Cancel</Button>
            </Box>
        </Box>
    );
}
