import axios from 'axios';
import {useState, useContext} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import Link from '@mui/material/Link';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LoadListListItem from "../LoadList/LoadListListItem";
import LoadPinsEditHelp from './LoadPinsEditHelp';
import LoadPinsEditCustomerUserList from './LoadPinsEditCustomerUserList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPinsEdit(props) {
    //console.debug('RTS Break LoadPinsEdit');

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showPinsHelp, setShowPinsHelp] = useState(false);

    const [customerUsers, setCustomerUsers] = useState([]);

    function addLoadPins(pUsers) {

        let newLoadPins = [];

        pUsers.forEach((usr) =>{
            let newPin = {
                AppUser: usr,
                Customer: sessionCustomer.customer,
                Load: props.data,
                Action: 1
            }

            let existCheck = props.data.loadPins.filter((row) => {return row.appUser.userName === usr.userName});
            if(existCheck.length === 0){
                newLoadPins.push(newPin);
            }
        })

        let apiObj = newLoadPins;

        let apiURL = apiRoot + "/LoadList/AddLoadPins";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.dataRefreshFx();            
            props.modeFx(0);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateLoad Error", error);
            setShowErrorModal(true);
        });


    } 


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 

            <Dialog open={showPinsHelp} onClose={()=>{setShowPinsHelp(false);}}>
                <DialogTitle>Reel Pin Help</DialogTitle>
                <DialogContent>
                    <LoadPinsEditHelp />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowPinsHelp(false);}}>Close</Button>
                </DialogActions>
            </Dialog>                     

            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box style={{width:'400px'}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography variant='h4'>Add User Pins</Typography>
                        <Link onClick={() => {setShowPinsHelp(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <HelpOutlineIcon sx={{fontSize:'1em', ml:1}}  />
                        </Link>
                    </Box>
                    <LoadPinsEditCustomerUserList data={props.data} addLoadPinsFx={addLoadPins} modeFx={props.modeFx} />                               
                </Box>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
