import Box from '@mui/material/Box'; 

import LoadListListItem from '../LoadList/LoadListListItem';
import LoadSetLocationSet from './LoadSetLocationSet';
import LoadSetLocationCancel from './LoadSetLocationCancel';

export default function LoadSetLocation(props){
    //console.debug('LoadSetLocation');

    return (
        <Box>
            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true} />
            </Box>
            <Box>
                <LoadSetLocationSet data={props.data} user={props.user} modeFx={props.modeFx} dataRefreshFx={props.dataRefreshFx} />
            </Box>
            <Box sx={{mt:2, textAlign:'center'}}>
                <LoadSetLocationCancel modeFx={props.modeFx} baseMode={props.baseMode} />
            </Box>
        </Box>
    )
}
