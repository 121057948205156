import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import EditIcon from '@mui/icons-material/Edit'; 

export default function UsersList(props){
    //console.debug("RTS Break UsersList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/UsersEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit User", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"User First Name", 
            flex:2
        },        
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"User Last Name", 
            flex:2 
        },
        {
            field: 'email', 
            headerName: "Email", 
            description:"User Email", 
            flex:3 
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"User Status", 
            flex:2,
            valueGetter: (cellValues) => {
                switch(cellValues.row.status){
                    case 1:
                        return "Active";
                        break;

                    case 0:
                        return "Disabled";
                        break;

                    default:
                        return "Unknown";
                        break;
                }
            },
        },
        {
            field: 'customerString', 
            headerName: "Customers", 
            description:"User Customers", 
            flex:4         
        },
        {
            field: 'licensedBy', 
            headerName: "Licensed By", 
            description:"Customer Licnese Used", 
            flex:3,
            valueGetter: (cellValues) => {
                return(cellValues.row.licensedBy!==null?cellValues.row.licensedBy.name:'');
            },                           
            hide:true                      
        },
        {
            field: 'adminFlag', 
            headerName: "Admin", 
            description:"User Admin Flag", 
            flex:3,
            valueGetter: (cellValues) => {
                switch(cellValues.row.adminFlag){
                    case 1:
                        return "Yes";
                        break;

                    case 0:
                        return "No";
                        break;

                    default:
                        return "Unknown";
                        break;
                }
            },                     
            hide:true                      
        },
        {
            field: 'reelAdminFlag', 
            headerName: "Reel Admin", 
            description:"User Reel Admin Flag", 
            flex:3,
            valueGetter: (cellValues) => {
                switch(cellValues.row.reelAdminFlag){
                    case 1:
                        return "Yes";
                        break;

                    case 0:
                        return "No";
                        break;

                    default:
                        return "Unknown";
                        break;
                }
            },                     
            hide:true                      
        },
        {
            field: 'southwireFlag', 
            headerName: "Southwire User", 
            description:"Southwire User Flag", 
            flex:3,
            valueGetter: (cellValues) => {
                switch(cellValues.row.southwireFlag){
                    case 1:
                        return "Yes";
                        break;

                    case 0:
                        return "No";
                        break;

                    default:
                        return "Unknown";
                        break;
                }
            },                     
            hide:true                      
        },                  
        {
            field: 'distributorFlag', 
            headerName: "Distributor", 
            description:"User Distributor Flag", 
            flex:3,
            valueGetter: (cellValues) => {
                switch(cellValues.row.distributorFlag){
                    case 1:
                        return "Yes";
                        break;

                    case 0:
                        return "No";
                        break;

                    default:
                        return "Unknown";
                        break;
                }
            },                     
            hide:true                      
        },
        {
            field: 'manufacturerFlag', 
            headerName: "Manufacturer", 
            description:"User Manufacturer Flag", 
            flex:3,
            valueGetter: (cellValues) => {
                switch(cellValues.row.manufacturerFlag){
                    case 1:
                        return "Yes";
                        break;

                    case 0:
                        return "No";
                        break;

                    default:
                        return "Unknown";
                        break;
                }
            },                     
            hide:true                      
        },                         
        {
            field: 'createDate', 
            headerName: "Create Date", 
            description:"Date User was created", 
            flex:3,         
            hide:true                      
        },        
        {
            field: 'modifyDate', 
            headerName: "Modify Date", 
            description:"Date User was last modified", 
            flex:3,         
            hide:true                      
        },        
        {
            field: 'modifyUser', 
            headerName: "Modify User", 
            description:"User who performed last modification", 
            flex:3,         
            hide:true                      
        },        

    ];

    function renderCustomers(ind){

    }

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}}
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.email} 
            />
        </Box>
    );
}
