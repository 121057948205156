import { useNavigate } from 'react-router-dom';

import PalletTurnDetailsHeader from './PalletTurnDetailsHeader'
import PalletTurnDetailsList from './PalletTurnDetailsList'

export default function PalletTurnDetails(props){
    //console.debug("RTS Break PalletTurnDetails");

    const history = useNavigate();

    return (
        <>
        <PalletTurnDetailsHeader data={props.data} />
        <PalletTurnDetailsList data={props.data} />
        </>
    );
}
