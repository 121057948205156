import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import CustomerReelsList from '../../components/CustomerReels/CustomerReelsList';
import CustomerReelsHeader from '../../components/CustomerReels/CustomerReelsHeader';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CustomerReelsPage( props ){
    //console.debug("RTS Break CustomerReelsPage");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
  
    function fetchData(){
        let apiURL = apiRoot + "/CustomerReels/GetAllCustomerReelsFull";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }
    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, []);  
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box id="pageBox">
            <CustomerReelsHeader />
            <Box sx={{mt:2}}>           
                <CustomerReelsList data={data} />
            </Box>
        </Box>
    );
}