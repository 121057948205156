import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import Loading from '../components/Loading';
import Error from '../components/Error';
import SessionCustomersList from '../components/SessionCustomers/SessionCustomersList';

import { useSessionContext } from '../contexts/SessionContext';

export default function SessionCustomersPage( props ){
    //console.debug("RTS Break SessionCustomersPage");

    const {user, sessionCustomer, setSessionCustomer, prevSessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [noData, setNoData] = useState(false);

    const [data, setData] = useState(user.appUserCustomers);

    function fetchData(){
        let apiObj = user;
        let apiURL = apiRoot + "/AppUsers/GetUserCustomerAccessList";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        if(user.adminFlag === 1 || user.reelAdminFlag === 1){
            fetchData();
        }
        else{
            if(data !== null && data.length > 0){
                setIsLoading(false);
                setHasError(false);
            }
            else{
                setIsLoading(false);
                setHasError(false);
                setNoData(true)
                console.error("RTS Data Error", "No User Customer Associations.");                
            }
        }
    }, []);  


    const handleClick = (e) => {
        setSessionCustomer(prevSessionCustomer);
    } 
    

    const checkForNull = () => {
        let retValue;

        if(!prevSessionCustomer){
            retValue = false;
        }
        else{
            retValue = true;
        }
        return retValue;
    }    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    if(noData){
        return (
            <Box sx={{display:'flex', 
                      alignItems:'center', 
                      justifyContent:'center', 
                      flexDirection:'column', 
                      height:'100%', 
                      p:0, 
                      mt:3,
                      border:'1px solid grey', 
                      borderRadius:1}}>
                <Typography variant='h5' sx={{color:'red'}}>No Customers</Typography>
                <Typography variant="caption">The user has no customers access.  Please contact support and associate customers with this user.</Typography>                
            </Box>
          );        
    }


    return(
        <Box>
            <Box id="pageBox" sx={{pt:2}}>
                <SessionCustomersList data={data} />
            </Box>
            <Box sx={{textAlign:'center', mt:3}}>
            {
                checkForNull()
                ?
                <Button variant="contained" onClick={handleClick}>Cancel</Button>
                :
                ""
            }
            </Box>
        </Box>        
    );
}
