import axios from 'axios';
import {useState, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Loading from '../Loading';
import Error from '../Error';
import LoadListList from '../LoadList/LoadListList';
import LoadListMap from '../LoadList/LoadListMap';
import LoadListMode from '../LoadList/LoadListMode';
import LoadPullsEdit from '../LoadPulls/LoadPullsEdit';
import LoadPullAdjustmentEdit from '../LoadPullAdjustment/LoadPullAdjustmentEdit';
import LoadOwnershipEdit from '../LoadOwnership/LoadOwnershipEdit';
import LoadDetails from '../LoadDetails/LoadDetails';
import LoadMarkEdit from '../LoadMark/LoadMarkEdit';
import LoadSetLocation from '../LoadSetLocation/LoadSetLocation';
import LoadPinsEdit from '../LoadPins/LoadPinsEdit';
import LoadProjectEdit from '../LoadProject/LoadProjectEdit';
import LoadPullManagementEdit from '../LoadPullManagement/LoadPullManagementEdit';

import { useSessionContext } from '../../contexts/SessionContext';

export default function DashboardProject( props ) {
    //console.debug("RTS Break DashbaordProject");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(true);
    const [baseMode, setBaseMode] = useState(0);
    const [mode, setMode] = useState(0);
    const [data, setData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [childRecord, setChildRecord] = useState({});
    const [hasError, setHasError] = useState(false);

    function fetchData(){

        let apiObj = {
            "user":user, 
            "status":[1,25,50,75,100],
            "customers":[sessionCustomer.customer],
            "orders":[], 
            "projects":projects, 
            "products":[], 
            "reelTypes":[],
            "reels":[],
            "minLength":0,
            "namedLocations":[],
            "pinned":-1,
            "locked":-1, 
            "sortBy":0
        }

        let apiURL = apiRoot + "/LoadList/GetLoadList";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                if(response.data.length === 0)
                {
                    setMode(-1);
                }
                setIsLoading(false);
                setHasError(false);
            }
            else{
                setMode(-1);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }


    function fetchProjects(){

        let apiObj = {
            Customer:sessionCustomer.customer, 
            User:user, 
            SortBy:0
        }

        let apiURL = apiRoot + "/CustomerProjects/GetProjectsByManager";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                if(response.data.length === 0){
                    setMode(-1);
                    setIsLoading(false);
                    setHasError(false);
                }
                else{
                    setProjects(response.data);
                    //fetchData();
                }
            }
            else{
                setIsLoading(false);
                setHasError(false);
                setMode(-1);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }    


    useEffect(() => {
        fetchProjects();
    }, []);


    useEffect(() => {
        if(projects.length > 0){
            fetchData();
        }
    }, [projects]);    



    function dataRefresh(){
        setIsLoading(true);
        fetchProjects();
        setMode(0);
    }


    function openChild(args){
        if(args['idx'] != null){
            let childIdx = args.idx;
            setChildRecord(data[childIdx]);
        }
        setMode(args.mode);
    }


    function openBaseMode(args){
        setIsLoading(true);
        setBaseMode(args);
        setMode(args);
        setIsLoading(false);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <Box id="pageBox">
            <Box id="modeBox">
                <LoadListMode data={data} modeFx={openBaseMode} mode={mode} reelCount={data===null?0:data.length} />            
            </Box>            
            <Box id="contentBox">
                {mode===-1?<Box justifyContent={"center"} alignItems={"center"} display={"flex"}><Typography variant='h4'>No Records Found</Typography></Box>:""}
                {mode===0?<LoadListList data={data} user={user} openChildFx={openChild} dataRefreshFx={dataRefresh}/>:""}
                {mode===1?<LoadListMap data={data} user={user} openChildFx={openChild} dataRefreshFx={dataRefresh}/>:""}
                {mode===3?<LoadPullsEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===4?<LoadDetails data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===5?<LoadMarkEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===6?<LoadSetLocation data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===7?<LoadPullAdjustmentEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===8?<LoadOwnershipEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===9?<LoadPinsEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===10?<LoadProjectEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
                {mode===11?<LoadPullManagementEdit data={childRecord} user={user} modeFx={setMode} baseMode={baseMode} dataRefreshFx={dataRefresh}/>:""}
            </Box> 
        </Box>
    );
}

