import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ClearIcon from '@mui/icons-material/Clear';

export default function CustomersEditLinksList(props){
    //console.debug("RTS Break CustomersEditLinksList")

    function handleClick(e, cellValues){
        props.removeCustomerFx(cellValues.row);
    }

    const columns = [
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:4,
            valueGetter: (cellValues) => {
                return cellValues.row.child.name;
            },            
        },
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Customer Id", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.child.idSap;
            },            
        },        
        {
            field: 'salesOffice', 
            headerName: "Office Id", 
            description:"Sales Office Id", 
            flex:3, 
            valueGetter: (cellValues) => {
                return cellValues.row.child.salesOffice;
            },            
        },
        {
            field: 'salesGroup', 
            headerName: "Group Id", 
            description:"Sales Group Id", 
            flex:3, 
            valueGetter: (cellValues) => {
                return cellValues.row.child.salesGroup;
            },            
        },
        {
            field: '',
            description:"Remove Customer", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <ClearIcon sx={{color:"red"}} />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },        
    ];

    return (
        <Box sx={{width:'100%', mt:1}}>
            <DataGridPro 
                autoHeight 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.child.idGuid} 
            />
        </Box>
    );
}
