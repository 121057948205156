import axios from 'axios';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersEditCreate(props) {
    //console.debug("RTS Break UserRequestsEditBody");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');

    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            email: props.email,
            firstName: fName,
            lastName: lName,
            customer: sessionCustomer.customer,
            status: 1,
            createUser: user.userName,
            modifyUser: user.userName
        }


        let apiURL = apiRoot + "/UserRequests/CreateUserRequest";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setErrorTitle("InsertError");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateUser Error", error);
            setShowErrorModal(true);
        });
    }     



    return (
        <form id="requestBodyForm" onSubmit={handleSubmit}>
            <Box>
                <TextField
                    id="tbFName"
                    margin="normal"
                    fullWidth
                    label="First Name"
                    size="small"
                    required
                    value={fName}
                    onChange={(e) => {setFName(e.target.value);}}
                    disabled={props.validated?false:true}
                />
                <TextField
                    id="tbLName"
                    margin="normal"
                    fullWidth
                    label="Last Name"
                    size="small"
                    required
                    value={lName}
                    onChange={(e) => {setLName(e.target.value);}}
                    disabled={props.validated?false:true}
                />
            </Box>
            <Box sx={{textAlign:'center', mt:3}}>
                <Button variant="contained"  type="submit" disabled={props.validated?false:true}>Submit Request</Button>
            </Box>        
        </form>
    );
}
