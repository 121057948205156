import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography>
                The Reels page allows for association of a tracker with a reel.
            </Typography>
            <Typography sx={{mt:1}}>
                Find the reel you wish to associate a tracker with in the list and click on the 
                pencil icon for the reel.   
            </Typography>
            <Typography sx={{mt:1}}>
                To filter the list of reels you can enter the Reel Serial Number into the search bar at
                the top right of the table or choose the filter option from the menu for more advanced 
                filtering capabilities.   
            </Typography>

            <Typography sx={{mt:1}}>
                The fields tracked within the system include:
            </Typography>
            <table>
                <tbody>
                <tr>
                    <td>
                        Serial Number
                    </td>
                    <td>
                        The serial number of the physical reel.  This number is what is used to track the 
                        reel within the SAP system.
                    </td>
                </tr>
                <tr>
                    <td>
                        Tracker
                    </td>
                    <td>
                        A pick list of Trackers within the system.  Using this list you can choose which
                        tracker is attached to the reel.  The system will not allow you to associate a 
                        tracker with more than one reel.
                    </td>
                </tr>
                </tbody>
            </table>          
        </Box>
    );

}
