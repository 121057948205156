import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';

export default function CrewMembersList(props){
    //console.debug("RTS Break CrewMembersList")

    const history = useNavigate();

    function handleRemoveClick(e, cellValues){
        history("/CrewMembersEdit", {state:{data:cellValues.row, mode: 99}});
    }

    const columns = [
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"Crew Member First Name", 
            flex:3 
        },
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"Crew Member Last Name", 
            flex:3 
        },
        {
            field: 'email', 
            headerName: "Email Address", 
            description:"Crew Member Email Address", 
            flex:4
        },
        {
            field: "Remove",
            headerName: "",
            description:"Remove Crew Member", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleRemoveClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <ClearIcon sx={{color:"red"}} />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
    ];

    return (
        <>
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.email} 
            />
        </Box>
        </>
    );
}
