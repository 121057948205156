import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import EditIcon from '@mui/icons-material/Edit'; 

export default function PickupRequestsAdminList(props){
    //console.debug("RTS Break PickupRequestsAdminList");
    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/PickupRequestsAdminEdit", {state:{data:cellValues.row, mode:1}});
    }


    const columns = [
        {
            field: '',
            description:"Edit Request", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },
        {
            field: 'customerName', 
            headerName: "Customer Name", 
            description:"Customer Name", 
            flex:4,
            renderCell: (cellValues) => {
                return cellValues.row.customer.name
            },
            hide:true
        },        
        {
            field: 'name', 
            headerName: "Name", 
            description:"Pickup Request Name", 
            flex:4    
        },
        {
            field: 'loadCount', 
            headerName: "Reels", 
            description:"Number of Reels", 
            flex:1    
        },
        {
            field: 'createDate', 
            headerName: "Create Date", 
            description:"Create Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },
        {
            field: 'submitDate', 
            headerName: "Submit Date", 
            description:"submit Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        }        
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
