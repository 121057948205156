import axios from 'axios';
import { useState, useContext } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';

import EditCancel from '../EditCancel';
import PickupRequestsAdminEditCustomerList from './PickupRequestsAdminEditCustomerList';
import PickupRequestsAdminEditStatusList from './PickupRequestsAdminEditStatusList';
import PickupRequestAdminEditMarkedLoadsList from './PickupRequestsAdminEditMarkedLoadsList';
import PickupRequestAdminLoadsList from './PickupRequestAdminLoadsList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsAdminEdit(props) {
    //console.debug("RTS Break PickupRequestsAdminEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;

    const [customer, setCustomer] = useState(mode===0?user.appUserCustomers.length>1?null:user.appUserCustomers[0]:data.customer);
    const [status, setStatus] = useState(mode===0?'':data.status);
    const [name, setName] = useState(mode===0?'':data.name);
    const [contact, setContact] = useState(mode===0?'':data.onSiteContactName);
    const [phoneNumber, setPhoneNumber] = useState(mode===0?'':data.phoneNumber);
    const [altPhoneNumber, setAltPhoneNumber] = useState(mode===0?'':data.altPhoneNumber);
    const [contactEmail, setContactEmail] = useState(mode===0?'':data.contactEmail);
    const [hours, setHours] = useState(mode===0?'':data.shipRecHours);
    const [address1, setAddress1] = useState(mode===0?'':data.address1);
    const [address2, setAddress2] = useState(mode===0?'':data.address2);
    const [city, setCity] = useState(mode===0?'':data.city);
    const [region, setRegion] = useState(mode===0?'':data.region);
    const [zipCode, setZipCode] = useState(mode===0?'':data.zip);
    const [daysAvailable, setDaysAvailable] = useState(mode===0?'':data.daysAvailable);
    const [notes, setNotes] = useState(mode===0?'':data.notes);
    const [siteCloseDate, setSiteCloseDate] = useState(mode===0?format( new Date(), 'MM-dd-yyyy'):data.siteCloseDate==null?null:format(parseISO(data.siteCloseDate), 'MM-dd-yyyy'));
    const [submitDate, setSubmitDate] = useState(mode===0?'':data.submitDate);
    const [loads, setLoads] = useState(mode===0?[]:data.loads);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showLoadsModal, setShowLoadsModal] = useState(false);

   
    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid,
            Customer:customer,
            Name: name,
            OnSiteContactName: contact,
            PhoneNumber: phoneNumber,
            ShipRecHours: hours,
            DaysAvailable: daysAvailable,
            Notes: notes,
            SiteCloseDate: siteCloseDate,
            Lat: null,
            Lng: null,      
            Address1: address1,
            Address2: address2,
            City: city,
            Region: region,
            Zip: zipCode,
            Country: null,    
            SubmitUser: null,
            SubmitDate: submitDate,
            Loads: loads,
            status: 50,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/PickupRequest/UpdatePickupRequest";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdatePickupRequest Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/PickupRequest/CreatePickupRequest";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreatePickupRequest Error", error);
                setShowErrorModal(true);
            });
        }
    } 

    
    function openLoadsModal(){
        let curCustomer;
        if(user.appUserCustomers.length > 1)
        {
            if(customer == ""){
                setErrorTitle("Customer Required");
                setErrorBody("You must select a customer prior to adding reels.");
                setShowErrorModal(true);
                return;
            }
            else{
                curCustomer = customer;
            }
        }
        else{
            curCustomer = user.appUserCustomers[0];
        }

        let apiURL = apiRoot + "/PickupRequest/GetMarkedLoads";
        axios
        .post(
            apiURL,
            curCustomer, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setLoads(response.data);
            }
            setShowLoadsModal(true);
        })
        .catch(error => {
            setShowErrorModal(true);
            console.log("RTS Data Error", error);
        });        

    } 
    
    
    function addLoads(args){
        setShowLoadsModal(false);
        let curLoadList = JSON.parse(JSON.stringify(loads));

        args.forEach((item) => {
            if(curLoadList.filter(l => l.idGuid == item.idGuid).length > 0){
                return;
            }

            //let curLoadList = loadList.filter((l) => l.idGuid !== item.idGuid);
            curLoadList.push(item);
        });
            
        setLoads(curLoadList);        
    }


    function removeLoad(args){
        let curLoadList = loads.filter((l) => l.idGuid !== args.idGuid);
        setLoads(curLoadList);
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showLoadsModal}>
            <DialogTitle>Add Reel</DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:"400px"}}>
                    <PickupRequestAdminEditMarkedLoadsList data={loads} addLoadsFx={addLoads} />
                </Box>            
            </DialogContent>
        </Dialog>
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="projectForm" onSubmit={handleSubmit}>
                {mode===0
                ?
                    <PickupRequestsAdminEditCustomerList mode={mode} customer={customer} setCustomerFx={setCustomer} />
                :
                <Box>
                    <Typography sx={{mb:2}}>{customer.name}</Typography>
                </Box>
                }
                <PickupRequestsAdminEditStatusList mode={mode} status={status} setStatusFx={setStatus} />
                <DatePicker
                    id="tbSubmitDate"
                    label="Submit Date"
                    inputFormat="MM/dd/yyyy"
                    value={submitDate}
                    onChange={(val) => setSubmitDate(val)}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                />  
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {setName(e.target.value);}}
                />           
                <TextField
                    id="tbContact"
                    margin="normal"
                    fullWidth
                    label="On-Site Contact"
                    size="small"
                    value={contact}
                    onChange={(e) => {setContact(e.target.value);}}
                />
                <TextField
                    id="tbPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    size="small"
                    value={phoneNumber}
                    onChange={(e) => {setPhoneNumber(e.target.value);}}
                />
                <TextField
                    id="tbAltPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Alt. Phone Number"
                    size="small"
                    value={altPhoneNumber}
                    onChange={(e) => {setAltPhoneNumber(e.target.value);}}
                    disabled={data.submitDate==null?false:true}
                />
                <TextField
                    id="tbContactEmail"
                    margin="normal"
                    fullWidth
                    label="Email"
                    size="small"
                    value={contactEmail}
                    onChange={(e) => {setContactEmail(e.target.value);}}
                    disabled={data.submitDate==null?false:true}
                />                
                <Typography variant="h6" sx={{mt:2, mb:1}}>Pickup Address</Typography>
                <TextField
                    id="tbAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={address1}
                    onChange={(e) => {setAddress1(e.target.value);}}
                />
                <TextField
                    id="tbAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={address2}
                    onChange={(e) => {setAddress2(e.target.value);}}
                />
                <TextField
                    id="tbCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={city}
                    onChange={(e) => {setCity(e.target.value);}}
                />
                <TextField
                    id="tbState"
                    margin="normal"
                    fullWidth
                    label="State"
                    size="small"
                    value={region}
                    onChange={(e) => {setRegion(e.target.value);}}
                />
                <TextField
                    id="tbZip"
                    margin="normal"
                    fullWidth
                    label="Zip Code"
                    size="small"
                    value={zipCode}
                    onChange={(e) => {setZipCode(e.target.value);}}
                />
                <TextField
                    id="tbHours"
                    margin="normal"
                    fullWidth
                    label="Shipping/Receiving Hours"
                    size="small"
                    value={hours}
                    onChange={(e) => {setHours(e.target.value);}}
                />
                <TextField
                    id="tbDaysAvailable"
                    margin="normal"
                    fullWidth
                    label="Days Available"
                    size="small"
                    value={daysAvailable}
                    onChange={(e) => {setDaysAvailable(e.target.value);}}
                />
                <DatePicker
                    id="tbSiteCloseDate"
                    label="Site Close Date"
                    inputFormat="MM/dd/yyyy"
                    value={siteCloseDate}
                    onChange={(val) => setSiteCloseDate(val)}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                />    
                <TextField
                    id="tbNotes"
                    margin="normal"
                    fullWidth
                    label="Notes"
                    size="small"
                    value={notes}
                    onChange={(e) => {setNotes(e.target.value);}}
                />
                <Box sx={{mt:2}}>
                    <Grid container sx={{mb:1}}>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Pickup Request Reels</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:'right'}}>
                            {data.submitDate==null
                            ?
                            <Button variant='contained' onClick={openLoadsModal}>Add Reels</Button>
                            :
                            ""
                            }
                        </Grid>
                    </Grid>
                    <PickupRequestAdminLoadsList data={loads}  removeLoadFx={removeLoad} />
                </Box>
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );

}
