import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function LoadDetailsCancel(props) {
    return (
        <Box sx={{textAlign:'center', pt:2}}>
            <Button type="cancel" variant="contained" onClick={handleCancel}>Close</Button>
        </Box>
    );

    function handleCancel(e){
        e.preventDefault();
        props.modeFx(props.baseMode);
    }

}
