import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';


import EditCancel from '../EditCancel';
import LoadsEditReelList from './LoadsEditReelList';
import LoadsEditCustomerSoldToList from './LoadsEditCustomerSoldToList';
import LoadsEditCustomerAssignedToList from './LoadsEditCustomerAssignedToList';
import LoadsEditCustomerShipToList from './LoadsEditCustomerShipToList';
import LoadsEditPlantList from './LoadsEditPlantList';
import LoadsEditProductList from './LoadsEditProductList';
import LoadsEditTrackerList from './LoadsEditTrackerList';
import LoadsEditCustomerReelList from './LoadsEditCustomerReelList';
import LoadsEditCustomerProjectList from './LoadsEditCustomerProjectList';
import LoadsEditUomList from './LoadsEditUomList';
import EditRegion from '../EditRegion';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsEdit(props) {
    console.debug("RTS Break LoadsEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = props.data;
    const mode = props.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [status] = useState(mode===1?data.status!==null?data.status:1:1);
    const [soldToCustomer, setSoldToCustomer] = useState(mode===1?data.soldToCustomer:null);
    const [assignedToCustomer, setAssignedToCustomer] = useState(mode===1?data.assignedToCustomer:null);
    const [shipToCustomer, setShipToCustomer] = useState(mode===1?data.shipToCustomer:null);
    const [shipFromPlant, setShipFromPlant] = useState(mode===1?data.shipFromPlant:null);
    const [product, setProduct] = useState(mode===1?data.product:null);
    const [reel, setReel] = useState(mode===1?data.reel:null);
    const [tracker, setTracker] = useState(mode===1?data.tracker:null);
    const [customerVisibleFlag, setCustomerVisibleFlag] = useState(mode===1?data.customerVisibleFlag:0);
    const [customerReel, setCustomerReel] = useState(mode===1?data.customerReel:null);
    const [customerProject, setCustomerProject] = useState(mode===1?data.customerProject:null);
    const [shipDate, setShipDate] = useState(mode===1?format(parseISO(data.shipDate), 'MM-dd-yyyy'):format( new Date(), 'MM-dd-yyyy'));
    const [amt, setAmt] = useState(mode===1?data.amt:0);
    const [uom, setUom] = useState(mode===1?data.uom.toLowerCase():'ft');
    const [price, setPrice] = useState(mode===1?data.price!==null?data.price:0:0)
    const [shipToName, setShipToName] = useState(mode===1?data.shipToName!==null?data.shipToName:'':'');
    const [shipToAddress1, setShipToAddress1] = useState(mode===1?data.shipToAddress1!==null?data.shipToAddress1:'':'');
    const [shipToAddress2, setShipToAddress2] = useState(mode===1?data.shipToAddress2!==null?data.shipToAddress2:'':'');
    const [shipToCity, setShipToCity] = useState(mode===1?data.shipToCity!==null?data.shipToCity:'':'');
    const [shipToRegion, setShipToRegion] = useState(mode===1?data.shipToRegion!==null?data.shipToRegion:'':'');
    const [shipToZip, setShipToZip] = useState(mode===1?data.shipToZip!==null?data.shipToZip:'':'');
    const [deferralPeriod, setDeferralPeriod] = useState(mode===1?data.deferralPeriod:0);
    const [netWeight, setNetWeight] = useState(mode===1?data.netWeight!==null?data.netWeight:'':'');
    const [tareWeight, setTareWeight] = useState(mode===1?data.tareWeight!==null?data.tareWeight:'':'');
    const [grossWeight, setGrossWeight] = useState(mode===1?data.grossWeight!==null?data.grossWeight:'':'');
    const [customerPo, setCustomerPo] = useState(mode===1?data.customerPo!==null?data.customerPo:'':'');
    const [customerMaterialNo, setCustomerMaterialNo] = useState(mode===1?data.customerMaterialNo!==null?data.customerMaterailNo:'':'');
    const [idSapPo, setIdSapPo] = useState(mode===1?data.idSapPo!==null?data.idSapPo:'':'');
    const [idSapBol, setIdSapBol] = useState(mode===1?data.idSapBol!==null?data.idSapBol:'':'');
    const [idSapPackingList, setIdSapPackingList] = useState(mode===1?data.idSapPackingList!==null?data.idSapPackingList:'':'');
    const [idSapBatchNo, setIdSapBatchNo] = useState(mode===1?data.idSapBatchNo!==null?data.idSapBatchNo:'':'');

    function handleSubmit(e) {
        e.preventDefault();
 
        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid,
            Reel: reel,
            AssignedToCustomer: assignedToCustomer,
            SoldToCustomer: soldToCustomer,
            ShipToCustomer: shipToCustomer,
            ShipFromPlant: shipFromPlant,
            Product: product,
            CustomerProject: customerProject,
            Tracker: tracker,
            CustomerVisibleFlag: customerVisibleFlag,
            CustomerReel: customerReel,
            Amt: amt,
            Uom: uom,
            Price: price,
            ShipDate: shipDate,
            ShipToName: shipToName,
            ShipToAddress1: shipToAddress1,
            ShipToAddress2: shipToAddress2,
            ShipToCity: shipToCity,
            ShipToRegion: shipToRegion,
            ShipToZip: shipToZip,
            ShipToGeoLat: null,
            ShipToGeoLng: null,
            DeferralPeriod: deferralPeriod,
            NetWeight: netWeight,
            TareWeight: tareWeight,
            GrossWeight: grossWeight,
            CustomerPo: customerPo,
            CustomerMaterialNo: customerMaterialNo,
            IdSapPo: idSapPo,
            IdSapBol: idSapBol,
            IdSapPackingList: idSapPackingList,
            IdSapBatchNo: idSapBatchNo,
            Status: status,
            ReelType: reel.reelType,
            MarkDate: data.markDate,
            MarkUser: data.markUser,
            MarkEmptyScrap: data.markEmptyScrap,
            MarkWeight: data.markWeight,
            PickupRequestDate: data.pickupRequestDate,
            PickupRequest: data.pickupRequest, 
            ReturnedDate: data.returnedDate,
            modifyUser: user.userName
        }

        if(apiObj.AssignedToCustomer === null){
            setErrorTitle("Validation Error");
            setErrorBody("Assigned To Customer is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.SoldToCustomer === null){
            setErrorTitle("Validation Error");
            setErrorBody("Sold To Customer is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.ShipToCustomer === null){
            setErrorTitle("Validation Error");
            setErrorBody("Ship To Customer is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Reel === null){
            setErrorTitle("Validation Error");
            setErrorBody("Reel is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Product === null){
            setErrorTitle("Validation Error");
            setErrorBody("Product is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.ShipFromPlant === null){
            setErrorTitle("Validation Error");
            setErrorBody("Ship From Plant is required");
            setShowErrorModal(true);
            return;
        }


        if(apiObj.Amt === null || apiObj.Amt === 0){
            setErrorTitle("Validation Error");
            setErrorBody("Amount is required and must be greater than 0");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Uom === null){
            setErrorTitle("Validation Error");
            setErrorBody("Unit of  Measure is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.ShipDate === null){
            setErrorTitle("Validation Error");
            setErrorBody("Ship Date is required");
            setShowErrorModal(true);
            return;
        }



        if(mode > 0){
            let apiURL = apiRoot + "/Loads/UpdateLoad/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateLoad Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Loads/CreateLoad";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateLoad Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="loadsForm" onSubmit={handleSubmit}>
                <LoadsEditCustomerSoldToList customer={soldToCustomer} setCustomerFx={setSoldToCustomer} />
                <LoadsEditCustomerAssignedToList customer={assignedToCustomer} setCustomerFx={setAssignedToCustomer} />
                <LoadsEditCustomerShipToList customer={shipToCustomer} setCustomerFx={setShipToCustomer} />
                <LoadsEditPlantList plant={shipFromPlant} setPlantFx={setShipFromPlant} />
                <LoadsEditReelList reel={reel} setReelFx={setReel} />
                <LoadsEditProductList product={product} setProductFx={setProduct} />
                <LoadsEditTrackerList tracker={tracker} setTrackerFx={setTracker} />
                <Box sx={{display:"flex"}}>
                    <FormControl component="fieldset" sx={{flexGrow:1}}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={customerVisibleFlag==1?true:false} 
                                            onChange={(e) => {e.target.checked?setCustomerVisibleFlag(1):setCustomerVisibleFlag(0);}} 
                                            name="cbxCustomerVisible" 
                                    />
                                }
                                label="Customer Visible"
                            />
                        </FormGroup> 
                    </FormControl>
                </Box>
                <LoadsEditCustomerReelList customerReel={customerReel} setCustomerReelFx={setCustomerReel} />
                <LoadsEditCustomerProjectList data={data} mode={mode} setCustomerProjectFx={setCustomerProject} />
                <LoadsEditUomList uom={uom} setUomFx={setUom} />
                <TextField
                    id="tbAmount"
                    type="number"
                    margin="normal"
                    fullWidth
                    label="Length (ft)"
                    size="small"
                    required
                    value={amt}
                    onChange={(e) => {setAmt(e.target.value);}}
                    sx={{flex:1, mr:1}}
                />
                <TextField
                    id="tbPrice"
                    type="number"
                    margin="normal"
                    fullWidth
                    label="Price"
                    size="small"
                    value={price}
                    onChange={(e) => {setPrice(e.target.value);}}
                />
                <DatePicker
                    id="tbShipDate"
                    label="Ship Date"
                    inputFormat="MM/dd/yyyy"
                    value={shipDate}
                    onChange={(val) => setShipDate(val)}
                    renderInput={(params) => <TextField margin="normal" required size="small" {...params} />}
                />  
                <Typography variant="h6" sx={{mt:2, mb:1}}>Shipping Address</Typography>
                <TextField
                    id="tbShipToName"
                    margin="none"
                    fullWidth
                    label="Customer Name"
                    size="small"
                    value={shipToName}
                    onChange={(e) => {setShipToName(e.target.value);}}
                    sx={{mb:2}}
                />
                <TextField
                    id="tbShipToAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={shipToAddress1}
                    onChange={(e) => {setShipToAddress1(e.target.value);}}
                />
                <TextField
                    id="tbShipToAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={shipToAddress2}
                    onChange={(e) => {setShipToAddress2(e.target.value);}}
                />
                <TextField
                    id="tbShipToCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={shipToCity}
                    onChange={(e) => {setShipToCity(e.target.value);}}
                />
                <Box sx={{display:"flex"}}>
                    <Box sx={{flex:1, mr:1}} >
                        <EditRegion region={shipToRegion} setShipToRegionFx={setShipToRegion} />
                    </Box>
                    <TextField
                        id="tbShipToZip"
                        margin="normal"
                        fullWidth
                        label="Zip Code"
                        size="small"
                        value={shipToZip}
                        onChange={(e) => {setShipToZip(e.target.value);}}
                        sx={{flex:1}}
                    />
                </Box>
                <Typography variant="h6" sx={{mt:2, mb:1}}>Reel Deferral</Typography>
                <TextField
                    id="tbDeferralPeriod"
                    margin="none"
                    fullWidth
                    label="Deferral Period (Months)"
                    size="small"
                    value={deferralPeriod}
                    onChange={(e) => {setDeferralPeriod(e.target.value);}}
                />
                <Typography variant="h6" sx={{mt:2, mb:1}}>Weights</Typography>
                <TextField
                    id="tbNetWeight"
                    margin="none"
                    fullWidth
                    label="Net Weight"
                    size="small"
                    value={netWeight}
                    onChange={(e) => {setNetWeight(e.target.value);}}
                />
                <TextField
                    id="tbTareWeight"
                    margin="normal"
                    fullWidth
                    label="Tare Weight"
                    size="small"
                    value={tareWeight}
                    onChange={(e) => {setTareWeight(e.target.value);}}
                />                
                <TextField
                    id="tbGrossWeight"
                    margin="normal"
                    fullWidth
                    label="Gross Weight"
                    size="small"
                    value={grossWeight}
                    onChange={(e) => {setGrossWeight(e.target.value);}}
                />                
                <Typography variant="h6" sx={{mt:2, mb:1}}>Customer References</Typography>
                <TextField
                    id="tbCustomerPo"
                    margin="none"
                    fullWidth
                    label="Customer Purchase Order"
                    size="small"
                    value={customerPo}
                    onChange={(e) => {setCustomerPo(e.target.value);}}
                />
                <TextField
                    id="tbCustomerMaterialNo"
                    margin="normal"
                    fullWidth
                    label="Customer Material Number"
                    size="small"
                    value={customerMaterialNo}
                    onChange={(e) => {setCustomerMaterialNo(e.target.value);}}
                />
                <Typography variant="h6" sx={{mt:2, mb:1}}>SAP Documents</Typography>
                <TextField
                    id="tbIdSapPo"
                    margin="none"
                    fullWidth
                    label="Purchase Order"
                    size="small"
                    value={idSapPo}
                    onChange={(e) => {setIdSapPo(e.target.value);}}
                />
                <TextField
                    id="tbIdSapBol"
                    margin="normal"
                    fullWidth
                    label="Bill of Lading"
                    size="small"
                    value={idSapBol}
                    onChange={(e) => {setIdSapBol(e.target.value);}}
                />
                <TextField
                    id="tbIdSapPackingList"
                    margin="normal"
                    fullWidth
                    label="Packing List"
                    size="small"
                    value={idSapPackingList}
                    onChange={(e) => {setIdSapPackingList(e.target.value);}}
                />                
                <TextField
                    id="tbIdSapBatchNo"
                    margin="normal"
                    fullWidth
                    label="Batch Number"
                    size="small"
                    value={idSapBatchNo}
                    onChange={(e) => {setIdSapBatchNo(e.target.value);}}
                />          
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );

}
