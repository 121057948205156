import axios from 'axios';
import { useState, useEffect } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Error from '../Error';
import Loading from '../Loading';
import EditCancel from '../EditCancel';
import ProductAliasEditProductLookup from './ProductAliasEditProductLookup';

import SearchIcon from '@mui/icons-material/Search';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductAliasEdit(props) {
    //console.debug("RTS Break ProductAliasEdit");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();
    const location = useLocation();

    const data = location.state.data;
    const mode = location.state.mode;
    const keys = location.state.keys;

    const [formDirty, setFormDirty] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [type, setType] = useState(0);
    const [productObj, setProductObj] = useState(null);
    const [product, setProduct] = useState('');
    const [wildcard, setWildcard] = useState('');
    const [alias, setAlias] = useState('');
    const [customerMaterialNo, setCustomerMaterialNo] = useState('');
    const [ageIndex, setAgeIndex] = useState('');
    const [remnantLimit, setRemnantLimit] = useState(0);

    const [showProductLookupModal, setShowProductLookupModal] = useState(false);

    useEffect(() => {
        try{
            if(mode===1){
                setType(data.type);
                setProductObj(data.type===0?data.product:null);
                setProduct(data.type===0?data.product.name + " (" + data.product.idSap + ")":'');
                setWildcard(data.type===1?data.wildcard:'');
                setAlias(data.alias);
                setCustomerMaterialNo(data.customerMaterialNo);
                setAgeIndex(data.ageIndexLimit);
                setRemnantLimit(data.remnantLimit);
            }

            setIsLoading(false);
        }
        catch(e){
            setIsLoading(false);
            setHasError(true);
        }
    }, []);  


    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid,
            Customer: sessionCustomer.customer,
            Type: type,
            Product: productObj,
            Wildcard: wildcard,
            Alias: alias,
            AgeIndexLimit:ageIndex===0?365:ageIndex,
            RemnantLimit:remnantLimit===0?0:remnantLimit,
            CustomerMaterialNo: customerMaterialNo,
            modifyUser: user.userName
        }

        if(apiObj.Customer === null){
            setErrorBody("Customer is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Type === 0 && apiObj.Product === null){
            setErrorBody("Product is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Type === 1 && apiObj.Wildcard === null){
            setErrorBody("Wildcard is required");
            setShowErrorModal(true);
            return;
        }        

        if(apiObj.Alias === null){
            setErrorBody("Alias is required");
            setShowErrorModal(true);
            return;
        }

        if(mode > 0){
            let apiURL = apiRoot + "/ProductAlias/UpdateProductAlias";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateProductAlias Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/ProductAlias/CreateProductAlias";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateProductAlias Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    function setProductVal(productObj){
        try{
            setIsLoading(true);
            if(keys.includes(productObj.idGuid)){
                setErrorTitle("Duplicate Value")
                setErrorBody("A record already exists for this product.")
                setShowErrorModal(true);
            }
            else{
                setProductObj(productObj);
                setProduct(productObj.name + " (" + productObj.idSap + ")")
                setAlias(productObj.name);
                setCustomerMaterialNo(productObj.idSap);
                setAgeIndex(365);
                setRemnantLimit(0);
                setFormDirty(1); 
            }
            setShowProductLookupModal(false);
            setIsLoading(false);
        }
        catch(e){
            setShowProductLookupModal(false);
            setIsLoading(false);
            
            setErrorTitle("Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS Product Alias Error", e.message);
            setShowErrorModal(true);
        }
    }


    if(hasError){
        return(
            <Box sx={{mt:2}}>
                <Error />
            </Box>
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showProductLookupModal} onClose={()=>{setShowProductLookupModal(false);}}>
            <DialogTitle>Select Product</DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:"400px"}}>
                    <ProductAliasEditProductLookup setProductFx={setProductVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{mr:2}} onClick={e => {setShowProductLookupModal(false);}}>Cancel</Button>
            </DialogActions>
        </Dialog>                   
        <Box>
        {formDirty===0
            ?
                <Typography variant="h5" sx={{my:2}}>Edit Customer Part</Typography>
            :
                <Typography variant="h5" sx={{my:2, fontStyle:'italic'}}>Edit Customer Part *</Typography>
            }
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="projectForm" onSubmit={handleSubmit}>
                <Box sx={{mb:1}}>
                    <Typography>{sessionCustomer.customer.name}</Typography>
                </Box>

                <RadioGroup
                    name="rbType"
                    row
                    value={type}
                    onChange={(e) => {setFormDirty(1); setType(parseInt(e.target.value)); if(e.target.value==="1"){setProductObj(null);}}}
                >
                    <FormControlLabel value="0" control={<Radio />} label="Standard" />
                    <FormControlLabel value="1" control={<Radio />} label="Wildcard" />
                </RadioGroup>

                <Box sx={{display:type===0?'default':'none', visible:type===0?'visible':'hidden'}}>
                    <Box sx={{display:'flex'}}>
                        <TextField
                            id="tbProductNumber"
                            margin="normal"
                            fullWidth
                            label="Product"
                            size="small"
                            value={product}
                            aria-readonly={true}
                            required={type===0?true:false}
                        />
                        <Button 
                            type="button" 
                            sx={{ml:1, width:'40px'}} 
                            onClick={(e) => {setShowProductLookupModal(true);}}
                        >
                            <SearchIcon sx={{fontWeight:"bold", fontSize:"2em"}} />
                        </Button>
                    </Box>
                </Box>

                <Box sx={{display:type===1?'default':'none', visible:type===0?'visible':'hidden'}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <TextField
                            id="tbWildcard"
                            margin="normal"
                            label="Wildcard"
                            size="small"
                            value={wildcard}
                            required={type===1?true:false}
                            onChange={(e) => {setFormDirty(1); setWildcard(e.target.value);}}
                        />
                        <Typography variant='h6' sx={{ml:1}}>%</Typography>
                    </Box>
                </Box>

                <TextField
                    id="tbCustomerMaterialNo"
                    margin="normal"
                    fullWidth
                    label="Customer Material No"
                    size="small"
                    required
                    value={customerMaterialNo}
                    onChange={(e) => {setFormDirty(1); setCustomerMaterialNo(e.target.value);}}
                />

                <TextField
                    id="tbAlias"
                    margin="normal"
                    fullWidth
                    label="Product Alias"
                    size="small"
                    required
                    value={alias}
                    onChange={(e) => {setFormDirty(1); setAlias(e.target.value);}}
                />

                <TextField
                    id="tbAgeIndex"
                    type="number"
                    margin="normal"
                    fullWidth
                    label="Age Index Limit (Days)"
                    size="small"
                    required
                    value={ageIndex}
                    onChange={(e) => {setFormDirty(1); setAgeIndex(e.target.value);}}
                /> 


                <TextField
                    id="tbRemnantLimit"
                    type="number"
                    margin="normal"
                    fullWidth
                    label="Remnant Limit"
                    size="small"
                    required
                    value={remnantLimit}
                    onChange={(e) => {setFormDirty(1); setRemnantLimit(e.target.value);}}
                />                                

                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" disabled={formDirty===1?false:true} sx={{mr:2}}>Save</Button>
                    <EditCancel formDirty={formDirty} />
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>                
    );
}
