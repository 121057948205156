import axios from 'axios';
import {useState, useContext} from 'react';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import UsersEditCustomerAccessList from './UsersEditCustomerAccessList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function UsersEditAccessLevel(props) {
    //console.debug("RTS Break UsersEditAccessLevel"); 
    
    const [accessLevel, setAccessLevel] = useState({id:50, label:'Customer Representative'});

    function handleSubmit(e) {
        e.preventDefault();
        props.addCustomerFx(accessLevel.id);
    } 

    return (
        <form onSubmit={handleSubmit}>
            <Typography>Set the access level for customer:</Typography>
            <Typography>{props.data.name}</Typography>
            <Box sx={{mt:2}}>
                <UsersEditCustomerAccessList data={accessLevel} setAccessLevelFx={setAccessLevel} />
            </Box>
            <Box sx={{textAlign:"center", mt:2}}>
                <Button variant="contained" onClick={handleSubmit} sx={{mr:2}}>Add Customer</Button>
                <Button variant="contained" onClick={e => {props.showAccessLevelModalFx(false);}}>Cancel</Button>
            </Box>
        </form>
    );
}
