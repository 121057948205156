import { useNavigate } from 'react-router-dom';

import PalletPlantDetailsHeader from './PalletPlantDetailsHeader'
import PalletPlantDetailsList from './PalletPlantDetailsList'

export default function PalletPlantDetails(props){
    //console.debug("RTS Break PalletPlantDetails");

    const history = useNavigate();

    return (
        <>
        <PalletPlantDetailsHeader data={props.plantData} />
        <PalletPlantDetailsList data={props.actionData} />
        </>
    );
}
