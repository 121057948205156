import {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useSessionContext } from '../../contexts/SessionContext';

export default function WorkNavButSystem( props ){
    //console.debug("RTS Break PalletNavButSystem");

    const {user} = useSessionContext();
    const history = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorElSystem, setAnchorElSystem] = useState(null);

    const handleOpenSystemMenu = (e) => {
        setOpen(!open);
        setAnchorElSystem(e.currentTarget);
    };
      
    const handleCloseSystemMenu = () => {
        props.setOpenDrawerFx(false);
        setAnchorElSystem(null);
    };
   
    function handleSysUsersClick(e){
        // props.closeNavMenuFx();
        handleCloseSystemMenu();
        history("/Users");
    }  

    const isMobile = props.setOpenDrawerFx;

    if(user.adminFlag != 1){
        return("");
    }

    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="drawer-PalletSystem" onClick={handleOpenSystemMenu}>
                <ListItemText primary="System" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton key="drawer-PalletUsers" sx={{ pl: 4 }}  onClick={handleSysUsersClick}>
                        <ListItemText primary="Users" />
                    </ListItemButton>                    
                </List>
            </Collapse>

            </>
        )
    }

    return (
        <>
        <Button
            key='but-PalletSystem'
            onClick={handleOpenSystemMenu}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
            endIcon={<ArrowDropDownIcon />}
        >
            System
        </Button>
        <Menu
            id="menu-PalletSystem"
            anchorEl={anchorElSystem}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElSystem)}
            onClose={handleCloseSystemMenu}
            sx={{
                display: { xs: 'none', md: 'block' },
            }}
        >
            <MenuItem key='menu-PalletUsers' onClick={handleSysUsersClick}>
                <Typography textAlign="center">Users</Typography>
            </MenuItem>            
        </Menu>
        </>
    );
}



