import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';

export default function PickupRequestAdminLoadsList(props){
    //console.debug("RTS Break PickupRequestAdminLoadsList")
    const history = useNavigate();

    function handleClick(e, cellValues){
        props.removeLoadFx(cellValues.row);
    }

    const columns = [
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:2,
            renderCell: (cellValues) => {
                return cellValues.row.reel.serialNumber;
            },
        },        
        // {
        //     field: 'productName', 
        //     headerName: "Product", 
        //     description:"Southwire Product Id", 
        //     flex:3,
        //     renderCell: (cellValues) => {
        //         return cellValues.row.product.name;
        //     },
 
        // },
        // {
        //     field: 'shipDate', 
        //     headerName: "Purchase Date", 
        //     description:"Reel Ship Date", 
        //     flex:2,           
        //     valueFormatter: (parms) => {
        //         if(parms.value == null){
        //             return '';
        //         }

        //         return (
        //             format(parseISO(parms.value), "MM-dd-yyyy")
        //         );
        //     }, 
        // },
        // {
        //     field: '',
        //     description:"Remove Reel", 
        //     flex:1,
        //     maxWidth:60,
        //     renderCell: (cellValues) => {
        //         if(cellValues.row.pickupRequestDate==null){
        //             return (
                        
        //                 <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
        //                     <Box sx={{display:'flex'}}>
        //                         <ClearIcon sx={{color:"red"}} />
        //                     </Box>
        //                 </Link>
        //             );
        //         }
        //         else{
        //             return (
        //                 ""
        //             )
        //         }
        //     },
        //     align:"center",
        //     sortable:false,
        //     disableColumnMenu:true
        // },        
    ];

    return (
        <DataGridPro autoHeight rows={props.data} columns={columns} getRowId={row => row.idGuid} />
    );
}
