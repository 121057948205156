import axios from 'axios';
import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useSessionContext } from '../../contexts/SessionContext';

import LoadSetLocationMap from './LoadSetLocationMap';

export default function LoadSetLocationSet( props ) {
    //console.debug('LoadSetLocationSet');

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [prevLat] = useState(props.data.currentLocation!=null?props.data.currentLocation.lat:"33.5666694");
    const [prevLng] = useState(props.data.currentLocation!=null?props.data.currentLocation.lng:"-85.0716746");
    const [newLat, setNewLat] = useState('');
    const [newLng, setNewLng] = useState('');

    function setCoords(coords){
        setNewLat(coords.lat);
        setNewLng(coords.lng);
    }


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            Load: props.data,
            Lat: newLat,
            Lng: newLng,
            modifyUser: user.userName,
            createUser: user.userName
        }


        if(apiObj.Lat === null){
//            setErrorBody("Latitude is required");
//            setShowErrorModal(true);
            return;
        }        

        if(apiObj.Lng === null){
            // setErrorBody("Longitude is required");
            // setShowErrorModal(true);
            return;
        }        


        let apiURL = apiRoot + "/LoadLocations/CreateLoadLocation";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.dataRefreshFx();            
            props.modeFx(0);
        })
        .catch(error => {
//            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateCustomerReel Error", error);
//            setShowErrorModal(true);
        });
    } 



    return(
        <Box>
            <form id="SelLocationForm" onSubmit={handleSubmit}>
                <Box sx={{textAlign:'center'}}>
                    <Typography variant="h6" sx={{display:'inline'}}>Current Location</Typography>
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Stack direction="row" spacing={3}>
                            <Box sx={{display:'flex', alignItems:'center', justifyItems:'center'}}>
                                <Box sx={{display:'flex', alignItems:'center', justifyItems:'center'}} className="rtsMarkerSetPrevious">
                                    <Typography>C</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <Typography>Latitude</Typography>
                                </Box>
                                <Box>
                                    <Typography>{prevLat}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <Typography>Longitude</Typography>
                                </Box>
                                <Box>
                                    <Typography>{prevLng}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                </Box>


                <Box sx={{textAlign:'center', pt:2}}>
                    <Typography variant="h6" sx={{mb:2}}>Enter the Latitude and Longitude or click the new location on the map</Typography>
                    <Typography variant="h6" sx={{display:'inline'}}>New Location</Typography>
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Stack direction="row" spacing={3}>
                            <Box sx={{display:'flex', alignItems:'center', justifyItems:'center', pt:1}}>
                                <Box sx={{display:'flex', alignItems:'center', justifyItems:'center'}} className="rtsMarkerSetNew">
                                    <Typography>N</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <TextField
                                    id="tbLatitude"
                                    margin="normal"
                                    label="Latitude"
                                    type="text"
                                    size="small"
                                    value={newLat}
                                    onChange={(e) => {props.setNewLat(e.target.value);}}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    id="tbLongitude"
                                    margin="normal"
                                    label="Longitude"
                                    type="text"
                                    size="small"
                                    value={newLng}
                                    onChange={(e) => {props.setNewLng(e.target.value);}}
                                />
                            </Box>
                            <Box sx={{display:'flex', alignItems:'center', justifyItems:'center', pt:1}}>
                                <Button variant="contained" type="submit">Set</Button>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </form>
            <LoadSetLocationMap data={props.data} user={props.user} modeFx={props.modeFx} dataRefreshFx={props.dataRefreshFx} prevLat={prevLat} prevLng={prevLng} newLat={newLat} newLng={newLng} setCoordsFx={setCoords} />
        </Box>
    );
}
