import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';

import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error'; 


export default function PickupRequestLoadsList(props){
    //console.debug("RTS Break PickupRequestLoadsList")

    function handleClick(e, cellValues){
        props.removeLoadFx(cellValues.row);
    }

    const columns = [
        {
            field: "Error",
            headerName: "",
            description:"Error", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                if(cellValues.row.valError){
                    return (
                        <Tooltip title={cellValues.row.valError}>
                            <Box sx={{display:'flex'}}>
                                <ErrorIcon sx={{color:"red"}} />
                            </Box>
                        </Tooltip>
                    );
                }
                else{
                    return (
                        ""
                    )
                }
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:2,
            renderCell: (cellValues) => {
                return cellValues.row.reel.serialNumber;
            },
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Southwire Product Id", 
            flex:3,
            renderCell: (cellValues) => {
                return cellValues.row.product.name;
            },
 
        },
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Reel Ship Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },
        {
            field: '',
            description:"Remove Reel", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                if(cellValues.row.pickupRequestDate==null){
                    return (
                        
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <Box sx={{display:'flex'}}>
                                <ClearIcon sx={{color:"red"}} />
                            </Box>
                        </Link>
                    );
                }
                else{
                    return (
                        ""
                    )
                }
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },        
    ];

    return (
        <DataGridPro 
            autoHeight 
            rows={props.data} 
            columns={columns} 
            getRowId={row => row.idGuid} 
        />
    );
}
