import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

import EditIcon from '@mui/icons-material/Edit';

import { useSessionContext } from '../../contexts/SessionContext';

export default function SessionCustomersList(props){
    //console.debug("RTS Break SessionCustomerList");

    const {setSessionCustomer, setPrevSessionCustomer, saveSession} = useSessionContext();

    function SessionCustomerToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <Box sx={{width:"50%"}}>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                </Box>
                <Box sx={{width:"50%", display:"flex", justifyContent:"right"}}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    }


    function handleClick(e, cellValues){
        setSessionCustomer(cellValues.row);
        setPrevSessionCustomer(cellValues.row);
    }

    const columns = [
        {
            field: '',
            description:"Select Customer", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.name;
            }             
        },
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Customer Id", 
            flex:2,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.idSap;
            }
        },
        {
            field: 'mailCity', 
            headerName: "City", 
            description:"Customer City", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.mailCity;
            }
        },
        {
            field: 'mailRegion', 
            headerName: "State", 
            description:"Customer State", 
            flex:2,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.mailRegion;
            }
        }
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: SessionCustomerToolbar }}
                componentsProps={{toolbar:{showQuickFilter:true}}}
                options={{filtering:true}} 
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.customer.idGuid} 
            />
        </Box>
    );
}
