import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

let pwReset = params?.pwr;
let pwUser = params?.pwu;
let pwToken = params?.pwt;

root.render(<App pwReset={pwReset} pwUser={pwUser} pwToken={pwToken} />);

