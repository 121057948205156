import {useState} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

export default function PickupRequestsEditMarkedLoadsList(props){
    //console.debug("RTS Break PickupRequestsEditMarkedLoadsList");
    
    function handleClick(e, cellValues){
        let selectedRowData = props.data.filter((row) => selectionModel.includes(row.idGuid.toString()));
        props.addLoadsFx(selectedRowData);
    }

    function CustomToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }    

    const [selectionModel, setSelectionModel] = useState([]);

    const columns = [
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:1,
            renderCell: (cellValues) => {
                return cellValues.row.reel.serialNumber;
            },
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Southwire Product Id", 
            flex:3,
            renderCell: (cellValues) => {
                return cellValues.row.product.name;
            },
 
        },
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Reel Ship Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },
        {
            field: 'project', 
            headerName: "Project", 
            description:"Project", 
            flex:2,
            valueGetter: (cellValues) => {
                return cellValues.row.customerProject !== null?cellValues.row.customerProject.name:null;
            },             
        }, 
        {
            field: 'location', 
            headerName: "Location", 
            description:"Named Location", 
            flex:2,
            valueGetter: (cellValues) => {
                return cellValues.row.currentLocation !== null?cellValues.row.currentLocation.namedLocation:null;
            },             
        },               

    ];

    return (
        <Box>
            <Box sx={{height:"400px"}}>
                <DataGridPro 
                    checkboxSelection={true} 
                    pagination 
                    pageSize={50}             
                    rows={props.data} 
                    columns={columns} 
                    getRowId={row => row.idGuid}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel} 
                    components={{Toolbar: CustomToolbar}}            
                />
            </Box>
            <Box sx={{mt:2, textAlign:'right'}}>
                <Button variant="contained" onClick={handleClick} sx={{mr:2}}>Add Reels</Button>
                <Button variant="contained" onClick={e => {props.setShowLoadsModalFx(false);}}>Cancel</Button>
            </Box>
        </Box>
    );
}
