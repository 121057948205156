import {useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import LoginPage from './LoginPage';
import AppModePage from './AppModePage';
import SessionCustomersPage from './SessionCustomersPage';
import NavBar from '../components/Nav/NavBar';

import DashboardPage from './DashboardPage';
import LoadListPage from './LoadListPage';
import LocatorPage from './LocatorPage';
import AnalyticsPage from './AnalyticsPage';
import CustomerProjectsPage from './CustomerProjectsPage';
import CustomerProjectsPageEdit from './CustomerProjectsPageEdit';
import ProductAliasPage from './ProductAliasPage';
import ProductAliasPageEdit from './ProductAliasPageEdit';
import CrewMembersPage from './CrewMembersPage';
import CrewMembersPageEdit from './CrewMembersPageEdit';
import PickupRequestsPage from './PickupRequestsPage';
import PickupRequestsPageEdit from './PickupRequestsPageEdit';
import UserRequestsPage from './UserRequestsPage';
import UserRequestsPageEdit from './UserRequestsPageEdit';
import NamedLocationsPage from './NamedLocationsPage';
import NamedLocationsPageEdit from './NamedLocationsPageEdit';
import AdminCustomerPageEdit from './AdminCustomerPageEdit';


import LoadsShippedLast30DaysPage from './analytics/LoadsShippedLast30DaysPage';
import LoadsReturnedLast30DaysPage from './analytics/LoadsReturnedLast30DaysPage';
import LoadsDueDetailPage from './analytics/LoadsDueDetailPage';
import LoadsAwaitingReturnPage from './analytics/LoadsAwaitingReturnPage';
import LoadsBatteryLevelPage from './analytics/LoadsBatteryLevelPage';
import LoadsProductRemainsDetailPage from './analytics/LoadsProductRemainsDetailPage';

import UsersPage from './system/UsersPage';
import UsersPageEdit from './system/UsersPageEdit';
import CustomersPage from './system/CustomersPage';
import CustomersPageEdit from './system/CustomersPageEdit';
import ReelTypesPage from './system/ReelTypesPage';
import ReelTypesPageEdit from './system/ReelTypesPageEdit';
import TrackersPage from './system/TrackersPage';
import TrackersPageEdit from './system/TrackersPageEdit';
import ProductsPage from './system/ProductsPage';
import ProductsPageEdit from './system/ProductsPageEdit';
import ReelsPage from './system/ReelsPage';
import ReelsPageEdit from './system/ReelsPageEdit';
import LoadsPage from './system/LoadsPage';
import LoadsPageEdit from './system/LoadsPageEdit';
import CustomerReelsPage from './system/CustomerReelsPage';
import CustomerReelsPageEdit from './system/CustomerReelsPageEdit';
import SystemNamedLocationsPage from './system/SystemNamedLocationsPage';
import SystemNamedLocationsPageEdit from './system/SystemNamedLocationsPageEdit';
import PickupRequestsAdminPage from './system/PickupRequestsAdminPage';
import PickupRequestsAdminPageEdit from './system/PickupRequestsAdminPageEdit';
import ReelWipPage from './system/ReelWipPage';
import QRScanPage from './QRScanPage';

import ReelInspectionsPage from './ReelInspectionsPage';
import ReelInspectionsPageEdit from './ReelInspectionsPageEdit';


import WorkSetsPage from './workrequest/WorkSetsPage';
import WorkSetsDeletePage from './workrequest/WorkSetsDeletePage';
import WorkItemsPage from './workrequest/WorkItemsPage';
import WorkItemsEditPage from './workrequest/WorkItemsEditPage';
import WorkItemsDeletePage from './workrequest/WorkItemsDeletePage';

import PalletDashboardPage from './pallets/PalletDashboardPage';
import PalletsPage from './pallets/PalletsPage';
import PalletsPageEdit from './pallets/PalletsPageEdit';
import PalletPlantsPage from './pallets/PalletPlantsPage';
import PalletPlantsPageEdit from './pallets/PalletPlantsPageEdit';
import PalletActionPage from './pallets/PalletActionPage';
import PalletTurnsPage from './pallets/PalletTurnsPage';

import ManuReelsPage from './manufacturing/ManuReelsPage';
import ManuReelsPageEdit from './manufacturing/ManuReelsPageEdit';



import { useSessionContext } from '../contexts/SessionContext';

export default function Main( props ) {
  //console.debug("RTS Break Main");

  const [isModal, setIsModal] = useState(false);
  const {user, appMode, userAuthorized, sessionCustomer} = useSessionContext();

  if(!userAuthorized){
    return(<LoginPage 
              pwr={props.pwr==='1'?true:false}
              pwu={props.pwu===null?'':props.pwu} 
              pwt={props.pwt===null?'':props.pwt} 
            />);
  }


  if(appMode===null){
    return(
      <AppModePage />
    )    
  }


  if(sessionCustomer===null){
    return(
      <>
      <NavBar isModal={true} />
      <Toolbar id="sessionToolbar"></Toolbar>
      <Box id="mainBox" sx={{pt:2}}>
        <SessionCustomersPage />
      </Box>
      </>
    )
  }


  if(appMode==='REELS'){
    return(
      <>
      <NavBar isModal={isModal} />
      <Toolbar id="mainToolbar"></Toolbar>
      <Box id="mainBox" sx={{pt:2}}>
        <Routes>
          <Route path='/Dashboard' element={<DashboardPage filter={{customers:[sessionCustomer.customer]}} setIsModalFx={setIsModal}/>}/>
          <Route path='/LoadList' element={<LoadListPage setIsModalFx={setIsModal}/>}/>
          <Route path='/Locator' element={<LocatorPage setIsModalFx={setIsModal}/>}/>
          <Route path='/Analytics' element={<AnalyticsPage setIsModalFx={setIsModal}/>}/> 
          <Route path='/Users' element={<UsersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/UsersEdit' element={<UsersPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/Customers' element={<CustomersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/CustomersEdit' element={<CustomersPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/AdminCustomerEdit' element={<AdminCustomerPageEdit setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelTypes' element={<ReelTypesPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelTypesEdit' element={<ReelTypesPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/Trackers' element={<TrackersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/TrackersEdit' element={<TrackersPageEdit  setIsModalFx={setIsModal}/>}/>            
          <Route path='/Products' element={<ProductsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ProductsEdit' element={<ProductsPageEdit  setIsModalFx={setIsModal}/>}/>  
          <Route path='/ProductAlias' element={<ProductAliasPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ProductAliasEdit' element={<ProductAliasPageEdit  setIsModalFx={setIsModal}/>}/>  
          <Route path='/Reels' element={<ReelsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelsEdit' element={<ReelsPageEdit  setIsModalFx={setIsModal}/>}/>                           
          <Route path='/Loads' element={<LoadsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/LoadsEdit' element={<LoadsPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/CustomerProjects' element={<CustomerProjectsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/CustomerProjectsEdit' element={<CustomerProjectsPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/CustomerReels' element={<CustomerReelsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/CustomerReelsEdit' element={<CustomerReelsPageEdit  setIsModalFx={setIsModal}/>}/>             
          <Route path='/CrewMembers' element={<CrewMembersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/CrewMembersEdit' element={<CrewMembersPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/PickupRequests' element={<PickupRequestsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/PickupRequestsEdit' element={<PickupRequestsPageEdit setIsModalFx={setIsModal}/>}/>
          <Route path='/SystemNamedLocations' element={<SystemNamedLocationsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/SystemNamedLocationsEdit' element={<SystemNamedLocationsPageEdit  setIsModalFx={setIsModal}/>}/>                           
          <Route path='/PickupRequestsAdmin' element={<PickupRequestsAdminPage setIsModalFx={setIsModal}/>}/>
          <Route path='/PickupRequestsAdminEdit' element={<PickupRequestsAdminPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelWip' element={<ReelWipPage  setIsModalFx={setIsModal}/>}/>
          <Route path='/UserRequests' element={<UserRequestsPage  setIsModalFx={setIsModal}/>}/>
          <Route path='/UserRequestsEdit' element={<UserRequestsPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/QRScanPage' element={<QRScanPage  setIsModalFx={setIsModal}/>}/>
          <Route path='/LoadsShippedLast30DaysPage' element={<LoadsShippedLast30DaysPage setIsModalFx={setIsModal}/>}/>
          <Route path='/LoadsReturnedLast30DaysPage' element={<LoadsReturnedLast30DaysPage setIsModalFx={setIsModal}/>}/>
          <Route path='/LoadsDueDetailPage' element={<LoadsDueDetailPage setIsModalFx={setIsModal}/>}/>
          <Route path='/LoadsAwaitingReturnPage' element={<LoadsAwaitingReturnPage setIsModalFx={setIsModal}/>}/>
          <Route path='/LoadsBatteryLevelPage' element={<LoadsBatteryLevelPage setIsModalFx={setIsModal}/>}/>
          <Route path='/LoadsProductRemainsDetailPage' element={<LoadsProductRemainsDetailPage setIsModalFx={setIsModal}/>}/>
          <Route path='/NamedLocations' element={<NamedLocationsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/NamedLocationsEdit' element={<NamedLocationsPageEdit  setIsModalFx={setIsModal}/>}/>                           
          <Route path='/ReelInspectionsPage' element={<ReelInspectionsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelInsepctionsPageEdit' element={<ReelInspectionsPageEdit  setIsModalFx={setIsModal}/>}/>                           

          <Route path='*' replace={true} element={<DashboardPage UserAuthFx={props.UserAuthFx} filter={{customers:[sessionCustomer.customer]}} setIsModalFx={setIsModal}/>}/>
        </Routes>
      </Box>
      </>
    );
  }


  if(appMode==='WORK'){
    return(
      <>
      <NavBar isModal={isModal} />
      <Toolbar id="mainToolbar"></Toolbar>
      <Box id="mainBox" sx={{pt:2}}>
        <Routes>
          <Route path='/WorkSets' element={<WorkSetsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/WorkSetsDelete' element={<WorkSetsDeletePage setIsModalFx={setIsModal}/>}/>
          <Route path='/WorkItems' element={<WorkItemsPage  setIsModalFx={setIsModal}/>}/>
          <Route path='/WorkItemsEdit' element={<WorkItemsEditPage setIsModalFx={setIsModal}/>}/>
          <Route path='/WorkItemsDelete' element={<WorkItemsDeletePage setIsModalFx={setIsModal}/>}/>
          <Route path='/Users' element={<UsersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/UsersEdit' element={<UsersPageEdit  setIsModalFx={setIsModal}/>}/>

          <Route path='*' replace={true} element={<WorkSetsPage UserAuthFx={props.UserAuthFx} filter={{customers:[sessionCustomer.customer]}} setIsModalFx={setIsModal}/>}/>
        </Routes>
      </Box>
      </>
    );
  }


  if(appMode==='PALLETS'){
    return(
      <>
      <NavBar isModal={isModal} />
      <Toolbar id="mainToolbar"></Toolbar>
      <Box id="mainBox" sx={{pt:2}}>
        <Routes>
          <Route path='/PalletDashboard' element={<PalletDashboardPage setIsModalFx={setIsModal}/>}/>
          <Route path='/PalletTurns' element={<PalletTurnsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/Pallets' element={<PalletsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/PalletsEdit' element={<PalletsPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/PalletPlants' element={<PalletPlantsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/PalletPlantsEdit' element={<PalletPlantsPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/PalletAction' element={<PalletActionPage setIsModalFx={setIsModal}/>}/>
          <Route path='/Users' element={<UsersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/UsersEdit' element={<UsersPageEdit  setIsModalFx={setIsModal}/>}/>

          <Route path='*' replace={true} element={<PalletDashboardPage UserAuthFx={props.UserAuthFx} filter={{customers:[sessionCustomer.customer]}} setIsModalFx={setIsModal}/>}/>
        </Routes>
      </Box>
      </>
    );
  }


  if(appMode==='MANUFACTURING'){
    return(
      <>
      <NavBar isModal={isModal} />
      <Toolbar id="mainToolbar"></Toolbar>
      <Box id="mainBox" sx={{pt:2}}>
        <Routes>
          <Route path='/ManuReelsPage' element={<ManuReelsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ManuReelsPageEdit' element={<ManuReelsPageEdit  setIsModalFx={setIsModal}/>}/>                           
          <Route path='/Users' element={<UsersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/UsersEdit' element={<UsersPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelTypes' element={<ReelTypesPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelTypesEdit' element={<ReelTypesPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/Trackers' element={<TrackersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/TrackersEdit' element={<TrackersPageEdit  setIsModalFx={setIsModal}/>}/>            
          <Route path='/Reels' element={<ReelsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelsEdit' element={<ReelsPageEdit  setIsModalFx={setIsModal}/>}/>                           
          <Route path='/PickupRequestsAdmin' element={<PickupRequestsAdminPage setIsModalFx={setIsModal}/>}/>
          <Route path='/PickupRequestsAdminEdit' element={<PickupRequestsAdminPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelInspectionsPage' element={<ReelInspectionsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelInsepctionsPageEdit' element={<ReelInspectionsPageEdit  setIsModalFx={setIsModal}/>}/>                           

          <Route path='*' replace={true} element={<ManuReelsPage UserAuthFx={props.UserAuthFx} filter={{customers:[sessionCustomer.customer]}} setIsModalFx={setIsModal}/>}/>
        </Routes>
      </Box>
      </>
    );
  }  


  if(appMode==='REELINSPECTIONS'){
    return(
      <>
      <NavBar isModal={isModal} />
      <Toolbar id="mainToolbar"></Toolbar>
      <Box id="mainBox" sx={{pt:2}}>
        <Routes>
          <Route path='/ReelInspectionsPage' element={<ReelInspectionsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelInsepctionsPageEdit' element={<ReelInspectionsPageEdit  setIsModalFx={setIsModal}/>}/>                           
          <Route path='/Users' element={<UsersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/UsersEdit' element={<UsersPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelTypes' element={<ReelTypesPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelTypesEdit' element={<ReelTypesPageEdit  setIsModalFx={setIsModal}/>}/>
          <Route path='/Trackers' element={<TrackersPage setIsModalFx={setIsModal}/>}/>
          <Route path='/TrackersEdit' element={<TrackersPageEdit  setIsModalFx={setIsModal}/>}/>            
          <Route path='/Reels' element={<ReelsPage setIsModalFx={setIsModal}/>}/>
          <Route path='/ReelsEdit' element={<ReelsPageEdit  setIsModalFx={setIsModal}/>}/>                           
          <Route path='/PickupRequestsAdmin' element={<PickupRequestsAdminPage setIsModalFx={setIsModal}/>}/>
          <Route path='/PickupRequestsAdminEdit' element={<PickupRequestsAdminPageEdit  setIsModalFx={setIsModal}/>}/>

          <Route path='*' replace={true} element={<ReelInspectionsPage UserAuthFx={props.UserAuthFx} setIsModalFx={setIsModal}/>}/>
        </Routes>
      </Box>
      </>
    );
  }    

}
