import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import EditIcon from '@mui/icons-material/Edit'; 

export default function ReelTypesList(props){
    //console.debug("RTS Break ReelTypesList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/ReelTypesEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit Reel Type", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Reel Type", 
            description:"Reel Type Name", 
            flex:3
        },
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Reel Type Id", 
            flex:2
        },        
        {
            field: 'weightCapacity', 
            headerName: "Capacity (lbs)", 
            description:"Weight Capacity", 
            flex:2,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    parms.value.toLocaleString(undefined, { maximumFractionDigits: 2 })   
                )
            },
            align:'right'
        },
        {
            field: 'approxWeight', 
            headerName: "Weight (lbs)", 
            description:"Reel Weight", 
            flex:2,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    parms.value.toLocaleString(undefined, { maximumFractionDigits: 2 })   
                )
            },
            align:'right'
        }

    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
