import {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import FilterListIcon from '@mui/icons-material/FilterList';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import FilterCustomerList from '../FilterCustomerList';
import LoadListFilterOrderList from './LoadListFilterOrderList';
import LoadListFilterProductList from './LoadListFilterProductList';
import LoadListFilterReelList from './LoadListFilterReelList';
import LoadListFilterReelTypeList from './LoadListFilterReelTypeList';
import LoadListFilterProjectList from './LoadListFilterProjectList';
import LoadListFilterStatusList from './LoadListFilterStatusList';
import LoadListFilterSortByList from './LoadListFilterSortByList';
import LoadListFilterNamedLocationList from './LoadListFilterNamedLocationList';

import { useSessionContext } from '../../contexts/SessionContext';


export default function LoadListFilter(props) {
    //console.debug("RTS Break LoadListFilter");

    const history = useNavigate();

    const {user, sessionCustomer} = useSessionContext();

    const filterData = props.filterData;
    const [customers, setCustomers] = useState([sessionCustomer.customer]);
    const [orders, setOrders] = useState(props.filter===null?[]:props.filter.orders);
    const [products, setProducts] = useState(props.filter===null?[]:props.filter.products);
    const [reelTypes, setReelTypes] = useState(props.filter===null?[]:props.filter.reelTypes);
    const [reels, setReels] = useState(props.filter===null?[]:props.filter.reels);
    const [projects, setProjects] = useState(props.filter===null?[]:props.filter.projects);
    const [status, setStatus] = useState(props.filter===null?[]:props.filter.status);
    const [minLength, setMinLength] = useState(props.filter===null?0:props.filter.minLength);
    const [locations, setLocations] = useState(props.filter===null?[]:props.filter.namedLocations);
    const [pinned, setPinned] = useState(props.filter===null?-1:props.filter.pinned);
    const [locked, setLocked] = useState(props.filter===null?-1:props.filter.locked);
    const [sortBy, setSortBy] = useState(props.fitler===null?0:props.filter.sortBy);

    const [showFilter, setShowFilter] = useState(true);
    const [showMore, setShowMore] = useState(true);

    function setParentFilter(e){
        let args = {"customers":[sessionCustomer.customer],
                    "orders":orders, 
                    "projects":projects, 
                    "products":products, 
                    "reelTypes":reelTypes,
                    "reels":reels,
                    "status":status,
                    "minLength":minLength===""?0:minLength,
                    "namedLocations":locations,
                    "pinned":pinned,
                    "locked":locked,
                    "sortBy":sortBy
                }
        props.filterFx(args);
    }


    function handleScanClick(){
        history("/QRScanPage", {state:{data:props.data}});
    }    


    function toggleShowFilter()
    {
        setShowFilter(!showFilter);
    }


    function toggleShowMore()
    {
        setShowMore(!showMore);
    }

    
    return (
        <Box sx={{pt:1}}>
            <Box sx={{visibility:showFilter===true?"visible":"hidden", display:showFilter===true?"initial":"none"}}>
                <Grid container id="gridContainer">
                    {/* {user.appUserCustomers.length > 1
                    ?
                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <FilterCustomerList customers={customers} setCustomersFx={setCustomers} />
                    </Grid>
                    :
                    ""
                    } */}
                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterOrderList orders={orders} data={props.customerFilters.orders!==null?props.customerFilters.orders:[]} setOrdersFx={setOrders} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterProductList products={products} data={props.customerFilters.products!==null?props.customerFilters.products:[]} setProductsFx={setProducts} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterProjectList projects={projects} data={props.customerFilters.projects!==null?props.customerFilters.projects:[]} setProjectsFx={setProjects} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterReelTypeList reelTypes={reelTypes} data={props.customerFilters.reelTypes!==null?props.customerFilters.reelTypes:[]} setReelTypesFx={setReelTypes} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterReelList reels={reels} data={props.customerFilters.reels!==null?props.customerFilters.reels:[]} setReelsFx={setReels} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LoadListFilterStatusList status={status} data={props.data} setStatusFx={setStatus} />
                    </Grid>


                    <Grid item xs={12} sx={{px:1, pt:1}}>
                        <Grid container>
                            <Box onClick={toggleShowMore}>
                                <Button variant="text" size="small">{showMore?'+ More':'- Less'}</Button>
                            </Box>                
                        </Grid>
                    </Grid>                        

                    <Grid item xs={12} sx={{px:1, pt:1, visibility: showMore===true?"hidden":"visible", display:showMore===true?"none":"initial"}}>
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                                <TextField
                                    id="tbMinLength"
                                    margin="none"
                                    fullWidth
                                    label="Minimum Length Remaining (ft)"
                                    type="number"
                                    size="small"
                                    value={minLength}
                                    onChange={(e) => {setMinLength(e.target.value);}}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                                <LoadListFilterNamedLocationList locations={locations}  data={props.customerFilters.namedLocations!==null?props.customerFilters.namedLocations:[]} setLocationsFx={setLocations} />
                            </Grid>    

                            <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                                <LoadListFilterSortByList sortBy={sortBy} setSortByFx={setSortBy} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{px:1, pt:1}}>
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={6} sx={{textAlign:{xs:'center', sm:'left'}}}>
                                <Button type="button" variant="contained" onClick={setParentFilter} sx={{mt:1}} >
                                    <FilterListIcon />
                                    <Typography sx={{ml:1}}>Filter</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} sx={{textAlign:{xs:'center', sm:'right'}}}>
                                <Button type="button" variant="contained" onClick={handleScanClick} sx={{mt:1}} >
                                    <QrCodeScannerIcon />
                                    <Typography sx={{ml:1}}>Scan</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>                        
                </Grid>
                <Box onClick={toggleShowFilter}>
                    <Button variant="text" startIcon={<ArrowCircleUpOutlinedIcon />} size="small">Hide Filters</Button>
                </Box>                
            </Box>
            <Box sx={{visibility: showFilter===true?"hidden":"visible", display:showFilter===true?"none":"initial"}}>
                <Box onClick={toggleShowFilter}>
                    <Button variant="text" startIcon={<ArrowCircleDownIcon />} size="small">Show Filters</Button>
                </Box>
            </Box>                     
        </Box>
    );
}
