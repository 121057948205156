import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import EditIcon from '@mui/icons-material/Edit'; 

export default function PickupRequestsList(props){
    //console.debug("RTS Break PickupRequestsList");
    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/PickupRequestsEdit", {state:{data:cellValues.row, mode:1}});
    }


    const columns = [
        {
            field: "Edit",
            headerName: "",
            description:"Edit Project", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Name", 
            description:"Pickup Request Name", 
            flex:4    
        },
        {
            field: 'loadCount', 
            headerName: "# Reels", 
            description:"Number of Reels", 
            flex:1    
        },
        {
            field: 'createDate', 
            headerName: "Create Date", 
            description:"Create Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },
        {
            field: 'submitDate', 
            headerName: "Submit Date", 
            description:"submit Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        }        
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
