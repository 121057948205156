import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadProjectEditProjectList(props) {
    //console.debug("RTS Break LoadProjectEditProjectList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [project, setProject] = useState(props.project);
    const [repoProjects, setRepoProjects] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    function fetchData(){
        let locFilter = {"user":user,
                        "customer":sessionCustomer.customer, 
                        "sortBy":0 
                        }

        let apiURL = apiRoot + "/CustomerProjects/GetProjectsByCustomer";
        axios
        .post(
            apiURL,
            locFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setRepoProjects(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }


    useEffect(() => {
        fetchData();
    }, []); 

    
    function valChange(e, val){
        props.setProjectFx(val);
        setProject(val);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoProjects}
                renderInput={(params) => <TextField {...params} label="Project" margin="normal" size="small" />}
                value={project}
                onChange={valChange}
                getOptionLabel={(repoValue) => repoValue!=''?repoValue.name:''}
                renderOption={(props, repoProjects) => (
                    <Box component="li" {...props} key={repoProjects.idGuid}>
                        {repoProjects.name}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.idGuid === value.idGuid}
            />
        </>
    );
}

