import { BrowserRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Main from './pages/Main';
import { SessionContextProvider } from './contexts/SessionContext';

import './index.css';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  'af4c09c21650f32c526c6c6754bb2636T1JERVI6NDE2MjQsRVhQSVJZPTE2ODEzMzc5NDAxNTUsS0VZVkVSU0lPTj0x'
);


const theme = createTheme({
  palette: {
    //mode:'dark',
    // primary: {
    //   main: '#96694c'
    // }
  },
  overrides: {
    MuiButton:{
      minWidth:'150px'
    }
  }
});


export default function App(props) {

  const pwr = props.pwReset===null?0:props.pwReset;
  const pwu = props.pwUser===null?'':props.pwUser;
  const pwt = props.pwToken===null?'':props.pwToken;

  return (
    <ThemeProvider theme={theme}>
      <SessionContextProvider>
        <BrowserRouter>
          <Box id="appBox">
            <Main pwr={pwr} pwu={pwu} pwt={pwt} />
          </Box>
        </BrowserRouter>
      </SessionContextProvider>      
    </ThemeProvider>
  );  
}
