import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { format, parseISO } from 'date-fns';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LocatorListItemReel from "./LocatorListItemReel";
import LocatorListItemCurLocation from './LocatorListItemCurLocation';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LocatorListItem(props){
    //console.debug("RTS Break LocatorListItem");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const[helpProduct, setHelpProduct] = useState(false);
    const[helpReel, setHelpReel] = useState(false);
    const[helpActions, setHelpActions] = useState(false);

    const styleHeaders = {
        textAlign: 'center',
        borderBottom: '2px solid #1976d2'
    }


    return (
        <Paper elevation={4} sx={{p:2}} id="LocatorItemPaper">
            {
                props.user.appUserCustomers.length>1
                    ?
                    <Box>
                        <Typography variant="h6" sx={{fontWeight:"bold", fontSize:"1em"}}>{props.data.assignedToCustomer.name}</Typography>
                    </Box>
                    :
                    ""
            }
            {
                props.data.status===25
                    ?
                    <Box>
                        <Typography variant="subtitle2" sx={{color:'red'}}>Marked for Pickup on {format(parseISO(props.data.markDate), "MM-dd-yyyy")}</Typography>
                    </Box>
                    :
                    ""
            }
            {
                props.data.status===50
                    ?
                    <Box>
                        <Typography variant="subtitle2" sx={{color:'red'}}>Marked for Pickup on {format(parseISO(props.data.markDate), "MM-dd-yyyy")} Listed on Pickup Request</Typography>
                    </Box>
                    :
                    ""
            }            
            {
                props.data.status===75
                    ?
                    <Box>
                        <Typography variant="subtitle2" sx={{color:'red'}}>Submitted for Pickup on {format(parseISO(props.data.pickupRequestDate), "MM-dd-yyyy")}</Typography>
                    </Box>
                    :
                    ""
            }
            {
                props.data.status===100
                    ?
                    <Box>
                        <Typography variant="subtitle2" sx={{color:'red'}}>Returned on {format(parseISO(props.data.returnedDate), "MM-dd-yyyy")}</Typography>
                    </Box>
                    :
                    ""
            }            
            <Grid container spacing={2} id="mainGrid">
                <Grid item xs={12} sm={8}>
                    <Stack>
                        <Box sx={{...styleHeaders, display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Typography variant="h6">Product</Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Stack>
                                    {props.data.product.productAlias !== null && props.data.product.productAlias.length > 0
                                    ?
                                    <>
                                        <Box>
                                            <Tooltip title={props.data.product.idSap}>
                                                <Typography variant="h6" >
                                                    {props.data.product.productAlias[0].alias}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                        <Box>
                                            <Typography variant="caption" >
                                                {props.data.customerMaterialNo!==null?props.data.customerMaterialNo:props.data.product.productAlias[0].customerMaterialNo!==null?props.data.product.productAlias[0].customerMaterialNo:''}
                                            </Typography>
                                        </Box>
                                        {props.hideLinks==true
                                        ?
                                        <Typography variant="caption" >
                                            {props.data.product.name}
                                        </Typography>
                                        :
                                        ""
                                        }
                                    </>
                                    :
                                    <>
                                    <Tooltip title={props.data.product.idSap}>
                                        <Typography variant="h6" >
                                            {props.data.product.name}
                                        </Typography>
                                    </Tooltip>
                                    <Typography variant="caption" >
                                        {props.data.customerMaterialNo}
                                    </Typography>
                                    </>                                    
                                    }
                                </Stack>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Stack>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Purchased:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.amt.toLocaleString()}</Typography>
                                        <Typography variant="body2" sx={{ml:.5}}>ft</Typography>
                                        {props.data.uom === "lb"
                                        ?
                                        <Typography variant="caption" sx={{ml:2}}>(calc)</Typography>
                                        :
                                        ""
                                        }
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Remaining:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{(props.data.amt-props.data.pullSum).toLocaleString()}</Typography>
                                        <Typography variant="body2" sx={{ml:.5}}>ft</Typography>
                                        {props.data.uom === "lb"
                                        ?
                                        <Typography variant="caption" sx={{ml:2}}>(calc)</Typography>
                                        :
                                        ""
                                        }
                                    </Box>
                                    <LocatorListItemCurLocation  data={props.data}  childIdx={props.childIdx} openChildFx={props.openChildFx} />
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Ship Date:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{format(parseISO(props.data.shipDate), 'MM-dd-yyyy')}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Age:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{(props.data.shipDateDays).toLocaleString()}</Typography>
                                        <Typography variant="body2" sx={{ml:.5}}>Days</Typography>
                                    </Box>

                                </Stack>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Stack>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Purchase Order:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.customerPo==null?"No Order":props.data.customerPo}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Shipping Order:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.idSapOrder}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Packing List No:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.idSapPackingList}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Bill of Lading No:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.idSapBol}</Typography>
                                    </Box>
                                    <Box sx={{display:'flex'}}>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Batch No:</Typography>
                                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.idSapBatchNo}</Typography>
                                    </Box>                                    
                                </Stack>
                            </Grid>                            
                        </Grid>
                    </Stack>
                </Grid>
                
                <Grid item xs={12} sm={4}>
                    <Stack>
                        <Box sx={{...styleHeaders, display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Typography variant="h6">Reel</Typography>
                            <Link onClick={() => {setHelpReel(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <HelpOutlineIcon sx={{fontSize:'1em', ml:1}}  />
                            </Link>                            
                        </Box>
                        {/* {
                            props.data.reel!==null
                            ? */}
                            <LocatorListItemReel data={props.data} />
                            {/* :
                            ""
                        } */}
                    </Stack>                          
                </Grid>
            </Grid>
        </Paper>
    )
}

