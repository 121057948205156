import {useContext} from 'react';
import { useNavigate } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';

import NavButDash from './NavButDash';
import NavButReels from './NavButReels';
import NavButAnalytics from './NavButAnalytics';
import NavButAdmin from './NavButAdmin';
import NavButManage from './NavButManage';
import NavButLocator from './NavButLocator';
import NavButSystem from './NavButSystem';

import WorkNavButDash from './WorkNavButDash';
import WorkNavButSystem from './WorkNavButSystem';

import PalletNavButDash from './PalletNavButDash';
import PalletNavButTurns from './PalletNavButTurns';
import PalletNavButAdmin from './PalletNavButAdmin';
import PalletNavButSystem from './PalletNavButSystem';

import ManuNavButDash from './ManuNavButDash';
import ManuNavButAdmin from './ManuNavButAdmin';
import ManuNavButSystem from './ManuNavButSystem';

import InspectionNavButDash from './InspectionNavButDash';
import InspectionNavButAdmin from './InspectionNavButAdmin';
import InspectionNavButSystem from './InspectionNavButSystem';

import { useSessionContext } from '../../contexts/SessionContext';

export default function NavBarDrawer( props ){
    const history = useNavigate();
    const {user, appMode, userAuthToken, userLogOut, userAccessLevel} = useSessionContext();

    function handleReelsClick(e) {
        history("/LoadList");
    }

    function handleAnalyticsClick(e){
        history("/Analytics");
    }

    const handleOpenAdminMenu = (event) => {
        // setAnchorElAdmin(event.currentTarget);
    };
      
    const handleCloseAdminMenu = () => {
        // setAnchorElAdmin(null);
    };


    return (
        <>
        <Drawer
            open={props.openDrawer}
            onClose={() => props.setOpenDrawer(false)}
            sx={{width:'100%', maxWidth:360, bgcolor:'Background.paper', display: { xs: 'flex', md: 'none' }}}
        >
            <Toolbar sx={{mb:2}}></Toolbar>
            {appMode==='WORK'
            ?
                <>
                <WorkNavButDash setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                <WorkNavButSystem setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                </>
            :
                appMode==='PALLETS'
                ?
                    <>
                    <PalletNavButDash setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                    <PalletNavButTurns setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                    <PalletNavButAdmin setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                    <PalletNavButSystem setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                    </>
                :
                    appMode==='MANUFACTURING'
                    ?
                        <>
                        <ManuNavButDash setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                        <ManuNavButAdmin setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                        <ManuNavButSystem setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                        </>
                    :
                        appMode==='REELINSPECTIONS'
                        ?
                            <>
                            <InspectionNavButDash setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            <InspectionNavButAdmin setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            <InspectionNavButSystem setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            </>
                        :                    
                            <>
                            <NavButDash setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            <NavButReels setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            <NavButAnalytics setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            <NavButManage setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            <NavButAdmin setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            <NavButLocator setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            <NavButSystem setOpenDrawerFx={props.setOpenDrawerFx} mode="NavDrawer" />
                            </>
        }
        </Drawer>
        </>
    );
}



