import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import Loading from '../Loading';
import Error from '../Error';

import EditIcon from '@mui/icons-material/Edit'; 

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductAliasEditProductLookup(props){
    //console.debug("RTS Debug ProductAliasEditProductLookup");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [repoProducts, setRepoProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    function fetchData(){
        let apiURL = apiRoot + "/Products/GetAllProducts";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoProducts(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }

    
    useEffect(() => {
        fetchData();
    }, []); 


    function handleClick(e, cellValues){
        props.setProductFx(cellValues.row);
    }


    const columns = [
        {
            field: "Edit",
            headerName: "",
            description:"Edit Product", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'productName', 
            headerName: "Southwire Product", 
            description:"Southwire Product Id", 
            flex:4, 
            valueGetter: (cellValues) => {
                return cellValues.row.name
            }
        },
        {
            field: 'idSap', 
            headerName: "Southwire Id", 
            description:"Southwire Product Id", 
            flex:2, 
            valueGetter: (cellValues) => {
                return cellValues.row.idSap
            }
        },        
    ];

    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }

    return (
        <>
        <Box>
            <Box sx={{height:"400px"}}>
                <DataGridPro 
                    pagination 
                    components={{Toolbar: GridToolbar}} 
                    componentsProps={{toolbar:{showQuickFilter:true}}}
                    pageSize={50} 
                    rows={repoProducts} 
                    columns={columns} 
                    getRowId={row => row.idGuid} 
                />
            </Box>
        </Box>
        </>
    );
}
