import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import EditCancel from '../EditCancel';
import ReelTypesEditMaterialList from './ReelTypesEditMaterialList';


import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelTypesEdit(props) {
    //console.debug("RTS Break ReelTypesEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [name, setName] = useState(mode===1?data.name:'');
    const [desc, setDesc] = useState(mode===1?data.desc!==null?data.desc:'':'');
    const [idSap, setIdSap] = useState(mode===1?data.idSap!==null?data.idSap:'':'');
    const [material, setMaterial] = useState(mode===1?data.material:null);
    const [reelTypeImage, setReelTypeImage] = useState(mode===1?data.reelTypeImage!==null?data.reelTypeImage:'':'');
    const [alumAssoc, setAlumAssoc] = useState(mode===1?data.alumAssoc!==null?data.alumAssoc:'':'');
    const [flangeSize, setFlangeSize] = useState(mode===1?data.flangeSize!==null?data.flangeSize:'':'');
    const [traverseSize, setTraverseSize] = useState(mode===1?data.traverseSize!==null?data.traverseSize:'':'');
    const [drumSize, setDrumSize] = useState(mode===1?data.drumSize!==null?data.drumSize:'':'');
    const [maxOverallWidth, setMaxOverallWidth] = useState(mode===1?data.maxOverallWidth!==null?data.maxOverallWidth:'':'');
    const [arborHoleSize, setArborHoleSize] = useState(mode===1?data.arborHoleSize!==null?data.arborHoleSize:'':'');
    const [weightCapacity, setWeightCapacity] = useState(mode===1?data.weightCapacity!==null?data.weightCapacity:'':'');
    const [approxWeight, setApproxWeight] = useState(mode===1?data.approxWeight:0);


    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }


        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            Name: name===''?null:name,
            Desc: desc===''?null:desc,
            Material: material===''?null:material,
            AlumAssoc: alumAssoc===''?null:alumAssoc,
            FlangeSize: flangeSize===''?null:flangeSize,
            TraverseSize: traverseSize===''?null:traverseSize,
            DrumSize: drumSize===''?null:drumSize,
            MaxOverallWidth: maxOverallWidth===''?null:maxOverallWidth,
            ArborHoleSize: arborHoleSize===''?null:arborHoleSize,
            WeightCapacity: weightCapacity===''?null:weightCapacity,
            ApproxWeight: approxWeight===''?0:approxWeight,
            ReelTypeImage: reelTypeImage===''?null:reelTypeImage,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/ReelTypes/UpdateReelType/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateReelType Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/ReelTypes/CreateReelType";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
                )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateReelType Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box>
            <form id="reelTypesForm" onSubmit={handleSubmit}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {setName(e.target.value);}}
                />
                <TextField
                    id="tbDesc"
                    margin="normal"
                    fullWidth
                    label="Description"
                    size="small"
                    required
                    value={desc}
                    onChange={(e) => {setDesc(e.target.value);}}
                />
                <TextField
                    id="tbIdSap"
                    margin="normal"
                    fullWidth
                    label="SAP Id"
                    size="small"
                    value={idSap}
                    onChange={(e) => {setIdSap(e.target.value);}}
                />
                <TextField
                    id="tbIdImage"
                    margin="normal"
                    fullWidth
                    label="Image URL"
                    size="small"
                    value={reelTypeImage}
                    onChange={(e) => {setReelTypeImage(e.target.value);}}
                />
                <ReelTypesEditMaterialList material={material} setMaterialFx={setMaterial} />
                <TextField
                    id="tbAlumAssoc"
                    margin="normal"
                    fullWidth
                    label="Alum. Assoc. Designation"
                    size="small"
                    value={alumAssoc}
                    onChange={(e) => {setAlumAssoc(e.target.value);}}
                />
                <Typography variant="h6" sx={{mt:1}}>Dimensions (inches)</Typography>
                <Box sx={{display:'flex'}}>
                    <Box sx={{flex:1, mr:2}}>
                        <TextField
                            id="tbFlangeSize"
                            margin="normal"
                            fullWidth
                            label="Flange"
                            size="small"
                            value={flangeSize}
                            onChange={(e) => {setFlangeSize(e.target.value);}}
                        />
                        <TextField
                            id="tbTraverseSize"
                            margin="normal"
                            fullWidth
                            label="Traverse"
                            size="small"
                            value={traverseSize}
                            onChange={(e) => {setTraverseSize(e.target.value);}}
                        />
                        <TextField
                            id="tbDrumSize"
                            margin="normal"
                            fullWidth
                            label="Drum Size"
                            size="small"
                            value={drumSize}
                            onChange={(e) => {setDrumSize(e.target.value);}}
                        />
                    </Box>
                    <Box sx={{flex:1}}>
                        <TextField
                            id="tbOverallWidth"
                            margin="normal"
                            fullWidth
                            label="Max Overall Width"
                            size="small"
                            value={maxOverallWidth}
                            onChange={(e) => {setMaxOverallWidth(e.target.value);}}
                        />
                        <TextField
                            id="tbArborHoleSize"
                            margin="normal"
                            fullWidth
                            label="Arbor Hole"
                            size="small"
                            value={arborHoleSize}
                            onChange={(e) => {setArborHoleSize(e.target.value);}}
                        />
                    </Box>
                </Box>
                <Typography variant="h6" sx={{mt:1}}>Weights (lbs)</Typography>
                <Box sx={{display:'flex'}}>
                    <Box sx={{flex:1, mr:2}}>
                        <TextField
                            id="tbWeightCapacity"
                            margin="normal"
                            fullWidth
                            label="Weight Capacity"
                            size="small"
                            value={weightCapacity}
                            onChange={(e) => {setWeightCapacity(e.target.value);}}
                        />
                    </Box>
                    <Box sx={{flex:1}}>
                        <TextField
                            id="tbApproxWeight"
                            margin="normal"
                            fullWidth
                            label="Approximate Weight"
                            size="small"
                            value={approxWeight}
                            onChange={(e) => {setApproxWeight(e.target.value);}}
                        />
                    </Box>
                </Box>                
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
        </Box>
        </>
    );
}


