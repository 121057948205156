import axios from 'axios';
import {useState, useEffect } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import WorkItemsListBack from '../../components/WorkItems/WorkItemsListBack';
import WorkItemsHeader from '../../components/WorkItems/WorkItemsHeader';
import WorkItemsList_1 from '../../components/WorkItems/WorkItemsList_1';
import WorkItemsList_2 from '../../components/WorkItems/WorkItemsList_2';
import WorkItemsList_3 from '../../components/WorkItems/WorkItemsList_3';
import WorkItemsList_4 from '../../components/WorkItems/WorkItemsList_4';
import WorkItemsList_5 from '../../components/WorkItems/WorkItemsList_5';

import { useSessionContext } from '../../contexts/SessionContext';

export default function WorkItemsPage( props ){
    //console.debug("RTS Break WorkItemsPage");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const location = useLocation();
    const workSet = location.state.data;

    const [data, setData] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    function fetchData(){
        let apiURL = apiRoot + "/WorkItems/GetWorkItemsByWorkSet";
        axios
        .post(
            apiURL,
            workSet,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }
    

    useEffect(() => {
        props.setIsModalFx(true);
        fetchData();
    }, []);  
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Container component="main" sx={{mb:5}}>
            <CssBaseline />
            
            <WorkItemsHeader data={workSet} />    
            <Typography variant="h5">Work Set Items</Typography>
            <Box>
                {
                workSet.workSetType.code === 1
                ?
                    <WorkItemsList_1 data={data} />
                :
                    workSet.workSetType.code === 2
                    ?
                        <WorkItemsList_2 data={data} />
                    :
                        workSet.workSetType.code === 3
                        ?
                            <WorkItemsList_3 data={data} />
                        :
                            workSet.workSetType.code === 4
                            ?
                                <WorkItemsList_4 data={data} />
                            :
                                workSet.workSetType.code === 5
                                ?
                                    <WorkItemsList_5 data={data} />
                                :
                                    <Box sx={{display:'flex', alignContent:'center', justifyContent:'center'}}>
                                        <Typography variant='h2'>Unknown Work Set Type</Typography>
                                    </Box>
                }

            </Box>
            <Box sx={{mt:2, textAlign:'center'}}>
                <WorkItemsListBack />
            </Box>
        </Container>
    );
}
