import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Loading from '../components/Loading';
import Error from '../components/Error';
import PickupRequestsList from '../components/PickupRequests/PickupRequestsList';
import PickupRequestsHeader from '../components/PickupRequests/PickupRequestsHeader';
import HelpListOverview from '../components/PickupRequests/HelpListOverview';

import { useSessionContext } from '../contexts/SessionContext';

export default function PickupRequestsPage( props ){
    //console.debug("RTS Break PickupRequestPage");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [helpListOverview, setHelpListOverview] = useState(false);

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({"user":user, 
                                        "customers":[sessionCustomer.customer], 
                                        "sortBy":0 
                                        });   

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);


    function fetchData(){
        let apiURL = apiRoot + "/PickupRequest/GetAllPickupRequestByFilter";
        axios
        .post(
            apiURL,
            filter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    

    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, [filter]);  


    function dataFilter(args){
        setIsLoading(true);
        let locFilter = {"user":user,
                        "customers":[sessionCustomer.customer], 
                        "sortBy":0 
                        }

        setFilter(locFilter);
    }


    function dataRefresh(){
        setIsLoading(true);
        fetchData();
    }    

    
    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <>
        <Dialog open={helpListOverview} onClose={()=>{setHelpListOverview(false);}}>
            <DialogTitle>Pickup Request Help</DialogTitle>
            <DialogContent>
                <HelpListOverview />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setHelpListOverview(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box id="pageBox">
            <PickupRequestsHeader setHelpListOverviewFx={setHelpListOverview} />
            {/* <PickupRequestsFilter data={data} filterFx={dataFilter} /> */}
            <Box sx={{mt:2}}>           
                <PickupRequestsList data={data} dataRefreshFx={dataRefresh}/>
            </Box>
        </Box>
        </>            
    );

}
