import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import PickupRequestEdit from '../components/PickupRequests/PickupRequestsEdit';

export default function PickupRequestsPageEdit( props ){

    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 
    
    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Box>
                <PickupRequestEdit data={props.data} mode={props.mode} />
            </Box>
        </Container>  
    );
}
