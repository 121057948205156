import axios from 'axios';
import {useState, useContext} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import Link from '@mui/material/Link';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LoadPullManagementCancel from './LoadPullManagementCancel';
import LoadListListItem from "../LoadList/LoadListListItem";
import LoadPullManagementEditHelp from './LoadPullManagementEditHelp';
import LoadPullManagementList from './LoadPullManagementList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPullManagementEdit(props) {
    //console.debug('RTS Break LoadPullAdjustmentEdit');

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showPullManagementHelp, setShowPullManagementHelp] = useState(false);

    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 

            <Dialog open={showPullManagementHelp} onClose={()=>{setShowPullManagementHelp(false);}}>
                <DialogTitle>Pull Management Help</DialogTitle>
                <DialogContent>
                    <LoadPullManagementEditHelp />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowPullManagementHelp(false);}}>Close</Button>
                </DialogActions>
            </Dialog>                    

            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>

            <Box sx={{display:'flex', width:'100%', mt:2, alignItems:'center'}}>
                <Box sx={{textAlign:'left', width:'50%', pl:1}}>
                    <Typography variant="h6">Pulls</Typography>
                </Box>
                <Box sx={{textAlign:'right', width:'50%', pr:1}}>
                    <Button 
                        type="button" 
                        variant="contained"
                        onClick={()=>(props.modeFx(7))} 
                    >
                        Add Adjustment
                    </Button>
                </Box>
            </Box>
            <Box sx={{pt:1}}>
                <LoadPullManagementList data={props.data} user={props.user} />
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LoadPullManagementCancel modeFx={props.modeFx} dataRefreshFx={props.dataRefreshFx} />
            </Box>
        </Box>
    );

}
