import {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import LocatorFilterProductList from './LocatorFilterProductList';
import LocatorFilterReelTypeList from './LocatorFilterReelTypeList';
import LocatorFilterTrackList from './LocatorFilterTrackList';

import { useSessionContext } from '../../contexts/SessionContext';


export default function LocatorFilter(props) {
    //console.debug("RTS Break LocatorFilter");

    const history = useNavigate();

    const {user, sessionCustomer} = useSessionContext();

    const filterData = props.filterData;
    const [products, setProducts] = useState(props.filter===null?[]:props.filter.products);
    const [reelTypes, setReelTypes] = useState(props.filter===null?[]:props.filter.reelTypes);
    const [tracking, setTracking] = useState(props.filter===null?0:props.filter.tracking);
    const [minLength, setMinLength] = useState(props.filter===null?0:props.filter.minLength);
    const [maxAge, setMaxAge] = useState(props.filter===null?0:props.filter.maxAge);
    const [sortBy] = useState(props.filter===null?0:props.filter.sortBy);

    const [showFilter, setShowFilter] = useState(true);


    function setParentFilter(e){
        let args = {"user":user,
                    "products":products, 
                    "reelTypes":reelTypes,
                    "tracking":tracking,
                    "minLength":minLength,
                    "maxAge":maxAge,
                    "sortBy":sortBy                }
        props.setFilterFx(args);
    }


    function toggleShowFilter()
    {
        setShowFilter(!showFilter);
    }

    
    return (
        <Box sx={{pt:1}}>
            <Box sx={{visibility:showFilter===true?"visible":"hidden", display:showFilter===true?"initial":"none"}}>
                <Grid container id="gridContainer">
                    {/* {user.appUserCustomers.length > 1
                    ?
                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <FilterCustomerList customers={customers} setCustomersFx={setCustomers} />
                    </Grid>
                    :
                    ""
                    } */}
                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LocatorFilterProductList products={products} data={props.locatorFilters.products!==null?props.locatorFilters.products:[]} setProductsFx={setProducts} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LocatorFilterReelTypeList reelTypes={reelTypes} data={props.locatorFilters.reelTypes!==null?props.locatorFilters.reelTypes:[]} setReelTypesFx={setReelTypes} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <LocatorFilterTrackList tracking={tracking} setTrackingFx={setTracking} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <TextField
                            id="tbMinLength"
                            margin="none"
                            fullWidth
                            label="Minimum Length Remaining (ft)"
                            type="number"
                            size="small"
                            value={minLength}
                            onChange={(e) => {setMinLength(e.target.value);}}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{px:1, pt:1}}>
                        <TextField
                            id="tbMaxAge"
                            margin="none"
                            fullWidth
                            label="Maximum Age (days)"
                            type="number"
                            size="small"
                            value={maxAge}
                            onChange={(e) => {setMaxAge(e.target.value);}}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{px:1, pt:1}}>
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={6} sx={{textAlign:{xs:'center', sm:'left'}}}>
                                <Button type="button" variant="contained" onClick={setParentFilter} sx={{mt:1}} >
                                    <SearchIcon />
                                    <Typography sx={{ml:1}}>Find</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>                        
                </Grid>
                <Box onClick={toggleShowFilter}>
                    <Button variant="text" startIcon={<ArrowCircleUpOutlinedIcon />} size="small">Hide Filters</Button>
                </Box>                
            </Box>
            <Box sx={{visibility: showFilter===true?"hidden":"visible", display:showFilter===true?"none":"initial"}}>
                <Box onClick={toggleShowFilter}>
                    <Button variant="text" startIcon={<ArrowCircleDownIcon />} size="small">Show Filters</Button>
                </Box>
            </Box>                     
        </Box>
    );
}
