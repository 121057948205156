import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSessionContext } from '../../contexts/SessionContext';


export default function InspectionNavButAdmin( props ){
    //console.debug("RTS Break InspectinNavButAdmin");

    const history = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorElAdmin, setAnchorElAdmin] = useState(null);
    const {user, sessionCustomer} = useSessionContext();

    const handleOpenAdminMenu = (event) => {
        setOpen(!open);
        setAnchorElAdmin(event.currentTarget);
    };
      
    const handleCloseAdminMenu = () => {
        props.setOpenDrawerFx(false);
        setAnchorElAdmin(null);
    };

    function handleSysTrackersClick(e){
        // props.closeNavMenuFx();
        handleCloseAdminMenu();
        history("/Trackers");
    }  

    function handleSysReelTypesClick(e){
        // props.closeNavMenuFx();
        handleCloseAdminMenu();
        history("/ReelTypes");
    }  

    function handleSysReelsClick(e){
        // props.closeNavMenuFx();
        handleCloseAdminMenu();
        history("/Reels");
    }   



    if(user.reelAdminFlag === 0 && user.adminFlag === 0){
        return("");
    }


    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="Administrator" onClick={handleOpenAdminMenu}>
                <ListItemText primary="Administration" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button key="Trackers" sx={{ pl: 6 }} onClick={handleSysTrackersClick}>
                        <ListItemText primary="Trackers" />
                    </ListItem>
                    <ListItem button key="ReelTypes" sx={{ pl: 6 }} onClick={handleSysReelTypesClick}>
                        <ListItemText primary="Reel Types" />
                    </ListItem>
                    <ListItem button key="Reels" sx={{ pl: 6 }} onClick={handleSysReelsClick}>
                        <ListItemText primary="Reels" />
                    </ListItem>
                </List>
            </Collapse>

            </>
        )
    }
    
    return(
        <>
        <Button
            key='Administration'
            onClick={handleOpenAdminMenu}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
            endIcon={<ArrowDropDownIcon />}
        >
            Administration
        </Button>

        <Menu
            id="menu-admin"
            anchorEl={anchorElAdmin}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElAdmin)}
            onClose={handleCloseAdminMenu}
            sx={{
                display: { xs: 'none', md: 'block' },
            }}
        >
            <MenuItem key='SysTrackers' onClick={handleSysTrackersClick}>
                <Typography textAlign="Left" sx={{ml:1}}>Trackers</Typography>
            </MenuItem>

            <MenuItem key='SysReelTypes' onClick={handleSysReelTypesClick}>
                <Typography textAlign="Left" sx={{ml:1}}>Reel Types</Typography>
            </MenuItem>

            <MenuItem key='SysReels' onClick={handleSysReelsClick}>
                <Typography textAlign="Left" sx={{ml:1}}>Reels</Typography>
            </MenuItem>            
        </Menu>
        </>
    );
}



