import axios from 'axios';
import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import ClearIcon from '@mui/icons-material/Clear';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelWipList(props){
    //console.debug("RTS Break ReelWipList");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [currentWip, setCurrentWip] = useState(null);


    function handleRemoveClick(e, cellValues){
        setCurrentWip(cellValues);
        setShowDeleteModal(true);
    }


    function removeConfirmed(){
        setShowDeleteModal(false);            

        let apiURL = apiRoot + "/ReelWip/DeleteReelWip/" + currentWip.row.idGuid;
        axios
        .delete(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.fetchDataFx();
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateReelType Error", error);
            setShowErrorModal(true);
        });
    }


    const columns = [
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.reel.serialNumber
            }
        },
        {
            field: 'esn', 
            headerName: "Tracker", 
            description:"Tracker ESN", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.tracker.esn
            },
        },
        {
            field: 'createDate', 
            headerName: "Create Date", 
            description:"Create Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },  
        {
            field: 'sentDate', 
            headerName: "Sent Date", 
            description:"Sent to SAP Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }            
        },
        {
            field: 'shippedDate', 
            headerName: "Shipped Date", 
            description:"Reel Shipped Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            },
            valueGetter: (cellValues) => {
                if(cellValues.row.load){
                    return cellValues.row.load.shipDate;
                }
                else{
                    return null;
                }
            },              
        },                
        {
            field: "Remove",
            headerName: "",
            description:"Remove Crew Member", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                if(cellValues.row.sentDate === null && cellValues.row.load === null){
                    return (
                        <Link onClick={(event) => {handleRemoveClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <Box sx={{display:'flex'}}>
                                <ClearIcon sx={{color:"red"}} />
                            </Box>
                        </Link>
                    );
                }
                else{
                    return ("");
                }

            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        }                          

    ];

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showDeleteModal} onClose={()=>{setShowDeleteModal(false);}}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>Delete Reel W.I.P. record?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={removeConfirmed}>Delete</Button>
                <Button variant="contained" onClick={e => {setShowDeleteModal(false);}}>Cancel</Button>
            </DialogActions>
        </Dialog>                 
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} />
        </Box>
        </>
    );
}
