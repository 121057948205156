import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Loading from '../components/Loading';
import Error from '../components/Error';
import ReelInspectionsHeader from '../components/ReelInspections/ReelInspectionsHeader';
import ReelInspectionsFilter from '../components/ReelInspections/ReelInspectionsFilter';
import ReelInspectionsReelsList from '../components/ReelInspections/ReelInspectionsReelsList';
import HelpListOverview from '../components/ReelInspections/HelpListOverview';

import { useSessionContext } from '../contexts/SessionContext';

export default function ReelInspectionsPage( props ){
    //console.debug("RTS Break ReelInspectionsPage");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [helpListOverview, setHelpListOverview] = useState(false);

    const [data, setData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    

    function fetchData(){
        let apiURL = apiRoot + "/Reels/GetAllReelsFull";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, []);
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
        <Dialog open={helpListOverview} onClose={()=>{setHelpListOverview(false);}}>
            <DialogTitle>Reels Help</DialogTitle>
            <DialogContent>
                <HelpListOverview />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setHelpListOverview(false);}}>Close</Button>
            </DialogActions>
        </Dialog>         
        <Box id="pageBox">
            <ReelInspectionsHeader setHelpListOverviewFx={setHelpListOverview} />
            {/* <Box sx={{mt:2}}>           
                <ReelInspectionsFilter data={filterData} serial={serial} setFilterFx={setFilter} />
            </Box> */}
            <Box sx={{mt:2}}>           
                <ReelInspectionsReelsList data={data} />
            </Box>
        </Box>
        </>
    );
}
