import axios from 'axios';
import {useState, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import EditCancel from '../EditCancel';
import WorkItemsList_1Body from './WorkItemsList_1Body';

import { useSessionContext } from '../../contexts/SessionContext';

export default function WorkItemsDelete_1(props) {
    //console.debug("RTS Break WorkItemsDelete_1");

    const history = useNavigate();

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [status, setStatus] = useState(props.mode===1?props.data.workItemStatus:'');
    const [hours, setHours] = useState(props.mode===1?props.data.hours:0);
    const [notes, setNotes] = useState(props.mode===1?props.data.notes:'');

    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = props.data;
        apiObj.ModifyUser = user.userName;

        let apiURL = apiRoot + "/WorkItems/DeleteWorkItem/" + props.data.idGuid;
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateWorkItem Error", error);
            setShowErrorModal(true);
        });
    } 

    function handleCancel(e){
        props.setShowEditModalFx(false);
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <div>
                    {errorBody}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <Box sx={{mt:2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <WorkItemsList_1Body data={props.data} mode={1} />
            <Box sx={{mt:2}}  maxWidth="sm">
                <Box>
                    <Typography variant='h6'>Delete Work Set Item?</Typography>
                </Box>
                <Box sx={{mt:2}}>
                    <form id="WorkItemsForm_1" onSubmit={handleSubmit}>
                        <Box sx={{textAlign:'center', mt:3}}>
                            <Button variant="contained" type="submit" sx={{mr:2}}>Delete</Button>
                            <EditCancel />
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
        </>
    );
}


