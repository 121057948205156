import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ClearIcon from '@mui/icons-material/Clear';

export default function UsersEditCustomersList(props){
    console.debug("RTS Break UsersEditCustomersList")

    function handleClick(e, cellValues){
        props.removeCustomerFx(cellValues.row);
    }

    const columns = [
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:4,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.name;
            }   
        },        
        {
            field: 'idSap', 
            headerName: "SAP Id", 
            description:"Customer SAP Id", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.idSap;
            }   
        },
        {
            field: 'accessLevel', 
            headerName: "Role", 
            description:"User Role", 
            flex:3,
            valueGetter: (cellValues) => {
                var retValue = 'Manager';

                switch(cellValues.row.accessLevel){
                    case 1:
                        retValue='Crew Member';
                        break;

                    case 25:
                        retValue = 'Associate';
                        break;
    
                    case 50:
                        retValue = 'Manager';
                        break;

                    case 75:
                        retValue='Administrator';
                        break;

                    default:
                        retValue = 'Unknown';

                }

                return retValue;
            }   
        },
        {
            field: 'salesOffice', 
            headerName: "Office Id", 
            description:"Sales Office Id", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.salesOffice;
            },
            hide:true                      
        },
        {
            field: 'salesGroup', 
            headerName: "Group Id", 
            description:"Sales Group Id", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.salesGroup;
            },
            hide:true                
        },
        {
            field: '',
            description:"Remove Customer", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <ClearIcon sx={{color:"red"}} />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },        
    ];

    return (
        <Box sx={{width:'100%', mt:1}}>
            <DataGridPro 
                autoHeight 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.customer.idSap} 
            />
        </Box>
    );
}
