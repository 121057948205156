import {useState, useContext} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsAdminEditCustomerList(props) {
    //console.debug("RTS Break PickupRequestAdminEditCustomerList");

    const {user} = useSessionContext();
    const [customer, setCustomer] = useState(props.customer);
    const [repoCustomers] = useState(user.appUserCustomers);


    function valChange(e, val){
        setCustomer(val);
        props.setCustomerFx(val);
    }

 
    return(
        <>
            <Autocomplete
                options={repoCustomers}
                renderInput={(params) => <TextField {...params} label="Customer" required size="small" />}
                value={customer}
                onChange={valChange}
                getOptionLabel={(repoCustomer) => repoCustomer.name + ' (' + repoCustomer.idSap + ')'}
                renderOption={(props, repoCustomers) => (
                    <Box component="li" {...props} key={repoCustomers.idGuid}>
                        {repoCustomers.name + ' (' + repoCustomers.idSap + ')'}
                    </Box>
                )}
                {...(user.appUserCustomers.length===1?{disabled: true}:props.mode===1?{disabled: true}:'')}
                isOptionEqualToValue={(option, value) => option.idGuid === value.idGuid}
            />
        </>
    );
}
