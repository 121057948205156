import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../contexts/SessionContext';

export default function FilterCustomerList(props) {
    //console.debug("RTS Break FilterCustomerList");

    const {user} = useSessionContext();
    const [customers, setCustomers] = useState(user.appUserCustomers.length>1?props.customers:user.appUserCustomers);
    const [repoCustomers] = useState(user.appUserCustomers);

    function valChange(e, val){
        props.setCustomersFx(val);
        setCustomers(val);
    }


    return(
        <>
            <Autocomplete
                options={repoCustomers}
                renderInput={(params) => <TextField {...params} label="Customers" size="small" />}
                value={customers}
                onChange={valChange}
                getOptionLabel={(repoCustomers) => repoCustomers.name + " [" + repoCustomers.mailCity + "] (" + repoCustomers.idSap + ")"}
                renderOption={(props, repoCustomers) => (
                    <Box component="li" {...props} key={repoCustomers.idGuid}>
                        {repoCustomers.name + " [" + repoCustomers.mailCity + "] (" + repoCustomers.idSap + ")"}
                    </Box>
                )}
                multiple
                disabled={user.appUserCustomers.length>1?false:true}
            />
        </>
    );
}