import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function LocatorFilterProductList(props) {
    //console.debug("RTS Break LocatorFilterProductList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const [products, setProducts] = useState(props.products);
    const [repoProducts, setRepoProducts] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    let filter = {};
    function fetchData(){
        let apiURL = apiRoot + "/Locator/GetLocatorProductsFilter";
        axios
        .post(
            apiURL,
            filter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoProducts(response.data);
            }
            setIsLoading(false);
            setHasError(false);        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }

    function getData() {
        // const curData = props.data.map(function(i){return i.product});
        // const result = [];
        // const map = new Map();
        // for(const item of curData){
        //     if(!map.has(item.idGuid)){
        //         map.set(item.idGuid, true);
        //         result.push(item);
        //     }
        // }

        setRepoProducts(props.data);
        setIsLoading(false);
        setHasError(false);        
    }


    useEffect(() => {

        filter = {"user":user, 
                    "customers":[sessionCustomer.customer],
                    "orders":[],
                    "projects":[], 
                    "products":[],
                    "reelTypes":[], 
                    "reels":[], 
                    "status": []
                }

        getData();        
        //fetchData();
    }, []);


    function valChange(e, val){
        props.setProductsFx(val);
        setProducts(val);
    }


    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoProducts}
                renderInput={(params) => <TextField {...params} label="Products" size="small" />}
                value={products}
                onChange={valChange}
                getOptionLabel={(product) => product.name}
                renderOption={(props, repoProducts) => (
                    <Box component="li" {...props} key={repoProducts.idGuid}>
                        {repoProducts.name}
                    </Box>
                )}
                multiple
            />
        </>
    );
}
