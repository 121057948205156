import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MyLocationIcon from '@mui/icons-material/MyLocation';

export default function LocatorListItemCurLocation(props){
    return (
        <>
        {props.data.currentLocation!==null
            ?
            <Box>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Location:</Typography>
                {props.data.currentLocation.namedLocation===null
                ?
                <a target="_blank" style={{textDecoration:'none', cursor:'pointer', color:'#1976d2'}} href={"https://www.google.com/maps/search/?api=1&query=" + props.data.currentLocation.lat + "%2C" + props.data.currentLocation.lng} >
                    <Box sx={{display:'flex', pl:1}}>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0, m:0}}>
                            <MyLocationIcon sx={{fontSize:'.75em'}} />
                        </Box>
                        <Box sx={{pl:1}}>
                            <Box sx={{display:'flex'}}>
                                <Typography variant="caption" sx={{fontWeight:'bold'}}>lat:</Typography>
                                <Typography variant="caption">{props.data.currentLocation.lat!=null?props.data.currentLocation.lat.substring(0,8):""}</Typography>
                            </Box>
                            <Box sx={{display:'flex'}}>
                                <Typography variant="caption" sx={{fontWeight:'bold'}}>lng:</Typography>
                                <Typography variant="caption">{props.data.currentLocation.lng!=null?props.data.currentLocation.lng.substring(0,8):""}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </a>
                :
                <a target="_blank" style={{textDecoration:'none', cursor:'pointer', color:'#1976d2'}} href={"https://www.google.com/maps/search/?api=1&query=" + props.data.currentLocation.lat + "%2C" + props.data.currentLocation.lng} >
                    <Box sx={{display:'flex', pl:1}}>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0, m:0}}>
                            <MyLocationIcon sx={{fontSize:'.75em'}} />
                        </Box>
                        <Box sx={{pl:1}}>
                            <Box sx={{display:'flex'}}>
                                <Typography variant="caption">{props.data.currentLocation.namedLocation}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </a>
                }
            </Box>
            :
            <Box>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Location:</Typography>
                <Typography variant="body2" sx={{color:"red"}}>Unknown</Typography>
            </Box>
        }
        </>
    )
}
