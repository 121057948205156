import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function PalletActionReturnPalletsList(props){
    //console.debug("RTS Break PalletActionReturnPalletsList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);


    function fetchData(){
        let apiURL = apiRoot + "/PalletActions/GetReturnPallets";
        axios
        .post(
            apiURL,
            props.palletPlant,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        fetchData();
    },[]);  
    
    
    function handleClick(palletKeys){
        setSelectionModel(palletKeys);
        let selectedRowData = data.filter((row) => palletKeys.includes(row.idGuid.toString()));
        props.setReturnPalletsFx(selectedRowData);
    }

    function CustomToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }

    const [selectionModel, setSelectionModel] = useState([]);


    const columns = [
        {
            field: 'esn', 
            headerName: "Pallet", 
            description:"Pallet Electronic Serial Number", 
            flex:5,
            valueGetter: (cellValues) => {
                return cellValues.row.pallet!==null?cellValues.row.pallet.esn:null
            },            
       },
       {
        field: 'sendDate', 
        headerName: "Date Sent", 
        description:"Date Pallet Was Sent", 
        flex:5,           
        valueFormatter: (parms) => {
            if(parms.value == null){
                return '';
            }

            return (
                format(parseISO(parms.value), "MM-dd-yyyy")
            );
        }, 
    },                     
    ];


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return (
        <Box sx={{height:"400px", width:"500px", display:'flex', justifyContent:'center', alignItems:'center'}}>
            <DataGridPro 
                checkboxSelection={true} 
                pagination 
                pageSize={50}             
                rows={data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                onSelectionModelChange={(newSelectionModel) => {
                    handleClick(newSelectionModel);
                }}
                selectionModel={selectionModel} 
                components={{Toolbar: CustomToolbar}}            
            />
        </Box>
    );
}
