import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import LoadPullManagementConfirm from './LoadPullManagementConfirm';
import LoadPullManagementReject from './LoadPullManagementReject';

export default function LoadPullManagementList(props){
    //console.debug('RTS Break LoadPullManagementList');

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showPullConfirm, setShowPullConfirm] = useState(false);
    const [showPullReject, setShowPullReject] = useState(false);
    const [curPull, setCurPull] = useState(null);
    const [repoPulls, setRepoPulls] = useState(props.data.pulls);


    function handleConfirmClick(e, cellValues){
        setCurPull(cellValues.row);
        setShowPullConfirm(true);
    }

    function handleRejectClick(e, cellValues){
        setCurPull(cellValues.row);
        setShowPullReject(true);        
    }

    function handleReject(dataRow){
        let curPulls = repoPulls.filter(function(el){ return el.idGuid != dataRow.idGuid})
        setRepoPulls(curPulls);
        setShowPullReject(false);
    }

    const columns = [
        {field: "pullDate",
            headerName:"Date",
            description:"Pull Date",
            flex:2,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }             
        },
        {field: "pullUser",
            headerName:"Crew Member",
            description:"Crew Member Who Performed Pull",
            flex:5
        },
        {field:"pullLength",
            headerName:"Length",
            description:"Lenght of Pull",
            flex:2,
            valueGetter: (cellValues) => {
                return (
                    cellValues.row.pullLength.toLocaleString() + " ft"
                )
            },
            align:"right"
        },
        {field: 'confirmLink', 
            headerName:'Confirm/Reject',
            description:"Confirm/Reject Pull", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.row.confirmedFlag === 0){   
                    return (
                        <>
                        <Link onClick={(event) => {handleConfirmClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <Box sx={{display:'flex'}}>
                                <CheckIcon sx={{color:'green', fontWeight:'bold'}} />
                            </Box>
                        </Link>
                        <Link onClick={(event) => {handleRejectClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <Box sx={{display:'flex'}}>
                                <ClearIcon sx={{color:'red', fontWeight:'bold', ml:3}} />
                            </Box>
                        </Link>
                        </>
                    );
                }
                else{
                    return('');
                }
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },
    ];

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Dialog open={showPullConfirm} onClose={()=>{setShowPullConfirm(false);}}>
            <DialogTitle>Confirm Pull</DialogTitle>
            <DialogContent>
                {
                curPull !== null
                ?
                <LoadPullManagementConfirm data={curPull} setShowPullConfirmFx={setShowPullConfirm} />
                :
                ''
                }
            </DialogContent>
        </Dialog>                    
        <Dialog open={showPullReject} onClose={()=>{setShowPullReject(false);}}>
            <DialogTitle>Reject Pull</DialogTitle>
            <DialogContent>
                {
                curPull !== null
                ?
                <LoadPullManagementReject data={curPull} handleRejectFx={handleReject} setShowPullRejectFx={setShowPullReject} />
                :
                ''
                }                
            </DialogContent>
        </Dialog>                    
        <Box sx={{height:'300px', minHeight:'300px'}}>
            <DataGridPro 
                rows={repoPulls} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
        </>
    );
}
