import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';

import Loading from '../Loading';
import Error from '../Error';
import EditCancel from '../EditCancel';
import CustomerProjectsEditManagerList from './CustomerProjectsEditManagerList';
import CustomerProjectsEditUnassignedLoadsList from './CustomerProjectsEditUnassignedLoadsList';
import CustomerProjectsEditLoadsList from './CustomerProjectsEditLoadsList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CustomerProjectsEdit(props) {
    //console.debug("RTS Break CustomerProjectsEdit");
    
    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();
    const location = useLocation();
    const mode = location.state.mode;
    const [formDirty, setFormDirty] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [unassignedLoadList, setUnassignedLoadList] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showLoadsModal, setShowLoadsModal] = useState(false);

    const [idGuid, setIdGuid] = useState(null);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [manager, setManager] = useState(null);
    const [idExt, setIdExt] = useState('');
    const [startDate, setStartDate] = useState(format( new Date(), 'MM-dd-yyyy'));
    const [endDate, setEndDate] = useState(format( new Date(), 'MM-dd-yyyy'));
    const [loadList, setLoadList] = useState([]);

    function fetchData(){
        if(idGuid !== null){
            let apiURL = apiRoot + "/CustomerProjects/GetProjectById/" + idGuid;
            axios
            .get(
                apiURL,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    setName(response.data.name);
                    setDesc(response.data.desc);
                    setManager(response.data.manager);
                    setIdExt(response.data.idExt);
                    setStartDate(format(parseISO(response.data.startDate), 'MM-dd-yyyy'));
                    setEndDate(format(parseISO(response.data.endDate), 'MM-dd-yyyy'));
                    setLoadList(response.data.loads);

                    setIsLoading(false);
                }
            })
            .catch(error => {
                setHasError(true);
                console.log("RTS Data Error", error);
            });
        }
    }
    
    
    useEffect(() => {
        if(mode === 1){
            setIdGuid(location.state.key);
        }
        else{
            setIsLoading(false);
        }
    }, []);  


    useEffect(() => {
        fetchData();
    }, [idGuid]);  


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            IdGuid: idGuid,
            Customer: sessionCustomer.customer,
            Name: name,
            Desc: desc,
            Manager: manager,
            IdExt: idExt,
            StartDate: startDate,
            EndDate: endDate,
			Loads: loadList,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/CustomerProjects/UpdateProject/";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateCustomerProject Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/CustomerProjects/CreateProject";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateCustomerProject Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    function addLoads(args){
        setShowLoadsModal(false);
        let curLoadList = JSON.parse(JSON.stringify(loadList));

        args.forEach((item) => {
            if(curLoadList.filter(l => l.idGuid == item.idGuid).length > 0){
                return;
            }

            //let curLoadList = loadList.filter((l) => l.idGuid !== item.idGuid);
            curLoadList.push(item);
        });
            
        setLoadList(curLoadList);
        setFormDirty(1);
    }

    
    function removeLoad(args){
        let curLoadList = loadList.filter((l) => l.idGuid !== args.idGuid);
        setLoadList(curLoadList);
        setFormDirty(1);
    }


    function openLoadsModal(){
        let apiURL = apiRoot + "/CustomerProjects/GetUnassignedLoads";
        axios
        .post(
            apiURL,
            sessionCustomer.customer, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setUnassignedLoadList(response.data);
            }
            setShowLoadsModal(true);
        })
        .catch(error => {
            setShowErrorModal(true);
            console.log("RTS Data Error", error);
        });        

    }


    if(hasError){
        return(
            <Error />
        );
    }
    
    
    if(isLoading){
        return(
            <Loading />
        );
    }    


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Dialog fullWidth={true} maxWidth="md" open={showLoadsModal}>
            <DialogTitle>Add Reel</DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:"400px"}}>
                    <CustomerProjectsEditUnassignedLoadsList data={unassignedLoadList} addLoadsFx={addLoads} setShowLoadsModalFx={setShowLoadsModal} />
                </Box>            
            </DialogContent>
        </Dialog>  
        <Box>
            {formDirty===0
            ?
                <Typography variant="h5" sx={{my:2}}>Edit Project</Typography>
            :
                <Typography variant="h5" sx={{my:2, fontStyle:'italic'}}>Edit Project *</Typography>
            }
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="projectForm" onSubmit={handleSubmit}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Project Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {setFormDirty(1); setName(e.target.value);}}
                />
                <TextField
                    id="tbDescription"
                    margin="normal"
                    fullWidth
                    label="Description"
                    size="small"
                    value={desc}
                    onChange={(e) => {setFormDirty(1); setDesc(e.target.value);}}
                />
                <CustomerProjectsEditManagerList manager={manager} setManagerFx={setManager} setFormDirtyFx={setFormDirty} />
                <TextField
                    id="tbIdExt"
                    margin="normal"
                    fullWidth
                    label="External Id"
                    size="small"
                    value={idExt}
                    onChange={(e) => {setFormDirty(1); setIdExt(e.target.value);}}
                />
                <Grid container>
                    <Grid item xs={6}>
                        <DatePicker
                            id="tbStartDate"
                            label="Start Date"
                            inputFormat="MM/dd/yyyy"
                            value={startDate}
                            onChange={(val) => {setFormDirty(1); setStartDate(val)}}
                            renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                        />      
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <DatePicker
                            id="tbEndDate"
                            label="End Date"
                            inputFormat="MM/dd/yyyy"
                            value={endDate}
                            onChange={(val) => {setFormDirty(1); setEndDate(val)}}
                            renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                        />  
                    </Grid>
                </Grid>
                <Box sx={{mt:2}}>
                    <Grid container sx={{mb:1}}>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Project Reels</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:'right'}}>
                            <Button variant='contained' onClick={openLoadsModal}>Add Reels</Button>
                        </Grid>
                    </Grid>
                    <CustomerProjectsEditLoadsList data={loadList} removeLoadFx={removeLoad} />
                </Box>
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={formDirty===1?false:true}  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel formDirty={formDirty} />
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );

}
