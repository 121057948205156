import { useState } from 'react';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import EditCancel from '../EditCancel';
import CrewMembersAddCheck from './CrewMembersAddCheck';
import CrewMembersEditAdd from './CrewMembersEditAdd';
import CrewMembersEditCreate from './CrewMembersEditCreate';
import CrewMembersRemove from './CrewMembersRemove';


export default function CrewMembersEdit(props) {
    //console.debug("RTS Break CrewMembersEdit");

    const location = useLocation();

    const [mode, setMode] = useState(location.state.mode);
    const [user, setUser] = useState(location.state.data);
    const [userIdValid, setUserIdValid] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");


    function userIdCheck(args){
        if(args.exist){
            // This is an existing email address.
            setUser(args.data);
            setUserIdValid(true);
            setMode(1);
        }
        else{
            if(args.data == null){
                // There is an error with the email address.
                setErrorBody(args.errorBody)
                setUserIdValid(false);
                setShowErrorModal(true);
            }
            else{
                // This is a new email address.
                setUser(args.data);
                setUserIdValid(true);
                setMode(0);
            } 
        }
    }


    // function useExistingUser(e){
    //     setUser(userTemp);
    //     setUserIdValid(true);
    //     setShowUserExistsModal(false);         
    //     setMode(1);
    // }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Box>
            {mode===99
            ?
            <CrewMembersRemove data={user} />
            :
            <>
                <Typography variant="h5" sx={{my:2}}>Add Crew Member</Typography>
                <Box sx={{mb:3}}>
                    <CrewMembersAddCheck 
                        userIdCheckFx={userIdCheck} 
                        setUserIdValidFx={setUserIdValid} 
                    />
                </Box>
                {userIdValid===true
                    ?
                        mode===1
                        ?
                        <CrewMembersEditAdd data={user} />
                        :
                        <CrewMembersEditCreate data={user} />
                    :
                    <Box sx={{textAlign:'center', mt:2}}>
                        <EditCancel />
                    </Box>
                }
            </>
            }
        </Box>
        </>
    );

}
