import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

export default function ReelTypesEditMaterialList(props) {
    //console.debug("RTS Break LoadListFilterStatusList");

    const repoMaterial = ['Steel','Wood']

    // This must come after the repoStatus is set because of the find.
    const [material, setMaterial] = useState(props.material);


    function valChange(e, val){
        props.setMaterialFx(val);
        setMaterial(val);
    }    

    return(
        <>
            <Autocomplete
                options={repoMaterial}
                renderInput={(params) => <TextField {...params} label="Material"  autoComplete='new-password' margin="normal" size="small" />}
                value={material}
                onChange={valChange}
            />
        </>
    );
}

