import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import EditIcon from '@mui/icons-material/Edit'; 

export default function TrackersList(props){
    //console.debug("RTS Break TrackersList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/TrackersEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit Tracker", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'esn', 
            headerName: "ESN", 
            description:"Electronic Serial Number", 
            flex:2
        },
        {
            field: 'purchaseDate', 
            headerName: "Purchase Date", 
            description:"Purchase Date", 
            flex:1,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },
        {
            field: 'isActive', 
            headerName: "Activated", 
            description:"Tracker Is Activated", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value === 1){
                    return ('Yes');
                }
                else{
                    return('No');
                }
            },            
        },        
        {
            field: 'batteryLevel', 
            headerName: "Battery Level", 
            description:"Tracker Battery Level", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value !== null){
                    return (cellValues.value + '%');
                }
            },
            align:'right'
        }, 
        {
            field: 'lastUpdate', 
            headerName: "Last Update", 
            description:"Last Update", 
            flex:1,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }
        },            

    ];

    return (
        <DataGridPro 
            autoHeight 
            pagination 
            components={{Toolbar: GridToolbar}} 
            componentsProps={{toolbar:{showQuickFilter:true}}}
            pageSize={50} 
            rows={props.data} 
            columns={columns} 
            getRowId={row => row.esn} 
        />
    );
}

