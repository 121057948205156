import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import EditIcon from '@mui/icons-material/Edit'; 
import { ConstructionOutlined } from '@mui/icons-material';

export default function SystemNamedLocationsList(props){
    //console.debug("RTS Break SystemNamedLocationsList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/SystemNamedLocationsEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit Named Location", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'customer', 
            headerName: "Customer", 
            description:"Customer", 
            flex:4,
            valueGetter: (cellValues) => {
                return cellValues.row.customer.name + ' - ' + cellValues.row.customer.idSap
            },
        },        
        {
            field: 'name', 
            headerName: "Location Name", 
            description:"Named Location Name", 
            flex:3
        },
        {
            field: 'shortName', 
            headerName: "Short Name", 
            description:"Named Location Short Name", 
            flex:3
        }
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
